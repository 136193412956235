<template>
  <div>
    <orderhour ref="hour" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filtra">
      <b-form-group label="Azienda">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ditta" v-if="filtersMenu.internalcompanies.length > 1">
        <b-form-select v-model="tempFilter.internalcompanies_id" :options="addNullCombo(filtersMenu.internalcompanies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Commessa">
        <b-form-input type="number" v-model="tempFilter.orders_id"/>
      </b-form-group>
      <b-form-group label="Utente" v-if="filtersMenu.users.length > 1">
        <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="tempFilter.ordershourstypes_id" :options="addNullCombo(filtersMenu.ordershourstypes)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Da">
            <b-form-input type="date" v-model="tempFilter.from"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="A">
            <b-form-input type="date" v-model="tempFilter.to"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn v-if="level >= 2" variant="info" @click="$refs.excelFormSubmit.submit()">Excel</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.hour.loadData([], $event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" :extraText="' - Totale: ' + items.total_hours + 'h'" @loadData="loadData()"/>
    <form method="post" action="/api/ordershours/excel" ref="excelFormSubmit" target="_blank">
      <input type="hidden" name="token" :value="token">
      <input type="hidden" ref="downloadFormId" name="data" :value="JSON.stringify(search)">
    </form>
  </div>
</template>

<script>
export default {
  name: "ordershours",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          companies_id: null,
          internalcompanies_id: null,
          orders_id: null,
          users_id: null,
          ordershourstypes_id: null,
          from: null,
          to: null,
        },
        sortby: 'date',
        sortdesc: true,
      },
      tempFilter: {
        companies_id: null,
        internalcompanies_id: null,
        orders_id: null,
        users_id: null,
        ordershourstypes_id: null,
        from: null,
        to: null,
      },
      filtersMenu: {
        companies:[],
        internalcompanies:[],
        users: [],
        ordershourstypes: [],
      },
      fields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'user',
        label: 'Collaboratore',
      },{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'orders_id',
        label: 'Commessa',
      },{
        key: 'orderscoststype',
        label: 'Tipologia',
        sortable: true,
      },{
        key: 'done',
        label: 'Ore',
      },{
        key: 'internalcompany',
        label: 'Ditta',
        sortable: true,
      }],
      items: {
        total: 0,
        data: [],
        total_hours: 0,
      }
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "ordershours").level;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    let d = new Date();
    this.search.filters.from = [ d.getFullYear(), d.getMonth() + 1, 1 ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    this.tempFilter.from = this.search.filters.from;
    if ( this.ordershoursId >= 0 ){
      this.id = this.ordershoursId;
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("ordershours/", this.search ).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("ordershours/filters", null ).then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      let d = new Date();
      this.tempFilter = {
        companies_id: null,
        internalcompanies_id: null,
        orders_id: null,
        users_id: null,
        ordershourstypes_id: null,
        from: [ d.getFullYear(), d.getMonth() + 1, 1 ].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        to: null,
      };
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>