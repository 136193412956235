<template>
  <div>
    <b-modal v-if="data" v-model="show" size="xl" :title="'Mail: ' + data.mail.subject" scrollable @hide="closeForm()" @close="closeForm()">
      <mailnew ref="mailnew"/>
      <mailarchive ref="archive" @update="loadData(data.mail.id)"/>
      <b-row>
        <b-col lg="8">
          <div>
            <b>Casella:</b> {{ data.mail.folder }}
          </div>
          <div>
            <b>Da:</b> {{ data.mail.from }}
          </div>
          <div>
            <b>A:</b> <a>{{ data.mail.to.join(", ") }}</a>
          </div>
          <div v-if="data.mail.cc.length > 0">
            <b>CC:</b> <a>{{ data.mail.cc.join(", ") }}</a>
          </div>
          <div v-if="data.mail.bcc.length > 0">
            <b>CCN:</b> <a>{{ data.mail.bcc.join(", ") }}</a>
          </div>
          <div>
            <b>Oggetto: </b> {{ data.mail.subject }}
          </div>
        </b-col>
        <b-col lg="4" style="text-align: right;">
          <b>Data ed ora: </b> {{ hourtime }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col lg="9">
          <div class="mb-1" style="background-color: white; overflow:auto; border-style: solid; border-width: 1px; border-color: gainsboro; padding: 10px;" :style="{ 'min-height': ( winHeight - 400) + 'px', 'max-height': ( winHeight - 400) + 'px' }">
            <div v-html="data.mail.body"></div>
          </div>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Note della mail">
            <b-form-textarea v-model="data.note.note1"/>
          </b-form-group>
          <hr>
          <b-form-group label="Incaricato">
            <b-form-select v-model="data.note.users_id" :options="addNullCombo(data.users, 'Nessuno/a')" value-field="id" text-field="val"/>
          </b-form-group>
          <b-form-group label="Cosa">
            <b-form-select v-model="data.note.mailboxesmailsnotestypes_id" :options="data.notestypes" value-field="id" text-field="val" :disabled="data.note.users_id === null"/>
          </b-form-group>
          <b-form-group label="Note">
            <b-form-textarea v-model="data.note.note2" :disabled="data.note.users_id === null"/>
          </b-form-group>
          <b-form-group label="Data in cui è stato fatto">
            <b-form-input type="date" v-model="data.note.datedone" :disabled="data.note.users_id === null"/>
          </b-form-group>
          <b-btn variant="success" @click="saveNote">Salva nota</b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b>Allegati: </b>
          <a v-for="(item, index) in data.mail.attachments" :key="index" class="mr-2" @click="downloadAttachment(item)">{{ item }}</a>
          <span v-show="data.mail.attachments.length === 0">Non ci sono allegati</span>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="replyMail()">Rispondi</b-btn>
          <b-btn variant="primary" class="mr-2" @click="forwardMail()">Inoltra</b-btn>
          <b-btn variant="primary" class="mr-2" @click="$refs.downloadForm.submit()">Scarica</b-btn>
          <b-btn variant="secondary" v-if="[1, 2].includes(data.mail.mailboxesfolders_id_original)" class="mr-2" @click="notSeen">Non letta</b-btn>
          <b-btn variant="secondary" class="mr-2" v-if="data.mail.id > 0" @click="$refs.archive.loadData(data.mail.id)">Archivia</b-btn>
          <b-btn variant="warning" class="mr-2" v-if="data.mail.mailboxesfolders_id_original === 1 && data.mail.mailboxesfolders_id === 1" @click="setSpam">Spam</b-btn>
          <b-btn variant="warning" class="mr-2" v-if="data.mail.mailboxesfolders_id_original === 1 && data.mail.mailboxesfolders_id === 2" @click="unsetSpam">Ripristina Spam</b-btn>
          <b-btn variant="danger" class="mr-2" v-if="data.mail.mailboxesfolders_id === 4" @click="recoverData">Ripristina</b-btn>
          <b-btn variant="danger" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
      <form ref="downloadForm" method="POST" target="_blank" action="/api/mails/mail/download">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="id" :value="data.mail.id">
      </form>
      <form ref="downloadAttachmentForm" method="POST" target="_blank" action="/api/mails/mail/downloadattachment">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="id" :value="data.mail.id">
        <input type="hidden" name="file" ref="attachmentfile">
      </form>
    </b-modal>
  </div>
</template>

<script>
import mailarchive from './mailscomponents/mailarchive.vue';

export default {
  name: "mail",
  components: { 
    mailarchive,
  },
  data() {
    return {
      show: false,
      data: null,
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    hourtime (){
      var d = new Date( this.data.mail.datetime );
      const today = new Date();
      if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
        return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
      } else {
        return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      }
    },
  },
  methods: {
    loadData( id ){
      if ( this.data === null ) this.show = false;
      this.openHttp("mails/mail/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    replyMail(){
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: this.data.mail.mailboxes_id,
        to: this.data.mail.from,
        cc: this.data.mail.cc.join(","),
        bcc: this.data.mail.bcc.join(","),
        subject: 'R: ' + this.data.mail.subject,
        lockSubject: true,
        companies_id: this.data.mail.companies_id,
        invoices_id: this.data.mail.invoices_id,
        orders_id: this.data.mail.orders_id,
        quotes_id: this.data.mail.quotes_id,
        contracts_id: this.data.mail.contracts_id,
        audits_id: this.data.mail.audits_id,
        replybody: '<b>Da: </b>' + this.data.mail.from + '<br><b>A: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>CCN: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Data e ora: </b>' + this.hourtime + '<br><b>Oggetto: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
        params: {}
      });
    },
    forwardMail(){
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: this.data.mail.mailboxes_id,
        subject: 'I: ' + this.data.mail.subject,
        lockSubject: true,
        forward_id: this.data.mail.id,
        companies_id: this.data.mail.companies_id,
        invoices_id: this.data.mail.invoices_id,
        orders_id: this.data.mail.orders_id,
        quotes_id: this.data.mail.quotes_id,
        contracts_id: this.data.mail.contracts_id,
        audits_id: this.data.mail.audits_id,
        forwardbody: '<b>Da: </b>' + this.data.mail.from + '<br><b>A: </b>' + this.data.mail.to.join(",") + (this.data.mail.cc.length > 0 ? ( '<br><b>CC: </b>' + this.data.mail.cc.join(",") ) : '') + (this.data.mail.bcc.length > 0 ? ( '<br><b>CCN: </b>' + this.data.mail.bcc.join(",") ) : '') + '<br><b>Data e ora: </b>' + this.hourtime + '<br><b>Oggetto: </b>' + this.data.mail.subject + '<br><br>' + this.data.mail.body,
        params: {}
      });
    },
    notSeen(){
      this.saveHttp("mails/mail/notseen", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    saveNote(){
      this.saveHttp("mails/mail/notesave", this.data.note).then((result) => {
        if ( result !== null ) this.loadData( this.data.mail.id );
      })
    },
    setSpam(){
      this.saveHttp("mails/mail/spamset", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    unsetSpam(){
      this.saveHttp("mails/mail/spamunset", { id: this.data.mail.id } ).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("mails/mail/delete", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    recoverData(){
      this.saveHttp("mails/mail/recover", { id: this.data.mail.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    downloadAttachment(filename){
      this.$refs.attachmentfile.value = filename;
      this.$refs.downloadAttachmentForm.submit();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>