<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Template - ' + data.template.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <settingmailattachment ref="attachment"  @update="loadData(data.template.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Templates" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Template">
                  <b-form-input v-model="data.template.templatename"/>
                </b-form-group>
              </b-col>
              <b-col lg="8">
                <b-form-group label="Oggetto">
                  <b-form-input v-model="data.template.subject"/>
                </b-form-group>
              </b-col>
            </b-row>
            <customeditor v-model="data.template.html" :height="winHeight - 480" :showToolbar="true"/>
          </b-tab>
          <b-tab title="Allegati" v-if="data.template.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.attachment.loadData(data.template.id, 0)">Carica</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-2" :items="data.attachments" :fields="fields.attachments" @row-clicked="$refs.attachment.loadData(data.template.id, $event.id)">
              <template v-slot:cell(download)="row">
                <b-btn variant="primary" size="sm" @click="$refs.attachment.downloadData(row.item.id, 0)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Abilitati">
            <b-form-checkbox-group v-model="data.template.mailsboxesusers">
              <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" responsive :items="data.mailsboxesusers" :fields="fields.mailsboxesusers">
                <template v-slot:cell(mailsboxes)="row">
                  <b-form-checkbox v-for="(item2, index2) of row.value" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                </template>
              </b-table>
            </b-form-checkbox-group>
          </b-tab>
          <b-tab title="Scadenza">
            <b-row>
              <b-col lg="3">
                <b-form-group label="Scadenza">
                  <b-form-select v-model="data.template.mailstemplateyesno" :options="yesNoCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="data.template.mailstemplateyesno === true">
              <b-col lg="4">
                <b-form-group label="Nota">
                  <b-form-textarea v-model="data.template.note" rows="6"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Scadenza">
                  <b-form-textarea v-model="data.template.deadline" rows="6"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Utente">
                  <b-form-select v-model="data.template.users_id" :options="data.users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Giorni">
                  <b-form-input type="number" v-model="data.template.deadlinedays"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.template.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import settingmailattachment from './settingmailattachment.vue'
import customeditor from '../customeditor.vue';

export default {
  name: "settingmailtemplate",
  components: {settingmailattachment, customeditor},
  data() {
    return {
      show: true,
      data: null,
      yesNoCombo: [{
        id: true,
        val: "Si"
      },{
        id: false,
        val: "No"
      }],
      fields: {
        attachments: [{
          key: "filename",
          label: "File"
        },{
          key: "download",
          label: ""
        }],
        mailsboxesusers: [{
          key: "val",
          label: "Utente"
        },{
          key: "mailsboxes",
          label: "Abilitazioni"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("settings/mails/template/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("settings/mails/template/save", this.data.template).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("settings/mails/template/delete", { id: this.data.template.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>