<template>
  <div>
    <settingmailtemplate ref="template" @update="loadData()"/>
    <marketingtemplate ref="marketingtemplate" @update="loadData()"/>
    <settingmailimage ref="image" @update="loadData()"/>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Templates normali" active>
          <b-modal v-model="templates.showFilter" title="Filtra">
            <b-form-group label="Template">
              <b-form-input v-model="templates.tempFilter.template"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetTemplates()">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetTemplates()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataTemplates()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="templates.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.template.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="templates.items.data" :fields="templates.fields" :busy="templates.isBusy" :no-local-sorting="true" :sort-by.sync="templates.search.sortby" :sort-desc.sync="templates.search.sortdesc" @sort-changed="sortingChangeTemplates" @row-clicked="$refs.template.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="templates.isBusy" :currentpage="templates.search.page" :total="templates.items.total" @updateCurrentPage="templates.search.page = $event" @loadData="loadDataTemplates()"/>
        </b-tab>
        <b-tab title="Templates marketing">
          <b-modal v-model="templatesmarketing.showFilter" title="Filtra">
            <b-form-group label="Template">
              <b-form-input v-model="templatesmarketing.tempFilter.template"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetTemplatesMarketing()">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetTemplatesMarketing()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataTemplatesMarketing()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="templatesmarketing.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.marketingtemplate.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="templatesmarketing.items.data" :fields="templatesmarketing.fields" :busy="templatesmarketing.isBusy" :no-local-sorting="true" :sort-by.sync="templatesmarketing.search.sortby" :sort-desc.sync="templatesmarketing.search.sortdesc" @sort-changed="sortingChangeTemplatesMarketing" @row-clicked="$refs.marketingtemplate.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>            
          </b-table>
          <tablebottom :busy="templatesmarketing.isBusy" :currentpage="templatesmarketing.search.page" :total="templatesmarketing.items.total" @updateCurrentPage="templatesmarketing.search.page = $event" @loadData="loadDataTemplatesMarketing()"/>
        </b-tab>
        <b-tab title="Immagini online">
          <b-modal v-model="images.showFilter" title="Filtra">
            <b-form-group label="Nome">
              <b-form-input v-model="images.tempFilter.filename"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetImages()">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetImages()">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataImages()">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="images.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.image.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="images.items.data" :fields="images.fields" :busy="images.isBusy" :no-local-sorting="true" :sort-by.sync="images.search.sortby" :sort-desc.sync="images.search.sortdesc" @sort-changed="sortingChangeImages" @row-clicked="$refs.image.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="images.isBusy" :currentpage="images.search.page" :total="images.items.total" @updateCurrentPage="images.search.page = $event" @loadData="loadDataImages()"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import settingmailtemplate from './settingmailtemplate.vue'
import marketingtemplate from './marketingtemplate.vue'
import settingmailimage from './settingmailimage.vue'

export default {
  name: "mailssettings",
  components : { settingmailtemplate, marketingtemplate, settingmailimage },
  data() {
    return {
      templates: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            template: null
          },
          sortby: 'templatename',
          sortdesc: false,
        },
        tempFilter: {
          template: null,
        },
        fields: [{
          key: 'templatename',
          label: 'Template',
          sortable: true,
        },{
          key: 'attachments',
          label: 'Allegati',
        },{
          key: 'mailsboxesusers',
          label: 'Autorizzati',
        }],
        items: []
      },
      templatesmarketing: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            template: null
          },
          sortby: 'marketingtemplate',
          sortdesc: false,
        },
        tempFilter: {
          template: null,
        },
        fields: [{
          key: 'marketingtemplate',
          label: 'Template',
          sortable: true,
        },{
          key: 'createdate',
          label: 'Data',
          sortable: true,
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'campaigns',
          label: 'Campagne',
        }],
        items: []
      },
      images: {
        search: {
          page: 1,
          filters: {
            filename: null
          },
          sortby: 'filename',
          sortdesc: false,
        },
        tempFilter: {
          filename: null
        },
        isBusy: true,
        fields: [{
          key: "domain",
          label: "Dominio",
          sortable: true,
        },{
          key: "filename",
          label: "File",
          sortable: true,
        }],
        items: []
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataTemplates();
      this.loadDataTemplatesMarketing();
      this.loadDataImages();
    },
    loadDataTemplates(){
      this.templates.isBusy = true;
      this.openHttp("settings/mails/templates", this.templates.search ).then((result) => {
        this.templates.items = result;
        this.templates.isBusy = false;
      })
    },
    filterSetTemplates(){
      this.templates.showFilter = false;
      this.templates.search.page = 1;
      this.templates.search.filters = this.templates.tempFilter;
      this.loadDataTemplates();
    },
    filterResetTemplates(){
      this.templates.tempFilter = {
        template: null,
      }
      this.filterSetTemplates();
    },
    sortingChangeTemplates(ctx) {
      this.templates.search.sortby = ctx.sortBy;
      this.templates.search.sortdesc = ctx.sortDesc;
      this.loadDataTemplates();
    },
    loadDataTemplatesMarketing(){
      this.templatesmarketing.isBusy = true;
      this.openHttp("settings/mails/templatesmarketing", this.templatesmarketing.search ).then((result) => {
        this.templatesmarketing.items = result;
        this.templatesmarketing.isBusy = false;
      })
    },
    filterSetTemplatesMarketing(){
      this.templatesmarketing.showFilter = false;
      this.templatesmarketing.search.page = 1;
      this.templatesmarketing.search.filters = this.templates.tempFilter;
      this.loadDataTemplatesMarketing();
    },
    filterResetTemplatesMarketing(){
      this.templatesmarketing.tempFilter = {
        template: null,
      }
      this.filterSetTemplatesMarketing();
    },
    sortingChangeTemplatesMarketing(ctx) {
      this.templatesmarketing.search.sortby = ctx.sortBy;
      this.templatesmarketing.search.sortdesc = ctx.sortDesc;
      this.loadDataTemplatesMarketing();
    },
    loadDataImages(){
      this.images.isBusy = true;
      this.openHttp("settings/mails/images", this.images.search ).then((result) => {
        this.images.items = result;
        this.images.isBusy = false;
      })
    },
    filterSetImages(){
      this.images.showFilter = false;
      this.images.search.page = 1;
      this.images.search.filters = this.images.tempFilter;
      this.loadDataImages();
    },
    filterResetImages(){
      this.images.tempFilter = {
        filename: null,
      }
      this.filterSetImages();
    },
    sortingChangeImages(ctx) {
      this.images.search.sortby = ctx.sortBy;
      this.images.search.sortdesc = ctx.sortDesc;
      this.loadDataImages();
    }
  }
}
</script>