<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="translate('non conformity to be closed') + ' - ' + data.nonconformitytobeclosed.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Non conformità">
        <b-form-textarea v-model="data.nonconformitytobeclosed.nonconformity" rows="3"/>
      </b-form-group>
      <b-row>
        <b-col lg="10">
          <b-form-group label="Punti norma">
            <b-form-checkbox-group v-model="data.nonconformitytobeclosed.standardspoints">
              <b-form-group v-for="item in data.points" :key="item.standard" :label="item.standard">
                <b-form-checkbox v-for="item2 in item.points" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>          
              </b-form-group>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.nonconformitytobeclosed.auditsnonconformitiescertificationbodiestypes_id" :options="data.types" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Cause">
        <b-form-textarea v-model="data.nonconformitytobeclosed.cause" rows="3"/>
      </b-form-group>
      <b-form-group label="Trattamento">
        <b-form-textarea v-model="data.nonconformitytobeclosed.treatment" rows="3"/>
      </b-form-group>
      <b-form-group label="Azione correttiva">
        <b-form-textarea v-model="data.nonconformitytobeclosed.correctiveaction" rows="3"/>
      </b-form-group>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Esito accettazione / rifiuto">
            <b-form-select v-model="data.nonconformitytobeclosed.effectivenessdecision" :options="effectivenessdecisionCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Note vertifica efficacia">
        <b-form-textarea v-model="data.nonconformitytobeclosed.effectivenessnote" rows="2"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" @click="saveData">{{ translate('save') }}</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "checklistnonconformitytobeclosed",
  data() {
    return {
      show: false,
      data: null,
      effectivenessdecisionCombo: [{
        id: null,
        val: "Decidere"
      },{
        id: true,
        val: 'Accettata'
      },{
        id: false,
        val: 'Non accettata'
      }]
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists/nonconformitytobeclosed/", { id: id, audits_id: auditsId }).then((result) => {
        console.log(result)
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/nonconformitytobeclosed/save", this.data.nonconformitytobeclosed).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData( ){
      this.deleteHttp("checklists/nonconformitytobeclosed/delete", { id: this.data.nonconformitytobeclosed.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>