<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Prenotazione - ' + data.reservation.id" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Collaboratore">
        <b-form-select v-model="data.reservation.users_id" :options="data.users" value-field="id" text-field="val" @change="refreshData()"/>
      </b-form-group>
      <b-row>
        <b-col lg="6">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.reservation.date" @change="refreshData()"/>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Tipologia">
            <b-form-select v-model="data.reservation.night" :options="nightCombo" value-field="id" text-field="val" @change="refreshData()"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Automezzo">
        <b-form-select v-model="data.reservation.vehicles_id" :options="data.vehicles" value-field="id" text-field="val" @change="refreshData()"/>
      </b-form-group>
      <b-form-group label="Riferimento">
        <b-form-select v-model="data.reservation.ordersdays_id" :options="data.ordersdays" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.reservation.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "vehiclereservation",
  data() {
    return {
      show: true,
      data: null,
      nightCombo: [{
        id: false,
        val: "Giornaliero",
      },{
        id: true,
        val: "Serale",
      }]
    }
  },
  methods: {
    loadData(id){
      this.openHttp("vehicles/reservation/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    refreshData(){
      this.openHttp("vehicles/reservation/avaiable", { id: this.data.reservation.id, users_id: this.data.reservation.users_id, date: this.data.reservation.date, night: this.data.reservation.night }).then((result) => {
        this.data.vehicles = result.vehicles;
        this.data.ordersdays = result.ordersdays;
      })
    },
    saveData(){
      this.saveHttp("vehicles/reservation/save", this.data.reservation).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("vehicles/reservation/delete", { id: this.data.reservation.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>