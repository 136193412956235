<template>
    <div>
      <b-modal v-if="data !== null" v-model="show" :title="'Utente - ' + data.user.id" size="xl" @hidden="closeForm()" @close="closeForm()">
        <b-row>
          <b-col lg="1">
            <b-form-group label="Titolo">
              <b-form-select v-model="data.user.titles_id" :options="data.titles" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Nome">
              <b-form-input v-model="data.user.firstname"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Cognome">
              <b-form-input v-model="data.user.lastname"/>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Mail">
              <b-form-input v-model="data.user.mail" :disabled="!data.user.active"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Password">
              <b-form-input v-model="data.user.psw" :disabled="!data.user.active"/>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <b-form-group label="Stato">
              <b-form-select v-model="data.user.active" :options="activeCombo" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <b-form-group label="Tipo">
              <b-form-select v-model="data.user.internal" :options="internalCombo" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="1">
            <b-form-group label="Piano">
              <b-form-select v-model="data.user.plan" :options="yesnoCombo" value-field="id" text-field="val" :disabled="!data.user.active"/>
            </b-form-group>
          </b-col>
          <b-col lg="1" v-if="data.user.internal === true">
            <b-form-group label="PBX">
              <b-form-input type="number" v-model="data.user.pbx" :disabled="!data.user.active"/>
            </b-form-group>
          </b-col>
          <b-col lg="1" v-if="data.user.internal === true">
            <b-form-group label="Segnare ore?">
              <b-form-select v-model="data.user.markhours" :options="yesnoCombo" value-field="id" text-field="val" :disabled="!data.user.active"/>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <b-form-group label="Lingua">
              <b-form-select v-model="data.user.languages_id" :options="data.languages" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Segnalatore">
              <b-form-select v-model="data.user.suppliers_id" :options="data.suppliers" value-field="id" text-field="val"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Autorizzazioni" v-show="data.user.active === true">
          <b-row>
            <b-col v-for="(item, index) in data.forms" :key="index" lg="3">
              <b-row class="mb-1">
                <b-col lg="6">
                  <b-button variant="light" size="sm">{{ item.form }}</b-button>
                </b-col>
                <b-col lg="6">
                  <b-button-group size="sm">
                    <b-button v-for="(item2, index2) in Array(item.maxaccesslevel + 1).keys()" :key="index2" :variant="data.user.forms.findIndex(x => x.id === item.id) === -1 && item2 === 0 ? 'danger' : (data.user.forms.findIndex(x => x.id === item.id && x.accesslevel >= item2) > -1 && item2 != 0 ? 'success' : '')" @click="elem(item.id, item2)">
                      {{ item2 }}
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
            <b-btn variant="danger" v-show="data.user.id > 0" @click="deleteData()">Elimina</b-btn>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "usersuser",
    data() {
      return {
        data: null,
        show: false,
        activeCombo: [{
          id: true,
          val: "Attivo",
        },{
          id: false,
          val: "Non attivo",
        }],
        yesnoCombo: [{
          id: true,
          val: "Si",
        },{
          id: false,
          val: "No",
        }],
        internalCombo: [{
          id: true,
          val: "Interno",
        },{
          id: false,
          val: "Esterno",
        }]
      }
    },
    methods: {
      loadData(id){      
        if ( this.data === null ) this.show = false;
        this.openHttp("settings/erp/user/", { id: id }).then((result) => {
          this.data = result;
          this.show = true;
        })
      },
      elem(formsId, level){
        const elementIndex = this.data.user.forms.findIndex(x => x.id === formsId);
        if (level == 0 && elementIndex > -1) this.data.user.forms.splice(elementIndex, 1);
        if (level > 0 && elementIndex === -1) this.data.user.forms.push({id: formsId, accesslevel: level});
        if (level > 0 && elementIndex > -1) this.data.user.forms.find(x => x.id === formsId).accesslevel = level;
      },
      saveData(){
        this.saveHttp("settings/erp/user/save", this.data.user).then((result) => {
          if (result !== null) this.closeForm();
        })
      },
      deleteData(){
        this.deleteHttp("settings/erp/user/delete", { id: this.data.user.id }).then((result) => {
          if (result !== null) this.closeForm();
        })
      },
      closeForm(){
        this.show = false;
        this.data = null;
        this.$emit('update');
      },
    }
  }
  </script>