<template>
  <div>
    <checklist ref="checklist" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filtri / Filters">
      <b-form-group label="Azienda / Company">
        <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma / Standard">
        <b-form-select v-model="tempFilter.standards_id" :options="addNullCombo(filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ente / Certification body">
        <b-form-select v-model="tempFilter.certificationbodies_id" :options="addNullCombo(filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra / Filter</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">{{ translate('refresh') }}</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">{{ translate('filters') }}</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive hover show-empty mode="remote" class="mt-3" :items="items.data" :fields="fieldsCleaned" :tbody-tr-class="rowClass" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.checklist.loadData($event.id)" caption-top>
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati / There are no results</template>
      <template v-slot:cell(phasesstandards)="row">
        <div v-for="(item, index) of row.value" :key="index">
          {{ item.phase }}: {{ item.standards }}
        </div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData"/>
  </div>
</template>

<script>
export default {
  name: "checklists",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          companies_id: null,
          standards_id: null,
          certificationbodies_id: null,
        },
        sortby: 'company',
        sortdesc: false,
      },
      tempFilter: {
        companies_id: null,
        standards_id: null,
        certificationbodies_id: null
      },
      filtersMenu: {
        companies:[],
        standards:[],
        certificationbodies: [],
      },
      fields: [{
        key: 'company',
        label: 'company',
        sortable: true,
      },{
        key: 'certificationbody',
        label: 'certification body',
        sortable: true,
      },{
        key: 'status',
        label: "status",
        sortable: true,
      },{
        key: 'phasesstandards',
        label: "standards and phases"
      },{
        key: 'dates',
        label: 'dates',
      },{
        key: 'check_percentage',
        label: 'percentage',
      },{
        key: 'users',
        label: 'authorized',
      },{
        key: 'fail',
        label: 'to be corrected',
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    fieldsCleaned(){
      return this.fields.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    rowClass(item){
      if ( item !== null ) return item.fail > 0 ? "table-danger" : "";
    },
    loadData(){
      this.isBusy = true;
      this.openHttp("checklists/", this.search).then((result) => {
        this.isBusy = false;
        this.items = result;
      })
    },
    filterOpen(){
      this.openHttp("checklists/filters").then((result) => {
        this.filtersMenu = result;
        this.showFilter = true;
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        companies_id: null,
        standards_id: null,
        certificationbodies_id: null,
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>