<template>
  <div>
    <homepageorderday ref="orderday" @update="loadCalendar()"/>
    <orderhour ref="hour" @update="loadHours()"/>
    <communication ref="communication" @update="loadCommunications()"/>
    <b-row>
      <b-col lg="6" v-if="$store.getters.checkRoute('ordershours', 2)">
        <b-btn variant="primary" size="sm" @click="loadMarkHours">Aggiorna</b-btn>
        <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" mode="remote" class="mt-2" :items="markHours.data" :fields="markHours.fields"/>
      </b-col>
      <b-col lg="6" v-if="vehicles.data.length > 0">
        <b-form-group label="Manutenzioni Veicoli">
          <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" :items="vehicles.data" :fields="vehicles.fields"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="10">
        <b-btn size="sm" variant="primary" class="mr-1" @click="previousMonth">&#60;</b-btn>
        <b-btn size="sm" class="mr-1" @click="loadCalendar">{{ (calendar.month + 1) + '-' + calendar.year }}</b-btn>
        <b-btn size="sm" variant="primary" class="mr-3" @click="nextMonth">&#62;</b-btn>
        <b-table-simple :sticky-header="(winHeight - 170) + 'px'" class="mt-3">
          <b-thead>
            <b-tr>
              <b-th v-for="item in calendar.days" :key="item.id">{{ translate(item.val) }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in calendar.weeks" :key="item.week">
              <b-td v-for="item2 in item.days" :key="item2.day" :style="{ width: item2.id < 5 ? '18%' : '5%' }" style="border-right: 2px solid #dee2e6; border-top: 2px solid #dee2e6; border-left: 2px solid #dee2e6; border-bottom: 2px;">
                <div style="min-height: 150px">
                  <div style="display: flex; justify-content: flex-end">
                    <div>{{ item2.day.substring(8, 10) }}</div>
                  </div>
                  <div v-for="appointment in item2.appointments" :key="appointment.id" :style="{ backgroundColor: '#' + appointment.color }" style="padding: 5px; margin-bottom: 5px; border-radius: 5px;" @click="$refs.orderday.loadData(appointment.id)">
                    <b>{{ appointment.ordersdaystype }}<br></b>
                    <b v-if="appointment.company !== null">{{ appointment.company + ' (' + appointment.orders_id + ')' }}</b>
                    <div v-if="appointment.note !== null">{{ appointment.note }}</div>
                    <b v-if="appointment.morning === true && appointment.afternoon === false">Mattina</b>
                    <b v-if="appointment.morning === false && appointment.afternoon === true">Pomeriggio</b>
                  </div>
                </div>
                <div v-for="vehicle in item2.vehicles" :key="vehicle.id" style="border-style: solid; border-color: #92a8d1; padding: 5px; margin-top: 5px; border-radius: 5px;">
                  {{ vehicle.vehicle }} <b v-if="vehicle.night === true">Serale</b>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col lg="2" v-if="calendar.show">
        <b-row v-if="$store.getters.checkRoute('ordershours', 1)">
          <b-col lg="8">
            <b-form-input type="date" size="sm" v-model="hours.date" @input="loadHours"/>
          </b-col>
          <b-col lg="4">
            <b-btn variant="primary" size="sm" @click="loadHours">{{ translate("refresh") }}</b-btn>
          </b-col>
          <b-col lg="12">
            <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" mode="remote" class="mt-3" :items="hours.data" :fields="fieldsCleanedHours">
              <template v-slot:cell(details)="row">
                <b-btn size="sm" @click="row.toggleDetails">{{ row.detailsShowing ? 'Nascondi' : 'Dettagli'}}</b-btn>
              </template>
              <template v-slot:row-details="row">
                <b-table hover :items="row.item.detail" :fields="hours.fieldsDetails" @row-clicked="$refs.hour.loadData([], $event.id)"/>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="$store.getters.checkRoute('communications', 1)">
          <b-col>
            <b-btn variant="primary" size="sm" @click="loadCommunications">{{ translate("refresh") }}</b-btn>
            <b-table class="mt-3" :items="communication.data" :fields="fieldsCleanedCommunications" @row-clicked="$refs.communication.loadData($event.id)"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import homepageorderday from './homepageorderday.vue';

export default {
  name: "homepage",
  components: { homepageorderday },
  data() {
    return {
      calendar: {
        days: [{
          id: 0,
          val: "monday",
        },{
          id: 1,
          val: "tuesday",
        },{
          id: 2,
          val: "wednesday",
        },{
          id: 3,
          val: "thursday",
        },{
          id: 4,
          val: "friday",
        },{
          id: 5,
          val: "saturday",
        },{
          id: 6,
          val: "sunday",
        }],
        daylist: [],
        weeks: [],
        month: null,
        year: null,
        show: false,
        data: [],
      },
      hours: {
        date: null,
        show: false,
        data: [],
        fields:[{
          key: 'fullname',
          label: "collaborator"
        },{
          key: 'totaldone',
          label: 'hours'
        },{
          key: 'details',
          label: ''
        }],
        fieldsDetails:[{
          key: 'company',
          label: 'Azienda'
        },{
          key: 'orders_id',
          label: 'Commessa'
        },{
          key: 'done',
          label: 'Ore'
        },{
          key: 'note',
          label: 'Note'
        }]
      },
      communication: {
        show: false,
        data: [],
        fields:[{
          key: "sender",
          label: "sender"
        },{
          key: "date",
          label: "date",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "subject",
          label: "subject"
        }]
      },
      markHours: {
        data: [],
        fields: [{
          key: "fullname",
          label: "Collaboratore"
        },{
          key: "dates",
          label: "Non segnati"
        }],
      },
      vehicles: {
        data: [],
        fields: [{
          key: "vehicle",
          label: "Veicolo"
        },{
          key: "maintenances",
          label: "Manutenzioni"
        }]
      }
    }
  },
  computed: {
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "").accesslevel;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    fieldsCleanedHours(){
      return this.hours.fields !== undefined ? this.hours.fields.map((element) => {
        element.label = this.translate(element.label);
        return element
      }) : [];
    },
    fieldsCleanedCommunications(){
      return this.communication.fields.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    }
  },
  mounted(){
    this.loadCalendar();
    if (this.$store.getters.getRoutes.find(x => x.route === "ordershours").accesslevel >= 1) this.loadHours();
    if (this.$store.getters.getRoutes.find(x => x.route === "ordershours").accesslevel >= 1) this.loadMarkHours();
    if (this.$store.getters.getRoutes.find(x => x.route === "communications").accesslevel >= 1) this.loadCommunications();
    if (this.$store.getters.getRoutes.find(x => x.route === "vehicles").accesslevel >= 1) this.loadVehicles();
  },
  methods: {
    previousMonth(){
      this.calendar.month = this.calendar.month - 1;
      if ( this.calendar.month === -1 ){
        this.calendar.month = 11;
        this.calendar.year = this.calendar.year - 1;
      }
      this.loadCalendar();
    },
    nextMonth(){
      this.calendar.month = this.calendar.month + 1;
      if ( this.calendar.month === 12 ){
        this.calendar.month = 0;
        this.calendar.year = this.calendar.year + 1;
      }
      this.loadCalendar();
    },
    loadCalendar(){
      this.calendar.show = false;
      let today = new Date();
      if ( this.calendar.month === null ) this.calendar.month = today.getMonth();
      if ( this.calendar.year === null ) this.calendar.year = today.getFullYear();
      let fromDate = new Date( this.calendar.year, this.calendar.month, 1);
      let day = fromDate.getDay();
      fromDate = new Date( fromDate.setDate(fromDate.getDate() - day + (day == 0 ? -6 : 1)));
      let toDate = new Date( this.calendar.year, this.calendar.month + 1, 0);
      toDate = new Date( toDate.setDate(toDate.getDate() - toDate.getDay() + 7 + 1) );
      this.calendar.weeks = [];
      this.openHttp("homepage/ordersdays", { fromdate: fromDate, todate: toDate }).then((result) => {
        if ( result !== null ){
          this.calendar.show = true;
          let dt = fromDate;
          dt.setDate( dt.getDate() + 1 );
          for(this.calendar.daylist = []; dt <= toDate; dt.setDate(dt.getDate()+1)){
            this.calendar.daylist.push( new Date( dt ) );
          }
          this.calendar.daylist = this.calendar.daylist.map((v) => v.toISOString().slice(0,10));
          for( let i = 0; i <= Math.floor(this.calendar.daylist.length / 7) - 1; i++ ){
            let week = {
              week: i,
              days: [],
            };
            for( let j = 0; j < 7; j++ ){
              week.days.push({
                id: j,
                day: this.calendar.daylist[ i * 7 + j ],
                appointments: result.calendar.filter(x => x.date === this.calendar.daylist[i * 7 + j]),
                vehicles: result.vehicles.filter(x => x.date === this.calendar.daylist[i * 7 + j]),
              })
            }
            this.calendar.weeks.push(week);
          }
        }
      })
    },
    loadHours(){
      let d = new Date();
      if ( this.hours.date === null ) this.hours.date = [ d.getFullYear(), d.getMonth() + 1, d.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      this.openHttp("homepage/hours", { date: this.hours.date }).then((result) => {
        if ( result !== null ){
          this.hours.show = true;
          this.hours.data = result;
        } else {
          this.hours.show = false;
        }
      })
    },
    loadCommunications(){
      this.communication.show = false;
      this.openHttp("homepage/communications", {}).then((result) => {
        if ( result !== null ){
          this.communication.show = true;
          this.communication.data = result;
        } else {
          this.communication.show = false;
        }
      })
    },
    loadMarkHours(){
      this.openHttp("homepage/markhours", { }).then((result) => {
        if ( result !== null ) this.markHours.data = result;
      })
    },
    loadVehicles(){
      this.openHttp("homepage/vehicles", { }).then((result) => {
        if ( result !== null ) this.vehicles.data = result;
      })
    }
  }
}
</script>