<template>
    <div>
      <b-modal v-if="data !== null" v-model="show" :title="'KPI - ' + data.kpi.id" size="lg" @hidden="closeForm()" @close="closeForm()">
        <b-form-group label="Indicatore">
          <b-form-input v-model="data.kpi.kpi"/>
        </b-form-group>
        <b-form-group label="Sql">
          <b-form-textarea v-model="data.kpi.kpisql" rows="10" @change="test = null"/>
        </b-form-group>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
            <b-btn :variant="test === null ? 'warning' : (test === false ? 'danger' : 'success')" v-show="data.kpi.id > 0" class="mr-2" @click="testData()">Test</b-btn>
            <b-btn variant="danger" v-show="data.kpi.id > 0" @click="deleteData()">Elimina</b-btn>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "usersform",
    data() {
      return {
        show: false,
        data: null,
        test: null,
        yesnoCombo: [{
          id: true,
          val: "Si",
        },{
          id: false,
          val: "No",
        }],
      }
    },
    methods: {
      loadData( id ){
        this.openHttp("settings/erp/kpi/", { id: id }).then((result) => {
          this.data = result;
          this.show = true;
        })
      },
      saveData(){
        this.saveHttp("settings/erp/kpi/save", this.data.kpi).then((result) => {
          if (result !== null) this.loadData(result);
        })
      },
      testData(){
        this.test = null;
        this.saveHttp("settings/erp/kpi/test", this.data.kpi).then((result) => {
          this.test = result;
        })
      },
      deleteData(){
        this.deleteHttp("settings/erp/kpi/delete", { id: this.data.kpi.id }).then((result) => {
          if (result !== null) this.closeForm();
        })
      },
      closeForm(){
        this.data = null;
        this.show = false;
        this.$emit('update');      
      }
    }
  }
  </script>