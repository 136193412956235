<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Fattura - ' + data.invoice.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Fattura" active>
            <b-row>
              <b-col lg="1">
                <b-form-group label="Progressivo">
                  <b-form-input v-model="data.invoice.progressive"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data">
                  <b-form-input type="date" v-model="data.invoice.date"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <company ref="company"/>
                <label><b-link v-if="data.invoice.companies_id > 0" @click="$refs.company.loadData(data.invoice.companies_id)">Azienda</b-link> <span v-else>Azienda</span></label> <b-badge v-if="vies === null" variant="warning" @click="loadVies()">Vies ?</b-badge><b-badge v-else :variant="vies.vies === true ? 'success' : 'danger'" @click="loadVies()">Vies {{ vies.date }}</b-badge>
                <multiselect v-model="companies_idSupport" :options="data.companies" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.invoice.companies_id = updateComboSimple(companies_idSupport)" @search-change="asyncCompanies" />
              </b-col>
              <b-col lg="3">
                <b-form-group label="Ditta">
                  <b-form-select v-model="data.invoice.internalcompanies_id" :options="data.internalcompanies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.invoice.invoicestypes_id" :options="data.invoicestypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Causale">
                  <b-form-select v-model="data.invoice.invoicescausals_id" :options="data.invoicescausals" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Valuta">
                  <b-form-select v-model="data.invoice.invoicesuniformsmonetary_id" :options="data.invoicesuniformsmonetary" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Commesse">
              <b-btn v-if="data.invoice.progressive === null" variant="primary" class="mb-3" size="sm" @click="loadOrders()">Carica Commesse</b-btn>
              <b-table show-empty :items="data.invoice.orders" :fields="fields.orders">
                <template v-slot:empty>Non ci sono risultati</template>
                <template v-slot:cell(services)="row">
                  <div v-for="item in row.value" :key="item">{{ item }}</div>
                </template>
                <template v-slot:cell(invoiced)="row">
                  <b-form-input type='number' step='0.01' :max="row.item.cost > 0 ? row.item.billable : 0" :min="row.item.cost < 0 ? row.item.billable : 0" v-model="row.item.invoiced" @blur="calculateTotal()"/>
                </template>
                <template v-slot:cell(note)="row">
                  <b-form-textarea v-model="row.item.note" :disabled="row.item.invoiced === 0"/>
                </template>                    
              </b-table>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Imponibile">
                  <b-form-input type='number' step='0.01' v-model="data.invoice.exvat"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="% IVA">
                  <b-form-input type='number' step='0.01' v-model="data.invoice.taxesperc" @change="calculateTotal()"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Tasse">
                  <b-form-input type='number' step='0.01' v-model="data.invoice.taxes"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Totale">
                  <b-form-input type='number' step='0.01' v-model="data.invoice.total"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Note">
                  <b-form-textarea v-model="data.invoice.note"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Pagamenti">
            <b-row>
              <b-col lg="3">
                <b-form-group label="Tempi di Pagamento">
                  <b-form-select v-model="data.invoice.invoicespaymenttypes_id" :options="data.paymenttypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Metodo di Pagamento">
                  <b-form-select v-model="data.invoice.invoicesrecesses_id" :options="data.invoicesrecesses" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Banche">
                  <b-form-select v-model="data.invoice.banks_id" :options="data.banks.filter(x=> x.internalcompanies_id === data.invoice.internalcompanies_id)" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Pagamenti">
              <b-btn variant="primary" class="mb-3" size="sm" @click="preparePayments">Calcola</b-btn>
              <b-table responsive show-empty :items="data.invoice.payments" :fields="fields.payments">
                <template v-slot:empty>Non ci sono pagamenti</template>
                <template v-slot:cell(expectedpaydate)="row">
                  <b-form-input type="date" v-model="row.item.expectedpaydate" :disabled="row.item.sdiupload === true"/>
                </template>
                <template v-slot:cell(expectedpayment)="row">
                  <b-form-input type="number" v-model="row.item.expectedpayment" :disabled="row.item.sdiupload === true"/>
                </template>
                <template v-slot:cell(paydate)="row">
                  <b-form-input type="date" v-model="row.item.paydate" :disabled="row.item.sdiupload === true"/>
                </template>
                <template v-slot:cell(payment)="row">
                  <b-form-input type="number" v-model="row.item.payment" :disabled="row.item.sdiupload === true"/>
                </template>
                <template v-slot:cell(id)="row">
                  <b-btn variant="danger" size="sm" @click="data.invoice.payments.splice(data.invoice.payments.findIndex( x => x.id === row.value ), 1)" :disabled="row.item.sdiupload === true">Elimina</b-btn>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Email" v-if="data.invoice.id > 0">
            <mailnew ref="mailnew" @update="loadData(data.invoice.id)"/>
            <mail ref="mail" @update="loadData(data.invoice.id)"/>
            <b-row class="mb-2">
              <b-col lg="4">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 335) + 'px'" :tbody-tr-class="rowClassMails" :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-if="showMail" v-model="showMail" title="Manda" size="lg">
            <b-table :items="data.contacts" :fields="fields.contacts" sortable="true">
              <template v-slot:cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"/>
              </template>
            </b-table>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" @click="prepareMail">Prepara Mail</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn variant="primary" v-show="data.invoice.id > 0" class="mr-2" @click="loadData(data.invoice.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.invoice.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.invoice.id > 0 && data.invoice.invoicestypes_id === 3 && data.invoice.proforma_id === null" @click="generateProforma()">Converti</b-btn>
          <b-btn variant="primary" v-show="data.file !== undefined" class="mr-2" @click="$refs.downloadFormSubmit.submit()">Stampa</b-btn>
          <b-btn variant="info" class="mr-2" href="https://fattureweb.sistemi.com/" target="_blank" v-show="data.invoice.id > 0">Software</b-btn>
          <b-btn variant="info" class="mr-2" href="https://www.sportello.cloud/Run.bc/BCWEB20MENU_UI/SISMENU_HOME20_WEB/@@" target="_blank" v-show="data.invoice.id > 0">Cloud</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.invoice.id > 0" @click="showMail = true">Prepara Mail</b-btn>
        </div>
      </template>
      <form method="post" action="/api/invoices/document/download" ref="downloadFormSubmit" target="_blank">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="id" :value="data.file !== undefined ? data.file : null">
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "invoice",
  data() {
    return {
      show: true,
      data: null,
      companies_idSupport: [],
      vies: null,
      showMail: false,
      fields: {
        orders: [{
          key: 'orders_id',
          label: "Commessa",
          sortable: true
        },{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'services',
          label: 'Servizi',
        },{
          key: 'cost',
          label: 'Preventivo',
          formatter: (value, key, item) => {
            return item.cost + ( item.otherinvoiced !== 0 ? ' (già fatturato ' + item.otherinvoiced + ')' : '' )
          },
        },{
          key: 'invoiced',
          label: 'Fatturato',
        },{
          key: 'note',
          label: 'Note in fattura',
        }],
        payments: [{
          key: 'expectedpaydate',
          label: 'Data prevista',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'expectedpayment',
          label: "Importo previsto",
        },{
          key: 'paydate',
          label: "Data pagamento",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "payment",
          label: "Importo pagato",
        },{
          key: "id",
          label: "",
        }],
        contacts: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "contactname",
          label: "Nome",
          sortable: true
        },{
          key: "contact",
          label: "Mail",
          sortable: true
        },{
          key: "active",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "job",
          label: "Mansione",
          sortable: true
        },{
          key: "note",
          label: "Note",
          sortable: true
        },{
          key: "areas",
          label: "Aree",
          sortable: true
        },{
          key: "selected",
          label: "",
          sortable: true
        }],
        mailsCriteria: null,
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true
        },{
          key: 'fromaddress',
          label: "Da"
        },{
          key: 'toaddress',
          label: 'A',
        },{
          key: 'subject',
          label: "Oggetto",
        },{
          key: 'datetime',
          label: "Data ed ora",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }]
      }
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    loadData(id){
      this.fields.mailsCriteria = null;
      this.showMail = false;
      if ( this.data === null ) this.show = false;
      this.openHttp("invoices/invoice/", { id: id }).then((result) => {
        this.companies_idSupport = this.convertComboSimple( result.companies, result.invoice.companies_id );
        this.data = result;
        this.show = true;
        if (id > 0) {
          this.loadVies()
        } else {
          this.vies = null;
        }
      })
    },
    asyncCompanies ( query ) {
      if ( query.length > 3 ){
        this.openHttp("invoices/invoice/companies", { search: query } ).then((result) => {
          for ( let item of result ) if ( this.data.companies.findIndex( x => x.id === item.id ) === -1 ) this.data.companies.push(item);
          this.data.companies = this.data.companies.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
        })
      }
    },
    loadVies(){
      this.vies = null;
      //if (this.data.invoice.companies_id > 0){
      //  this.openHttp("invoices/invoice/vies", { id : this.data.invoice.companies_id } ).then((result) => {
      //    this.vies = result === undefined ? null : result;
      //  })
      //}
    },
    loadOrders(){
      this.openHttp("invoices/invoice/orders", { companies_id : this.data.invoice.companies_id, internalcompanies_id: this.data.invoice.internalcompanies_id, invoicestypes_id: this.data.invoice.invoicestypes_id } ).then((result) => {
        this.data.invoice.orders = result;
      })
    },
    preparePayments(){
      this.openHttp("invoices/invoice/payments", { date: this.data.invoice.date, total: this.data.invoice.total, invoicespaymenttypes_id: this.data.invoice.invoicespaymenttypes_id }).then((result) => {
        for (let item of result){
          if (this.data.invoice.payments.findIndex( x => x.expectedpaydate === item.expectedpaydate) === -1 ){
            let min_id = -1;
            if ( this.data.invoice.payments.length > 0 ) {
              if ( Math.min.apply(Math, (this.data.invoice.payments).map(x => x.id)) <= min_id ) {
                min_id = Math.min.apply(Math, (this.data.invoice.payments).map(x => x.id)) - 1;
              }
            }
            this.data.invoice.payments.push({
              id: min_id,
              expectedpaydate: item.expectedpaydate,
              expectedpayment: item.expectedpayment,
              paydate: null,
              payment: null,
              sdiupload: false,
            });
          }
        }
      })
    },
    calculateTotal(){
      let exvat = 0;
      this.data.invoice.orders.forEach(function(value) {
        if (value.cost > 0){
          if (parseFloat(value.invoiced) > parseFloat(value.cost)) value.invoiced = parseFloat(value.cost);
          if (parseFloat(value.invoiced) < 0 ) value.invoiced = 0;
        } else {
          if (parseFloat(value.invoiced) < parseFloat(value.cost)) value.invoiced = parseFloat(value.cost);
          if (parseFloat(value.invoiced) > 0 ) value.invoiced = 0;
        }
        exvat = exvat + parseFloat(value.invoiced);
      })
      this.data.invoice.exvat = exvat;
      this.data.invoice.taxes = Math.round(exvat * parseFloat( this.data.invoice.taxesperc ))/ 100;
      this.data.invoice.total = parseFloat(this.data.invoice.exvat) + parseFloat( this.data.invoice.taxes );
    },
    prepareMail(){
      let contactsTos = [];
      let contactsNames = [];
      for (let item of this.data.contacts) {
        if ( item.selected === true ){
          contactsTos.push(item.contact);
          if ( contactsNames.includes(item.contactname) === false ) contactsNames.push(item.contactname);
        }
      }
      var d = new Date(this.data.invoice.date);
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contactsTos.join(","),
        cc: "",
        bcc: "",
        subject: 'Fattura: ' + this.data.invoice.progressive.toString().padStart(4, '0') + String(d.getFullYear()).substring(2, 4),
        lockSubject: true,
        body: "",
        invoices_id: this.data.invoice.id,
        attachments: [{ filename: "Fattura.pdf", id: this.data.file }],
        params: {
          fullname: contactsNames.join(", "),
          invoice: this.data.invoice.progressive.toString().padStart(4, '0') + String(d.getFullYear()).substring(2, 4)
        }
      });
    },
    generateProforma(){
      this.saveHttp("invoices/invoice/proforma", { id: this.data.invoice.id }).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    saveData(){
      this.saveHttp("invoices/invoice/save", this.data.invoice).then((result) => {
        this.saveHttp("invoices/document/generate", this.data.invoice)
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("invoices/invoice/delete", { id: this.data.invoice.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>