<template>
  <div>
    <b-modal id="modal-signature" v-if="data !== null" v-model="show" :title="'Firma - ' + data.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <VueSignaturePad v-if="showSignature" id="signature" width="760px" height="400px" ref="signature" :options="{ onEnd }" :customStyle="{ border: 'black 3px solid' }"/>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>          
          <b-btn variant="warning" class="mr-2" v-show="data.signature !== null" @click="cleanData">Pulisci</b-btn>
          <b-btn variant="danger" v-show="data.signature !== null" @click="deleteData">Elimina</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistsignature",
  data() {
    return {
      show: false,
      data: null,
      showSignature: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      if (this.showSignature === true){
        this.$refs.signature.clearSignature();
        this.launchSignature();
      }
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signature.saveSignature();
      if ( isEmpty === false ) this.data.signature = data;
    },
    launchSignature(){
      this.$refs.signature.fromDataURL(this.data.signature, {width: 760, height: 400});      
    },
    loadData(id){
      this.showSignature = false;
      this.show = false;
      this.openHttp("audits/signature/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
        var self = this;
        setTimeout(function(){
          self.showSignature = true;
          setTimeout(function(){ 
            self.launchSignature();
          }, 100);
        }, 100);
      })
    },
    cleanData(){
      this.$refs.signature.clearSignature();
    },
    saveData(){
      this.saveHttp("audits/signature/save", this.data).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("audits/signature/delete", { id: this.data.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>

<style>
  #modal-signature > .modal-dialog {
    min-height: 600px;
    min-width: 800px;
  }
</style>