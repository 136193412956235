<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="translate('finding') + ' - ' + data.finding.id" @hidden="closeForm()" @close="closeForm()">
      <b-alert show variant="danger">
        E' stata fatta una non-conformità generale dall'ente di accreditamento:<br>
        Le osservazioni attualmente vengono rilasciate in forma "Si raccomanda ..." oppure "E' opportuno ..." oppure "Sarebbe auspicabile ..."<br>
        Le osservazioni vanno date come "Nella documentazione del cliente alcune pratiche non sono propriamente registrate"<br>
        Importante il carattere neutro e senza consigliare o fare alcun tipo di suggerimento<br>
        <b>Grazie per la collaborazione</b>
      </b-alert>
      <b-form-group label="Audit" v-if="data.audits.length > 1">
        <b-form-select size="sm" v-model="data.finding.audits_id" :options="data.audits" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group :label="translate('finding')">
        <b-form-textarea size="sm" v-model="data.finding.opportunity" rows="5"/>
      </b-form-group>
      <b-form-checkbox-group v-model="data.finding.standardspoints">
        <b-form-group v-for="item in data.standardpoints.find(x => x.id === data.finding.audits_id).standardpoints" :key="item.standard" :label="item.standard">
          <b-form-checkbox v-for="item2 in item.points" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>          
        </b-form-group>
      </b-form-checkbox-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">{{ translate('save') }}</b-btn>
          <b-btn variant="danger" v-show="data.finding.id > 0" @click="deleteData">{{ translate('delete') }}</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistopportunity",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists/finding/", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/finding/save", this.data.finding).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("checklists/finding/delete", { id: this.data.finding.id, audits_id: this.data.finding.audits_id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>