<template>
    <div>
        <b-modal v-if="show" v-model="show" size="xl" :title="'Audit precedente - ' + data.previousaudit.id" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Dati" active>
                        <b-row>
                            <b-col lg="8">
                                <b-form-group label="Fase">
                                    <b-form-select v-model="data.previousaudit.contractsphases_id" :options="data.contractsphases" value-field="id" text-field="val"/>
                                </b-form-group>                    
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Data">
                                    <b-form-input v-model="data.previousaudit.plandate" type="date"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Punti">
                            <b-form-checkbox-group v-model="data.previousaudit.standardspoints">
                                <div v-for="(item, index) of data.standardspoints" :key="index">
                                    <strong>{{ item.standard }}</strong> <b-link @click="data.previousaudit.standardspoints = data.previousaudit.standardspoints.concat(item.points.map(x => x.id).filter(x => !data.previousaudit.standardspoints.includes(x)))">Seleziona tutti</b-link> <b-link @click="data.previousaudit.standardspoints = data.previousaudit.standardspoints.filter(x => !item.points.map(y => y.id).includes(x))">Rimuovi tutti</b-link>
                                    <br>
                                    <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                        </b-form-group>
                        <b-form-group label="Siti">
                            <multiselect v-model="sitesSupport" :options="data.sites" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.previousaudit.sites = updateCombo(sitesSupport)"/>
                        </b-form-group>
                        <b-form-group label="Considerazioni">
                            <b-form-textarea v-model="data.previousaudit.considerations" rows="2"/>
                        </b-form-group>
                        <nobr>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.considerations = (data.previousaudit.considerations === null ? '' : data.previousaudit.considerations + ' ') + 'There is no news from media research (checked google news keyword XXXXX)'">There is no news from media ...</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.considerations = (data.previousaudit.considerations === null ? '' : data.previousaudit.considerations + ' ') + 'On the old certificate there is IAF XX. This code is not appropriate for the scope of certification.'">On the old certificate there is IAF ...</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.considerations = (data.previousaudit.considerations === null ? '' : data.previousaudit.considerations + ' ') + 'On the old certificate there is/are IAF XX, XX. This/These code/s is/are appropriate for the scope of certification, but not sufficient. Some codes are missing.'">On the old certificate there is/are IAF ...</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.considerations = (data.previousaudit.considerations === null ? '' : data.previousaudit.considerations + ' ') + 'No exclusion was present on the old certificate. Assess exclusion clause XX (es. 7.1.5).'">No exclusion was present on the old certificate ...</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.considerations = (data.previousaudit.considerations === null ? '' : data.previousaudit.considerations + ' ') + 'The exclusion of paragraph XXX (es. 8.5.1.f) is stated in the report, but has not been reported in the specific section on the first page of that document.'">The exclusion of paragraph XXX ...</b-btn>
                        </nobr>
                        <b-form-group label="Lamentele">
                            <b-form-textarea v-model="data.previousaudit.complaints" rows="2"/>
                        </b-form-group>
                        <nobr>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.complaints = 'There are no complaints.'">There are no complaints</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.complaints = 'There are complaints about '">There are complaints</b-btn>
                        </nobr>
                        <b-form-group label="Azioni intraprese (relative alle lamentele)">
                            <b-form-textarea v-model="data.previousaudit.actionstaken" rows="2"/>
                        </b-form-group>
                        <nobr>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.actionstaken = 'There are no complaints and actions taken.'">There are no complaints</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.actionstaken = 'There are complaints and the actions taken are '">There are no complaints</b-btn>
                        </nobr>
                        <b-form-group label="Impegno con enti regolatori">
                            <b-form-textarea v-model="data.previousaudit.regulatorybodies" rows="2"/>
                        </b-form-group>
                        <nobr>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.regulatorybodies = 'There are no current engagements with regulatory bodies.'">There are no engagements</b-btn>
                            <b-btn variant="info" class="mr-2" size="sm" @click="data.previousaudit.regulatorybodies = 'There are engagements with regulatory bodies like '">There are engagements</b-btn>
                        </nobr>
                    </b-tab>
                    <b-tab title="Osservazioni Non conformità">
                        <div v-for="(item, index) in data.standardspoints" :key="index">
                            <label>{{ item.standard }}</label>
                            <b-row>
                                <b-col v-for="(item2, index2) in item.points" :key="index2" lg="1">
                                    <b-form-group :label="item2.val">
                                        <b-input-group v-for="(item3, index3) in data.nonconformitiesypes" :prepend="item3.val" size="sm" :key="index3">
                                            <b-form-input :value="data.previousaudit.nonconformities.findIndex(x => x.id === item2.id && x.nonconformitiestypes_id === item3.id) > -1 ? (data.previousaudit.nonconformities.find(x=> x.id === item2.id && x.nonconformitiestypes_id === item3.id).nonconformitiesnumber) : 0" type="number" size="sm" @change="updateNonConformities(item2.id, item3.id, $event)"/>
                                        </b-input-group>
                                        <b-input-group prepend="Oss" size="sm">
                                            <b-form-input :value="data.previousaudit.findings.findIndex(x => x.id === item2.id) > -1 ? (data.previousaudit.findings.find( x=> x.id === item2.id).findingsnumber) : 0" type="number" size="sm"  @change="updateFindings(item2.id, $event)"/>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Revisione">
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Revisionato da">
                                    <b-form-select v-model="data.previousaudit.users_id" :options="addNullCombo(data.users, 'Da valutare...')" value-field="id" text-field="val"/>
                                </b-form-group>                    
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Data revisione">
                                    <b-form-input v-model="data.previousaudit.reviewdate" type="date" :disabled="data.previousaudit.users_id === null"/>
                                </b-form-group>                    
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Esito revisione">
                                    <b-form-select v-model="data.previousaudit.reviewsuccess" :options="reviewsuccessCombo" value-field="id" text-field="val" :disabled="data.previousaudit.users_id === null"/>
                                </b-form-group>                    
                            </b-col>
                        </b-row>
                        <b-form-group label="Note revisione negativa" v-show="data.previousaudit.reviewsuccess === false">
                            <b-form-textarea v-model="data.previousaudit.reviewnote" rows="2"/>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.previousaudit.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditspreviousaudit",
    data() {
        return {
            show: false,
            data: null,
            sitesSupport: [],
            reviewsuccessCombo: [{
                id: true,
                val: "Positivo"
            },{
                id: false,
                val: "Negativo"
            },{
                id: null,
                val: "In corso"
            }]
        }
    },
    methods: {
        updateNonConformities(standardsPointsId, nonconformititesTypesId, number){
            if (this.data.previousaudit.nonconformities.findIndex(x => x.id === standardsPointsId && x.nonconformitiestypes_id === nonconformititesTypesId) > -1){
                this.data.previousaudit.nonconformities.find(x => x.id === standardsPointsId && x.nonconformitiestypes_id === nonconformititesTypesId).nonconformitiesnumber = number
            } else {
                this.data.previousaudit.nonconformities.push({ id:standardsPointsId, nonconformitiestypes_id: nonconformititesTypesId, nonconformitiesnumber:number });
            }
        },
        updateFindings(standardsPointsId, number){
            if (this.data.previousaudit.findings.findIndex(x => x.id === standardsPointsId) > -1){
                this.data.previousaudit.findings.find(x => x.id === standardsPointsId).findingsnumber = number
            } else {
                this.data.previousaudit.findings.push({ id:standardsPointsId, findingsnumber: number });
            }
        },
        loadData( auditsId, id ){
            this.openHttp("audits/previousaudit/", { audits_id: auditsId, id: id } ).then((result) => {
                this.sitesSupport = this.convertCombo(result.sites, result.previousaudit.sites);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/previousaudit/save", this.data.previousaudit).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("audits/previousaudit/delete", { id: this.data.previousaudit.id, audits_id: this.data.previousaudit.audits_id }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true) this.$emit("update");
        }
    },
}
</script>