<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Archiviazione">
      <b-form-group label="Cartella">
        <b-form-select v-model="data.archive.folder" :options="data.folders" value-field="id" text-field="val" @change="loadEmpty"/>
      </b-form-group>
      <b-form-group label="Archiviazione">
        <multiselect v-model="folders_idSupport" :options="data.data" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.archive.folders_id = updateComboSimple(folders_idSupport)" @search-change="asyncMails"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" :disabled="data.archive.folders_id === null" @click="saveData()">Archivia</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "mailarchive",
  data() {
    return {
      show: false,
      data: null,
      comboSupport: [],
      folders_idSupport: null,
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("mails/mail/archive", { id: id, folder: null, search: null }).then((result) => {
        this.folders_idSupport = this.convertComboSimple( result.data, result.archive.folders_id );
        this.data = result;
        this.show = true;
      })
    },
    loadEmpty(){
      this.data.archive.folders_id = null;
      this.data.data = [];
      this.openHttp("mails/mail/archive", { id: this.data.archive.id, folder: this.data.archive.folder, search: null }).then((result) => {
        this.folders_idSupport = this.convertComboSimple(result.data, result.archive.folders_id);
        this.data = result;
        this.show = true;
      })
    },
    asyncMails ( query ) {
      this.openHttp("mails/mail/archive", { id: this.data.archive.id, folder: this.data.archive.folder, search: query }).then((result) => {
        for ( let item of result.data ) {
          if ( this.data.data.findIndex( x => x.id === item.id ) === -1 ) this.data.data.push(item);
        }
        this.data.data = this.data.data.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
      })
    },
    saveData(){
      this.saveHttp("mails/mail/archive/save", this.data.archive).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');
    },
  },
}
</script>