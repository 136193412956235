import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect);

import VueSignature from 'vue-signature-pad';
Vue.use( VueSignature );

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import company from './components/companies/company.vue';
import contract from './components/contracts/contract.vue';
import audit from './components/audits/audit.vue';
import checklist from './components/checklists/checklist.vue';
import quote from './components/quotes/quote.vue';
import order from './components/orders/order.vue';
import communication from './components/communications/communication.vue';
import orderhour from './components/ordershours/orderhour.vue';
import ordercost from './components/orderscosts/ordercost.vue';
import ordersday from './components/ordersdays/ordersday.vue';
import invoice from './components/invoices/invoice.vue';
import incomeinvoice from './components/incomeinvoices/incomeinvoice.vue';
import mail from './components/mails/mail.vue';
import mailnew from './components/mails/mailnew.vue';
import tablebottom from './components/tablebottom.vue';

Vue.component("company", company);
Vue.component("contract", contract);
Vue.component("audit", audit);
Vue.component("checklist", checklist);
Vue.component("quote", quote);
Vue.component("order", order);
Vue.component("communication", communication);
Vue.component("orderhour", orderhour);
Vue.component("ordercost", ordercost);
Vue.component("ordersday", ordersday);
Vue.component("invoice", invoice);
Vue.component("incomeinvoice", incomeinvoice);
Vue.component("mail", mail);
Vue.component("mailnew", mailnew);
Vue.component('tablebottom', tablebottom);

Vue.config.productionTip = false

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyDUkOx5Ommizj1khEimLZhtxVS96TxK1hc', libraries: 'visualization'} });

new Vue({ router, store, render: h => h(App), }).$mount('#app')