<template>
  <div>
    <quote ref="quote" @update="loadData(true)"></quote>
    <b-modal v-model="showFilter" title="Filtra" size="lg">
      <b-row>
        <b-col lg="3">
          <b-form-group label="Preventivo">
            <b-form-input type="number" v-model="tempFilter.id"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Da">
            <b-form-input type="date" v-model="tempFilter.from"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="A">
            <b-form-input type="date" v-model="tempFilter.to"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Stato">
            <multiselect v-model="quotesstatus_idSupport" :options="filtersMenu.quotestatus" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.quotesstatus_id = updateCombo(quotesstatus_idSupport)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Azienda">
            <b-form-select v-model="tempFilter.companies_id" :options="addNullCombo(filtersMenu.companies)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Segnalatore">
            <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Ente">
            <b-form-select v-model="tempFilter.certificationbodies_id" :options="addNullCombo(filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Ditta">
            <b-form-select v-model="tempFilter.internalcompanies_id" :options="addNullCombo(filtersMenu.internalcompanies)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Utente">
            <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Contratto">
            <b-form-select v-model="tempFilter.contract" :options="addNullCombo(contractCombo)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn variant="success" @click="$refs.quote.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 215) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="computedRows" :fields="level >= 3 ? fields3 : fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.quote.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(date)="row">
        <nobr>{{ row.value }}</nobr>
      </template>
      <template v-slot:cell(services)="row">
        <div v-for="(item, index) in row.item.services" :key="index">{{ item }}</div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
  </div>
</template>

<script>
export default {
  name: "quotes",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          id: null,
          quotesstatus_id: [1],
          from: null,
          to: null,
          companies_id: null,
          suppliers_id: null,
          certificationbodies_id: null,
          internalcompanies_id: null,
          users_id: null,
          contract: null,
        },
        sortby: "id",
        sortdesc: true,
      },
      quotesstatus_idSupport : [],
      tempFilter: {
        id: null,
        quotesstatus_id: [1],
        from: null,
        to: null,
        companies_id: null,
        suppliers_id: null,
        certificationbodies_id: null,
        internalcompanies_id: null,
        users_id: null,
        contract: null,
      },
      filtersMenu: {
        quotestatus:[],
        companies:[],
        suppliers:[],
        certificationbodies:[],
        internalcompanies:[],
        users:[],
      },
      contractCombo: [{
        id: 1,
        val: "Non deciso contratto"
      },{
        id: 2,
        val: "OK fare contratto"
      },{
        id: 3,
        val: "Fatto contratto"
      },{
        id: 4,
        val: "NON fare contratto"
      }],
      fields: [{
        key: 'id',
        label: 'N°',
        sortable: true,
      },{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Segnalatore',
        sortable: true,
      },{
        key: 'services',
        label: 'Servizi',
      },{
        key: 'certificationbody',
        label: 'Ente',
        sortable: true,
      },{
        key: 'internalcompany',
        label: 'Ditta',
        sortable: true,
      },{
        key: 'status',
        label: 'Stato',
      },{
        key: 'fullname',
        label: 'Persona',
      }],
      fields3: [{
        key: 'id',
        label: 'N°',
        sortable: true,
      },{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Segnalatore',
        sortable: true,
      },{
        key: 'services',
        label: 'Servizi',
      },{
        key: 'certificationbody',
        label: 'Ente',
        sortable: true,
      },{
        key: 'contract',
        label: 'Contratto',
        formatter: (value, key, item) => {
          if (item.convertcontract === null) return "?";
          if (item.convertcontract === false) return "No";
          if (item.convertcontract === true && item.contracts_id === null) return "Da convertire";
          if (item.convertcontract === true && item.contracts_id !== null) return "Convertito";
        }
      },{
        key: 'internalcompany',
        label: 'Ditta',
        sortable: true,
      },{
        key: 'quote',
        label: 'Totale',
      },{
        key: 'deltapercentage',
        label: 'Delta%',
        sortable: true
      },{
        key: 'deltapercentagesupplier',
        label: 'Delta % NO Segn.',
        sortable: true
      },{
        key: 'status',
        label: 'Stato',
      },{
        key: 'fullname',
        label: 'Persona',
      }],
      items: {
        total: 0,
        data: []
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "quotes").accesslevel;
    },
    computedRows(){
      let tempItems = this.items.data;
      for (let item of tempItems ){
        if (this.level >= 3){
          item._cellVariants = {
            status: item.status_id === 1 ? 'danger' : ( item.status_id === 3 ? 'warning': ( item.status_id === 2 ? 'success': '' )),
            user: item.status_id === 1 ? 'danger' : ( item.status_id === 3 ? 'warning': ( item.status_id === 2 ? 'success': '' ) ),
            deltapercentage: item.deltapercentage < 5 ? 'danger' : ( item.deltapercentage < 20 ? 'warning': 'success' ),
            deltapercentagesupplier: item.deltapercentagesupplier < 5 ? 'danger' : ( item.deltapercentagesupplier < 20 ? 'warning': 'success' )
          }
        } else {
          item._cellVariants = {
            status: item.status_id === 1 ? 'danger' : ( item.status_id === 3 ? 'warning': ( item.status_id === 2 ? 'success': '' )),
            user: item.status_id === 1 ? 'danger' : ( item.status_id === 3 ? 'warning': ( item.status_id === 2 ? 'success': '' ) ),
          }
        }
      }
      return tempItems;
    },
  },
  created(){
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.search.filters.from = [d.getFullYear(), d.getMonth() + 1, 1].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    this.tempFilter.from = this.search.filters.from;
    this.loadData();
  },
  methods: {
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.openHttp("quotes/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("quotes/filters", null).then((result) => {
        this.filtersMenu = result;
        this.quotesstatus_idSupport = this.convertCombo(this.filtersMenu.quotestatus, this.tempFilter.quotesstatus_id);
        this.showFilter = true;
      })
    },
    filterSet(){
      this.search.page = 1;
      this.showFilter = false;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        id: null,
        quotesstatus_id: [1],
        from: null,
        to: null,
        companies_id: null,
        suppliers_id: null,
        certificationbodies_id: null,
        internalcompanies_id: null,
        users_id: null,
        contract: null
      };
      this.quotesstatus_idSupport = this.convertCombo(this.filtersMenu.quotestatus, this.tempFilter.quotesstatus_id);
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>