<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Prenotazioni" active>
          <b-modal v-model="reservations.showFilter" title="Filtra">
            <b-form-group label="Da">
              <b-form-input type="date" v-model="reservations.tempFilter.fromdate"/>
            </b-form-group>
            <b-form-group label="A">
              <b-form-input type="date" v-model="reservations.tempFilter.todate"/>
            </b-form-group>
            <b-form-group label="Veicolo">
              <b-form-input v-model="reservations.tempFilter.vehicle"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetReservations">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetReservations">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadReservations">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="reservations.showFilter = true">Filtra</b-btn>
          <b-btn variant="success" @click="$refs.reservation.loadData(0)">Nuovo</b-btn>
          <vehiclereservation ref="reservation" @update="loadData"/>
          <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="reservations.items.data" :fields="reservations.fields" :busy="reservations.isBusy" :no-local-sorting="true" :sort-by.sync="reservations.search.sortby" :sort-desc.sync="reservations.search.sortdesc" @sort-changed="sortingChangeReservations" @row-clicked="$refs.reservation.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="reservations.isBusy" :currentpage="reservations.search.page" :total="reservations.items.total" @updateCurrentPage="reservations.search.page = $event" @loadData="loadReservations()"/>
        </b-tab>
        <b-tab title="Scadenze" v-if="level >= 2">
          <b-btn class="mr-2" variant="primary" @click="loadDeadlines()">Aggiorna</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="deadlines.items.data" :fields="deadlines.fields" :busy="deadlines.isBusy" :no-local-sorting="true" :sort-by.sync="deadlines.search.sortby" :sort-desc.sync="deadlines.search.sortdesc" @sort-changed="sortingChangeDeadlines">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="deadlines.isBusy" :currentpage="deadlines.search.page" :total="deadlines.items.total" @updateCurrentPage="deadlines.search.page = $event" @loadData="loadDeadlines()"/>
        </b-tab>
        <b-tab title="Veicoli" v-if="level >= 2">
          <vehicle ref="vehicle" @update="loadData"/>
          <b-btn class="mr-2" variant="primary" @click="loadVehicles">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.vehicle.loadData(0)">Nuovo</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="vehicles.items.data" :fields="vehicles.fields" :busy="vehicles.isBusy" :no-local-sorting="true" :sort-by.sync="vehicles.search.sortby" :sort-desc.sync="vehicles.search.sortdesc" @sort-changed="sortingChangeVehicles" @row-clicked="$refs.vehicle.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="vehicles.isBusy" :currentpage="vehicles.search.page" :total="vehicles.items.total" @updateCurrentPage="vehicles.search.page = $event" @loadData="loadVehicles()"/>
        </b-tab>
        <b-tab title="Manutenzioni" v-if="level >= 2">
          <b-btn class="mr-2" variant="primary" @click="loadMaintenances">Aggiorna</b-btn>
          <b-btn variant="success" @click="$refs.maintenance.loadData(0)">Nuovo</b-btn>
          <vehiclemaintenance ref="maintenance" @update="loadData"/>
          <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="maintenances.items.data" :fields="maintenances.fields" :busy="maintenances.isBusy" :no-local-sorting="true" :sort-by.sync="maintenances.search.sortby" :sort-desc.sync="maintenances.search.sortdesc" @sort-changed="sortingChangeMaintenances" @row-clicked="$refs.maintenance.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="maintenances.isBusy" :currentpage="maintenances.search.page" :total="maintenances.items.total" @updateCurrentPage="maintenances.search.page = $event" @loadData="loadMaintenances"/>
        </b-tab>
        <b-tab title="Tragitto" v-if="level >= 2">
          <b-modal v-model="paths.showFilter" title="Filtra">
            <b-form-group label="Data">
              <b-form-input type="date" v-model="paths.tempFilter.date"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetPaths">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetPaths">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadPaths">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="paths.showFilter = true">Filtra</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 315) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="paths.items.data" :fields="paths.fields" :busy="paths.isBusy" :no-local-sorting="true" :sort-by.sync="paths.search.sortby" :sort-desc.sync="paths.search.sortdesc" @sort-changed="sortingChangePaths">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="paths.isBusy" :currentpage="paths.search.page" :total="paths.items.total" @updateCurrentPage="paths.search.page = $event" @loadData="loadPaths"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import vehiclereservation from './vehiclereservation.vue';
import vehicle from './vehicle.vue';
import vehiclemaintenance from './vehiclemaintenance.vue';

export default {
  name: "vehicles",
  components: { vehiclereservation, vehicle, vehiclemaintenance },
  data() {
    return {
      reservations: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            fromdate: null,
            todate: null,
            vehicle: null,
          },
          sortby: "vehicle",
          sortdesc: false,
        },
        tempFilter: {
          fromdate: null,
          todate: null,
          vehicle: null,
        },
        fields: [{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "vehicle",
          label: "Veicolo",
          sortable: true,
        },{
          key: 'fullname',
          label: 'Collaboratore',
        },{
          key: 'company',
          label: 'Azienda',
        },{
          key: 'night',
          label: 'Prelievo',
          formatter: value => {
            return value === true ? 'Serale' : 'Giorno';
          },
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      deadlines: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "vehicle",
          sortdesc: false
        },
        fields: [{
          key: "vehicle",
          label: "Veicolo",
          sortable: true,
        },{
          key: "maintenances",
          label: "Manutenzioni",
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      vehicles: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "vehicle",
          sortdesc: false
        },
        fields: [{
          key: "vehicle",
          label: "Veicolo",
          sortable: true,
        },{
          key: "active",
          label: "Stato",
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "users",
          label: "Collaboratori",
        },{
          key: "maintenancetypes",
          label: "Manutenzioni",
        },{
          key: 'kms',
          label: "Km gps",
        },{
          key: "date",
          label: "Aggiornamento",
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      maintenances: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "vehicle",
          sortdesc: false,
        },
        fields: [{
          key: 'vehicle',
          label: 'Veicolo',
          sortable: true,
        },{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'kms',
          label: 'Kms',
        },{
          key: 'mainteinances',
          label: 'Manutenzione',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      paths: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            date: null
          },
          sortby: "vehicle",
          sortdesc: false,
        },
        tempFilter: {
          date: null,
        },
        fields: [{
          key: 'vehicle',
          label: 'Veicolo',
          sortable: true,
        },{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'mintime',
          label: "Inizio",
        },{
          key: 'maxtime',
          label: "Fine",
        },{
          key: 'kmsdone',
          label: 'Km Fatti',
        },{
          key: 'maxspeed',
          label: 'Velocità Max',
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "vehicles").accesslevel;
    },
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadReservations();
      if (this.level >= 2) {
        this.loadDeadlines();
        this.loadVehicles();
        this.loadMaintenances();
        this.loadPaths();
      }
    },
    loadReservations(){
      this.reservations.isBusy = true;
      this.openHttp("vehicles/reservations", this.reservations.search).then((result) => {
        this.reservations.items = result;
        this.reservations.isBusy = false;
      })
    },
    filterSetReservations(){
      this.reservations.showFilter = false;
      this.reservations.search.page = 1;
      this.reservations.search.filters = this.reservations.tempFilter;
      this.loadReservations();
    },
    filterResetReservations(){
      this.reservations.tempFilter = {
        fromdate: null,
        todate: null,
        vehicle: null
      };
      this.filterSetReservations();
    },
    sortingChangeReservations(ctx) {
      this.reservations.search.sortby = ctx.sortBy;
      this.reservations.search.sortdesc = ctx.sortDesc;
      this.loadReservations();
    },
    
    loadDeadlines(){
      this.deadlines.isBusy = true;
      this.openHttp("vehicles/deadlines", this.deadlines.search ).then((result) => {
        this.deadlines.items = result;
        this.deadlines.isBusy = false;
      })
    },
    sortingChangeDeadlines(ctx) {
      this.deadlines.search.sortby = ctx.sortBy;
      this.deadlines.search.sortdesc = ctx.sortDesc;
      this.loadDeadlines();
    },
    
    loadVehicles(){
      this.vehicles.isBusy = true;
      this.openHttp("vehicles/vehicles", this.vehicles.search).then((result) => {
        this.vehicles.items = result;
        this.vehicles.isBusy = false;
      })
    },
    sortingChangeVehicles(ctx) {
      this.vehicles.search.sortby = ctx.sortBy;
      this.vehicles.search.sortdesc = ctx.sortDesc;
      this.loadVehicles();
    },
    loadMaintenances(){
      this.maintenances.isBusy = true;
      this.openHttp("vehicles/maintenances", this.maintenances.search).then((result) => {
        this.maintenances.items = result;
        this.maintenances.isBusy = false;
      })
    },
    sortingChangeMaintenances(ctx) {
      this.maintenances.search.sortby = ctx.sortBy;
      this.maintenances.search.sortdesc = ctx.sortDesc;
      this.loadMaintenances();
    },
    loadPaths(){
      this.paths.isBusy = true;
      this.openHttp("vehicles/paths", this.paths.search).then((result) => {
        this.paths.items = result;
        this.paths.isBusy = false;
      })
    },
    sortingChangePaths(ctx) {
      this.paths.search.sortby = ctx.sortBy;
      this.paths.search.sortdesc = ctx.sortDesc;
      this.loadPaths();
    },
    filterSetPaths(){
      this.paths.showFilter = false;
      this.paths.search.page = 1;
      this.paths.search.filters = this.paths.tempFilter;
      this.loadPaths();
    },
    filterResetPaths(){
      this.paths.tempFilter = {
        date: null
      };
      this.filterSetPaths();
    },
  }
}
</script>