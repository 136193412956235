<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Segnalatori" active>
          <supplier ref="supplier" @update="loadDataSuppliers"/>
          <b-modal v-model="suppliers.showFilter" title="Filtra">
            <b-form-group label="Segnalatore">
              <b-form-input v-model="suppliers.tempFilter.supplier"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="suppliers.tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetSuppliers">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetSuppliers">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataSuppliers">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="suppliers.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.supplier.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="suppliers.items.data" :fields="suppliers.fields" :busy="suppliers.isBusy" :no-local-sorting="true" :sort-by.sync="suppliers.search.sortby" :sort-desc.sync="suppliers.search.sortdesc" @sort-changed="sortingChangeSuppliers" @row-clicked="$refs.supplier.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="suppliers.isBusy" :currentpage="suppliers.search.page" :total="suppliers.items.total" @updateCurrentPage="suppliers.search.page = $event" @loadData="loadDataSuppliers"/>
        </b-tab>
        <b-tab title="Ditte interne">
          <internalcompany ref="internalcompany" @update="loadDataInternalcompanies"/>
          <b-modal v-model="internalcompanies.showFilter" title="Filtra">
            <b-form-group label="Ditta">
              <b-form-input v-model="internalcompanies.tempFilter.internalcompany"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="internalcompanies.tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetInternalcompanies">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetInternalcompanies">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataInternalcompanies">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="internalcompanies.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.internalcompany.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="internalcompanies.items.data" :fields="internalcompanies.fields" :busy="internalcompanies.isBusy" :no-local-sorting="true" :sort-by.sync="internalcompanies.search.sortby" :sort-desc.sync="internalcompanies.search.sortdesc" @sort-changed="sortingChangeInternalcompanies" @row-clicked="$refs.internalcompany.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="internalcompanies.isBusy" :currentpage="internalcompanies.search.page" :total="internalcompanies.items.total" @updateCurrentPage="internalcompanies.search.page = $event" @loadData="loadDataInternalcompanies"/>
        </b-tab>
        <b-tab title="Servizi">
          <service ref="service" @update="loadDataServices"/>
          <b-modal v-model="services.showFilter" title="Filtra">
            <b-form-group label="Servizio">
              <b-form-input v-model="services.tempFilter.servicetitle"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="services.tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetServices">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetServices">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataServices">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="services.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.service.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="services.items.data" :fields="services.fields" :busy="services.isBusy" :no-local-sorting="true" :sort-by.sync="services.search.sortby" :sort-desc.sync="services.search.sortdesc" @sort-changed="sortingChangeServices" @row-clicked="$refs.service.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="services.isBusy" :currentpage="services.search.page" :total="services.items.total" @updateCurrentPage="services.search.page = $event" @loadData="loadDataServices"/>
        </b-tab>
        <b-tab title="Banche" v-if="level >= 2">
          <bank ref="bank" @update="loadDataBanks"/>
          <b-modal v-model="banks.showFilter" title="Filtra">
            <b-form-group label="Banca">
              <b-form-input v-model="banks.tempFilter.name"/>
            </b-form-group>
            <b-form-group label="Stato">
              <b-form-select v-model="banks.tempFilter.active" :options="comboActive" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="filterSetBanks">Filtra</b-btn>
                <b-btn variant="warning" @click="filterResetBanks">Reset</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn class="mr-2" variant="primary" @click="loadDataBanks">Aggiorna</b-btn>
          <b-btn class="mr-2" variant="secondary" @click="banks.showFilter = true">Filtra</b-btn>
          <b-btn class="mr-2" variant="success" @click="$refs.bank.loadData(0)">Nuova</b-btn>
          <b-table no-border-collapse :sticky-header="(winHeight - 305) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="banks.items.data" :fields="banks.fields" :busy="banks.isBusy" :no-local-sorting="true" :sort-by.sync="banks.search.sortby" :sort-desc.sync="banks.search.sortdesc" @sort-changed="sortingChangeBanks" @row-clicked="$refs.bank.loadData($event.id)">
            <template v-slot:table-busy>
              <div class="text-center text-primary">
                <b-spinner class="align-middle"/>
              </div>
            </template>
            <template v-slot:empty>Non ci sono risultati</template>
          </b-table>
          <tablebottom :busy="banks.isBusy" :currentpage="banks.search.page" :total="banks.items.total" @updateCurrentPage="banks.search.page = $event" @loadData="loadDataBanks"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import supplier from './supplier.vue'
import internalcompany from './internalcompany.vue'
import service from './service.vue'
import bank from './bank.vue'

export default {
  name: "settingsorders",
  components : {
    supplier, internalcompany, service, bank
  },
  data() {
    return {
      comboActive: [{
        id: null,
        val: "Tutti",
      },{
        id: true,
        val: "Attivo",
      },{
        id: false,
        val: "Non attivo",
      }],
      suppliers: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            supplier: null,
            active: true,
          },
          sortby: 'supplier',
          sortdesc: false,
        },
        tempFilter: {
          supplier: null,
          active: true,
        },
        fields: [{
          key: 'supplier',
          label: 'Segnalatori',
          sortable: true,
        },{
          key: 'active',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo'
          },
        },{
          key: 'company',
          label: 'Anagrafica',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      internalcompanies: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            internalcompany: null,
            active: true,
          },
          sortby: 'internalcompany',
          sortdesc: false,
        },
        tempFilter: {
          internalcompany: null,
          active: true,
        },
        fields: [{
          key: 'internalcompany',
          label: 'Ditta',
          sortable: true,
        },{
          key: 'active',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo'
          },
        },{
          key: 'company',
          label: 'Anagrafica',
        },{
          key: 'vat',
          label: 'Partita IVA',
        },{
          key: 'taxregime',
          label: 'Tasse',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      services: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            servicetitle: null,
            active: true,
          },
          sortby: 'servicetitle',
          sortdesc: false,
        },
        tempFilter: {
          servicetitle: null,
          active: true,
        },
        fields: [{
          key: 'servicetitle',
          label: 'Servizio',
          sortable: true,
        },{
          key: 'active',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo'
          },
        },{
          key: 'standard',
          label: 'Norma',
          sortable: true,
        },{
          key: 'contractphaseen',
          label: 'Fase',
          sortable: true,
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      banks: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            bank: null,
            active: true,
          },
          sortby: 'bank',
          sortdesc: false,
        },
        tempFilter: {
          bank: null,
          active: true,
        },
        fields: [{
          key: 'tagname',
          label: 'Nome',
          sortable: true,
        },{
          key: 'bankname',
          label: 'Banca',
          sortable: true,
        },{
          key: 'iban',
          label: 'IBAN',
          sortable: true,
        },{
          key: 'active',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo'
          },
        },{
          key: 'internalcompany',
          label: 'Ditta',
        }],
        items: {
          total: 0,
          data: [],
        }
      },

    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "settingsorders").accesslevel;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.loadDataSuppliers();
      this.loadDataInternalcompanies();
      this.loadDataServices();
      if (this.level >= 2) this.loadDataBanks();
    },
    loadDataSuppliers(){
      this.suppliers.isBusy = true;
      this.openHttp("settings/orders/suppliers", this.suppliers.search).then((result) => {
        this.suppliers.isBusy = false;
        this.suppliers.items = result;
      })
    },
    filterSetSuppliers(){
      this.suppliers.showFilter = false;
      this.suppliers.search.page = 1;
      this.suppliers.search.filters = this.suppliers.tempFilter;
      this.loadDataSuppliers();
    },
    filterResetSuppliers(){
      this.suppliers.tempFilter = {
        supplier: null,
        active: true,
      };
      this.filterSetSuppliers();
    },
    sortingChangeSuppliers(ctx) {
      this.suppliers.search.sortby = ctx.sortBy;
      this.suppliers.search.sortdesc = ctx.sortDesc;
      this.loadDataSuppliers();
    },
    loadDataInternalcompanies(){
      this.internalcompanies.isBusy = true;
      this.openHttp("settings/orders/internalcompanies", this.internalcompanies.search).then((result) => {
        this.internalcompanies.items = result;
        this.internalcompanies.isBusy = false;
      })
    },
    filterSetInternalcompanies(){
      this.internalcompanies.showFilter = false;
      this.internalcompanies.search.page = 1;
      this.internalcompanies.search.filters = this.internalcompanies.tempFilter;
      this.loadDataInternalcompanies();
    },
    filterResetInternalcompanies(){
      this.internalcompanies.tempFilter = {
        internalcompany: null,
        active: true,
      };
      this.filterSetInternalcompanies();
    },
    sortingChangeInternalcompanies(ctx) {
      this.internalcompanies.search.sortby = ctx.sortBy;
      this.internalcompanies.search.sortdesc = ctx.sortDesc;
      this.loadDataInternalcompanies();
    },
    loadDataServices(){
      this.services.isBusy = true;
      this.openHttp("settings/orders/services", this.services.search).then((result) => {
        this.services.items = result;
        this.services.isBusy = false;
      })
    },
    filterSetServices(){
      this.services.showFilter = false;
      this.services.search.page = 1;
      this.services.search.filters = this.services.tempFilter;
      this.loadDataServices();
    },
    filterResetServices(){
      this.services.tempFilter = {
        servicetitle: null,
        active: true,
      };
      this.filterSetServices();
    },
    sortingChangeServices(ctx) {
      this.services.search.sortby = ctx.sortBy;
      this.services.search.sortdesc = ctx.sortDesc;
      this.loadDataServices();
    },
    loadDataBanks(){
      this.banks.isBusy = true;
      this.openHttp("settings/orders/banks", this.banks.search).then((result) => {
        if (result.result === false ){
          this.banks.allow = false;
        } else {
          this.banks.allow = true;
          this.banks.isBusy = false;
          this.banks.items = result;
        }
      })
    },
    filterSetBanks(){
      this.banks.showFilter = false;
      this.banks.search.page = 1;
      this.banks.search.filters = this.banks.tempFilter;
      this.loadDataBanks();
    },
    filterResetBanks(){
      this.banks.tempFilter = {
        name: null,
        active: true,
      };
      this.filterSetBanks();
    },
    sortingChangeBanks(ctx) {
      this.banks.search.sortby = ctx.sortBy;
      this.banks.search.sortdesc = ctx.sortDesc;
      this.loadDataBanks();
    }
  }
}
</script>