<template>
  <div>
    <div class="containter-white">
      <b-row>
        <b-col>
          <b-button-group>
            <b-button v-for="(item, index) of languages" :key="index" :variant="lang === item.lang ? 'success' : 'secondary'" @click="lang = item.lang">{{ item.langFull }}</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row v-if="data !== null">
        <externalauditsignature ref="signature" @update="loadData()"/>
        <b-col class="mt-2">
          <b-card no-body>
            <b-tabs card vertical>
              <b-tab active>
                <template #title>
                  <b><font :color="data.audit.findIndex(x => x.signature_initial_check === false) > -1 ? 'red' : 'green'">{{ translateCheck('opening meeting') }}</font></b>
                </template>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.audit" :key="index">
                      <template #title>
                        <b><font :color="item.signature_initial_check === true ? 'green' : 'red'">{{ item.company + ' ' + item.standards }}</font></b>
                      </template>
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th style="width: 25%">{{ translateCheck('parameter') }}</b-th>
                            <b-th>{{ translateCheck('value') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{ translateCheck('company and vat number') }}</b-td>
                            <b-td>{{ item.company + ' - ' + item.vat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('certification body') }}</b-td>
                            <b-td>{{ item.certificationbody }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('scopes') }}</b-td>
                            <b-td>
                              <div v-for="(item, index) of item.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>IAF</b-td>
                            <b-td>{{ item.iafs }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('sites on the certificate') }}</b-td>
                            <b-td>
                              <div v-for="(item2, index2) of item.sitesoncertificates" :key="index2">
                                {{ item2.site + (item2.ismain === true ? ' (' + translateCheck('main') + ')' : '') + (item2.isregistered === true ? ' (' + translateCheck('registered') + ')' : '') }}
                              </div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('points not to be verified') }}</b-td>
                            <b-td>
                              <div v-for="(item, index) of item.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('company representative') }}</b-td>
                            <b-td>
                              <b-form-select v-model="item.workers_id" :options="item.workers" value-field="id" text-field="val" size="sm" @change="saveWorker($event)"/>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('approval of the company') }}</b-td>
                            <b-td>
                              <b-btn :variant="item.signature_initial_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(item.signature_initial_token)">{{ item.signature_initial_check === true ? translateCheck('signed') : translateCheck('to_be_signed') }}</b-btn>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('plan')">
                <b-table-simple responsive>
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th>{{ translateCheck("date") }}</b-th>
                      <b-th>{{ translateCheck("fullname") }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, index) in data.plan" :key="index">
                      <b-td>{{ cleanData(item.date) }}</b-td>
                      <b-td>{{ item.fullnames }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
              <b-tab :title="translateCheck('participants')">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presences" :key="index">
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("fullname") }}</b-th>
                            <b-th>{{ translateCheck("job") }}</b-th>
                            <b-th>{{ translateCheck("signatures") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.presences" :key="index2">
                            <b-td>{{ item2.fullname }}</b-td>
                            <b-td>{{ item2.job }}</b-td>
                            <b-td>
                              <span v-for="(item3, index3) of item2.phases" :key="index3">
                                <b-btn v-for="(item4, index4) of item3.signatures" :key="index4" class="mb-1 ml-1" :variant="item4.check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(item4.token)">{{ item.signature_final_token === true ? translateCheck('signed') : translateCheck('to_be_signed') }}</b-btn>
                              </span>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('strengths')">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.strengths" :key="index">
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("strength") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.strengths" :key="index2">
                            <b-td>{{ item2.strength }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.strengths.length === 0">
                            <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('opportunities')">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.findings" :key="index">
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("opportunity") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.opportunities" :key="index2">
                            <b-td>{{ item2.opportunity }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.opportunities.length === 0">
                            <b-td colspan="2">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab :title="translateCheck('non conformities')">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.nonconformities" :key="index">
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th>{{ translateCheck("nonconformity") }}</b-th>
                            <b-th>{{ translateCheck("decision by client") }}</b-th>
                            <b-th>{{ translateCheck("causes") }}</b-th>
                            <b-th>{{ translateCheck("treatment") }}</b-th>
                            <b-th>{{ translateCheck("correctiveaction") }}</b-th>
                            <b-th>{{ translateCheck("effectiveness") }}</b-th>
                            <b-th>{{ translateCheck("points") }}</b-th>
                            <b-th></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item2, index2) in item.nonconformities" :key="index2">
                            <b-td>{{ item2.nonconformity }}</b-td>
                            <b-td>{{ item2.acceptdecision === true ? translateCheck("accepted") : (item2.acceptdecision === false ? translateCheck("not accepted") : translateCheck("pending")) }}</b-td>
                            <b-td>{{ item2.causes }}</b-td>
                            <b-td>{{ item2.treatment }}</b-td>
                            <b-td>{{ item2.correctiveaction }}</b-td>
                            <b-td>{{ item2.effectivenessdecision === true ? translateCheck("accepted") : (item2.effectivenessdecision === false ? translateCheck("not accepted") : translateCheck("pending")) }}</b-td>
                            <b-td>{{ item2.standardspoints }}</b-td>
                          </b-tr>
                          <b-tr v-show="item.nonconformities.length === 0">
                            <b-td colspan="7">{{ translateCheck('empty') }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab>
                <template #title>
                  <b><font :color="data.audit.findIndex(x => x.signature_final_check === false) > -1 ? 'red' : 'green'">{{ translateCheck('closing meeting') }}</font></b>
                </template>
                <b-card no-body>
                  <b-tabs card>
                    <b-tab v-for="(item, index) of data.audit" :key="index">
                      <template #title>
                        <b><font :color="item.signature_final_check === true ? 'green' : 'red'">{{ item.company + ' ' + item.standards }}</font></b>
                      </template>
                      <b-table-simple responsive>
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th style="width: 25%">{{ translateCheck('parameter') }}</b-th>
                            <b-th>{{ translateCheck('value') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{ translateCheck('company and vat number') }}</b-td>
                            <b-td>{{ item.company + ' - ' + item.vat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('certification body') }}</b-td>
                            <b-td>{{ item.certificationbody }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('strengths') }}</b-td>
                            <b-td>{{ data.strengths.find(x => x.id === item.id).strengths.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('opportunities') }}</b-td>
                            <b-td>{{ data.findings.find(x => x.id === item.id).opportunities.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('non conformities') }}</b-td>
                            <b-td>{{ data.nonconformities.find(x => x.id === item.id).nonconformities.length }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('lead auditor decision') }}</b-td>
                            <b-td>
                              <div v-for="(item2, index2) of item.decisions" :key="index2">
                                {{ item2.phase }}: {{ item2.decisions.find(x => x.lang === lang).val }} {{ item2.note }}
                              </div>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('company representative') }}</b-td>
                            <b-td>
                              {{ item.workers.find(x => x.id === item.workers_id).val }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ translateCheck('approval of the company') }}</b-td>
                            <b-td>
                              <b-btn :variant="item.signature_final_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(item.signature_final_token)">{{ item.signature_final_token === true ? translateCheck('signed') : translateCheck('to_be_signed') }}</b-btn>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </b-tabs>                  
                </b-card>
              </b-tab>
              <!--
              <b-tab :title="translateCheck('documents')">
              </b-tab>
              -->
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import externalauditsignature from './externalauditsignature.vue';

export default {
  name: "checklistexternal",
  components: { externalauditsignature },
  props: ["token"],
  data() {
    return {
      show: false,
      data: null,
      lang: "it",
      languages: [{
        lang: "en",
        langFull: "English",
        dictionary: [
          { word: "opening meeting", translate: "Opening meeting"},
          { word: "plan", translate: "Plan"},
          { word: "date", translate: "Date"},
          { word: "participants", translate: "Participants"},
          { word: "opportunities to be closed", translate: "Opportunities to be closed"},
          { word: "strengths", translate: "Strengths"},
          { word: "opportunities", translate: "Opportunities"},
          { word: "non conformities", translate: "Non conformities"},
          { word: "closing meeting", translate: "Closing meeting"},
          { word: "documents", translate: "Documents"},
          { word: "parameter", translate: "Parameter"},
          { word: "value", translate: "Value"},
          { word: "company and vat number", translate: "Company and VAT number"},
          { word: "certification body", translate: "Certification body"},
          { word: "scopes", translate: "Scopes"},
          { word: "sites on the certificate", translate: "Sites on the Certificate"},
          { word: "points not to be verified", translate: "Points not to be verified"},
          { word: "audited sites", translate: "Audited sites"},
          { word: "company representative", translate: "Company representative"},
          { word: "approval of the company", translate: "Approval of the company"},
          { word: "standards", translate: "Standards"},
          { word: "standard", translate: "Standard"},
          { word: "lead auditor decision", translate: "Lead Auditor Decision"},
          { word: "fullname", translate: "Fullname"},
          { word: "job", translate: "Job"},
          { word: "signatures", translate: "Signatures"},
          { word: "signature", translate: "Signature"},
          { word: "points", translate: "Points"},
          { word: "strength", translate: "Strength"},
          { word: "opportunity", translate: "Opportunity"},
          { word: "nonconformity", translate: "Non Conformity"},
          { word: "decision by client", translate: "Decision by client"},
          { word: "accepted", translate: "Accepted"},
          { word: "not accepted", translate: "Not accepted"},
          { word: "pending", translate: "Pending"},
          { word: "causes", translate: "Causes"},
          { word: "treatment", translate: "Treatment"},
          { word: "correctiveaction", translate: "Corrective Action"},
          { word: "effectiveness", translate: "Effectiveness"},
          { word: "positive", translate: "Positive"},
          { word: "negative", translate: "Negative"},
          { word: "approved", translate: "Approved"},
          { word: "to be approved", translate: "To be approved"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "date_and_time", translate: "Date and time"},
          { word: "main", translate: "Main"},
          { word: "registered", translate: "Registered"},
          { word: "yard", translate: "Yard"},
          { word: "empty", translate: "Empty"},
          { word: "signed", translate: "Signed"},
          { word: "to_be_signed", translate: "To be signed"}
        ]
      },{
        lang: "it",
        langFull: "Italiano",
        dictionary: [
          { word: "opening meeting", translate: "Riunione di apertura"},
          { word: "plan", translate: "Piano"},
          { word: "date", translate: "Data"},
          { word: "participants", translate: "Partecipanti"},
          { word: "opportunities to be closed", translate: "Opportunità da chiudere"},
          { word: "strengths", translate: "Punti di forza"},
          { word: "opportunities", translate: "Opportunità"},
          { word: "non conformities", translate: "Non conformità"},
          { word: "closing meeting", translate: "Riunione di chiusura"},
          { word: "documents", translate: "Documenti"},
          { word: "parameter", translate: "Parametro"},
          { word: "value", translate: "Valore"},
          { word: "company and vat number", translate: "Azienda e partita IVA"},
          { word: "certification body", translate: "Ente di certificazione"},
          { word: "scopes", translate: "Scopi"},
          { word: "sites on the certificate", translate: "Siti sul Certificato"},
          { word: "points not to be verified", translate: "Punti da non verificare"},
          { word: "audited sites", translate: "Siti Auditati"},
          { word: "company representative", translate: "Rappresentante della direzione"},
          { word: "approval of the company", translate: "Approvazione dell'azienda"},
          { word: "standards", translate: "Norme"},
          { word: "standard", translate: "Norma"},
          { word: "lead auditor decision", translate: "Decisione Lead Auditor"},
          { word: "fullname", translate: "Nome completo"},
          { word: "job", translate: "Mansione"},
          { word: "signatures", translate: "Firme"},
          { word: "signature", translate: "Firma"},
          { word: "points", translate: "Punti"},
          { word: "strength", translate: "Punto di forza"},
          { word: "opportunity", translate: "Opportunità"},
          { word: "nonconformity", translate: "Non Conformità"},
          { word: "decision by client", translate: "Decisione del cliente"},
          { word: "accepted", translate: "Accettata"},
          { word: "not accepted", translate: "Non accettata"},
          { word: "pending", translate: "In corso"},
          { word: "causes", translate: "Cause"},
          { word: "treatment", translate: "Trattamento"},
          { word: "correctiveaction", translate: "Azione correttiva"},
          { word: "effectiveness", translate: "Efficacia"},
          { word: "positive", translate: "Positivo"},
          { word: "negative", translate: "Negativo"},
          { word: "approved", translate: "Approvato"},
          { word: "to be approved", translate: "Da approvare"},
          { word: "download", translate: "Download"},
          { word: "file", translate: "File"},
          { word: "main", translate: "Principale"},
          { word: "registered", translate: "Legale"},
          { word: "yard", translate: "Cantiere"},
          { word: "empty", translate: "Vuoto"},
          { word: "signed", translate: "Firmato"},
          { word: "to_be_signed", translate: "Da firmare"}
        ]
      }]
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    translateCheck(word){
      if (this.languages.find(x => x.lang === this.lang).dictionary.findIndex(x => x.word === word) === -1) return '??? ' + word;
      return this.languages.find(x => x.lang === this.lang).dictionary.find(x => x.word === word).translate;
    },
    cleanData(value){
      var d = new Date(value);
      return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    },
    loadData(){
      this.openHttp("checklists/externalaudit/", { token: this.token }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveWorker(event){
      console.log(event);
    },
    downloadData(token){
      this.$refs.downloadFormToken.value = token;
      this.$refs.downloadForm.submit();
    }
  }
}
</script>
