<template>
    <div>
      <b-modal v-if="data !== null" v-model="show" :title="'Mail - ' + data.mailbox.id" scrollable @hidden="closeForm()" @close="closeForm()">
        <b-form-group label="Dominio">
          <b-form-select v-model="data.mailbox.webdomains_id" :options="data.domains" value-field="id" text-field="val"/>
        </b-form-group>
        <b-form-group label="Casella">
          <b-form-input v-model="data.mailbox.email"/>
        </b-form-group>
        <b-form-group label="Autorizzati">
          <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.mailbox.users = updateCombo(usersSupport)"/>
        </b-form-group>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
            <b-btn variant="danger" v-show="data.mailbox.id > 0" @click="deleteData">Elimina</b-btn>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    name: "mailbox",
    data() {
      return {
        show: true,
        data: null,
        usersSupport: []
      }
    },
    methods: {
      loadData(id){
        this.openHttp("settings/erp/mailbox/", { id: id }).then((result) => {
          this.usersSupport = this.convertCombo(result.users, result.mailbox.users);
          this.data = result;
          this.show = true;
        })
      },
      saveData(){
        this.saveHttp("settings/erp/mailbox/save", this.data.mailbox).then((result) => {
          if ( result !== null ) this.closeForm();
        })
      },
      deleteData(){
        this.deleteHttp("settings/erp/mailbox/delete", { id: this.data.mailbox.id }).then((result) => {
          if ( result !== null ) this.closeForm();
        })
      },
      closeForm(){
        this.show = false;
        this.data = null;
        this.$emit('update');
      },
    },
  }
  </script>