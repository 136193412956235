<template>
    <div>
        <b-modal v-if="show" v-model="show" :title="'Storia - ' + data.history.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Norma">
                <b-form-select v-model="data.history.contractsstandards_id" :options="data.contractsstandards" value-field="id" text-field="val" :disabled="data.history.contractsstandardsreleases_id != null"/>
            </b-form-group>
            <b-form-group label="Revisione">
                <b-form-select v-model="data.history.contractsstandardsreleases_id" :options="data.contractsstandardsreleases.filter(x => x.contractsstandards_id == data.history.contractsstandards_id)" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Stato">
                <b-form-select v-model="data.history.contractsstandardsstatus_id" :options="data.contractsstandardsstatus" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Persona">
                <b-form-select v-model="data.history.users_id" :options="addNullCombo(data.users, '-')" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Data">
                <b-form-input v-model="data.history.date" type="date"/>
            </b-form-group>
            <b-form-group label="Stato">
                <b-form-select v-model="data.history.accreditated" :options="[{id: false, val: 'Non accreditato'},{id: true, val: 'Accreditato'}]" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.history.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contracthistory",
    data() {
        return {
            show: false,
            data: null
        }
    },
    methods: {
        loadData(contractsId, id){
            this.openHttp("contracts/history/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/history/save", this.data.history).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/history/delete", { id: this.data.history.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>