<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Allegato - ' + data.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Nome file">
                <b-form-input v-model="data.filename"/>
            </b-form-group>
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/api/settings/mails/template/attachment/download" ref="downloadFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "auditdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token (){
            return this.$store.getters.getToken;
        }
    },
    methods: {
        loadData( mailstemplatesId, id ){
            this.openHttp("settings/mails/template/attachment/", { mailstemplates_id: mailstemplatesId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            if (event.target.files != null){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.data.filename = event.target.files[0].name;
                    this.data.content = reader.result;
                }
            } else {
                reader.readAsDataURL(event.dataTransfer.files[0]);
                reader.onload = ()=> {
                    this.data.filename = event.dataTransfer.files[0].name;
                    this.data.content = reader.result;
                }
            }
        },
        downloadData(id){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadFormSubmit.submit();
        },
        saveData(){
            this.saveHttp("settings/mails/template/attachment/save", this.data).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("settings/mails/template/attachment/delete", { id: this.data.id }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true ) this.$emit("update");
        }
    },
}
</script>