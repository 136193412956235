<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Sito - ' + data.site.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <div v-if="data.site.contractssites_id > 0">
                <b-form-group label="Sito">
                    <b-form-select v-model="data.site.contractssites_id" :options="data.contractssites" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
                </b-form-group>
                <b-row>
                    <b-col lg="2">
                        <b-form-group label="Turni">
                            <b-form-input type="number" v-model="data.site.shifts" :disabled="data.site.locked === true"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Personale totale">
                            <b-form-input type="number" v-model="data.site.employeesnumber" :disabled="data.site.locked === true"/>
                            ogni lavoratore assunto conta come 1 anche se part-time
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Personale effettivo">
                            <b-form-input type="number" v-model="data.site.employeeseffectivenumber" :disabled="data.site.locked === true"/>
                            ogni lavoratore full time conta come 1 mentre il part-time conta per le ore fatte
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Personale esterno">
                            <b-form-input type="number" v-model="data.site.employeesexternal" :disabled="data.site.locked === true"/>
                            lavoratori interinali o collaboratori esterni
                        </b-form-group>
                    </b-col>
                    <b-col lg="2">
                        <b-form-group label="Mansioni ripetitive">
                            <b-form-input type="number" v-model="data.site.employeesrepetitive" :disabled="data.site.locked === true"/>
                            numero di addetti che svolgono mansioni semplici ripetitive
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <b-row>
                    <b-col lg="5">
                        <b-form-group label="Indirizzo">
                            <b-form-input v-model="data.site.address"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="5">
                        <b-form-group label="Città">
                            <multiselect v-model="cities_idSupport" :options="data.cities" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.cities_id = updateComboSimple(cities_idSupport);"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <b-form-group label="Attività">
                <b-form-checkbox-group v-model="data.site.naces" :options="data.naces.filter(x => (data.site.contractssites_id === null) ? (x.yardnec === true) : (x.id > 0))" value-field="id" text-field="val" stacked :disabled="data.site.locked === true"/>
            </b-form-group>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Dati confermati">
                        <b-form-select v-model="data.site.locked" :options="lockedCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Campionato">
                        <b-form-select v-model="data.site.sampled" :options="sampledCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>

            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditsite",
    data() {
        return {
            show: false,
            data: null,
            cities_idSupport: [],
            activeCombo: [{
                id: false,
                val: "Non attivo"
            },{
                id: true,
                val: "Attivo"
            }],
            sampledCombo: [{
                id: false,
                val: "Non campionato"
            },{
                id: true,
                val: "Campionato"
            }],
            lockedCombo: [{
                id: false,
                val: "No"
            },{
                id: true,
                val: "Si"
            }]
        }
    },
    methods: {
        loadData( id, audits_id ){
            this.openHttp("audits/site/", { id: id, audits_id: audits_id }).then((result) => {
                this.cities_idSupport = this.convertComboSimple(result.cities, result.site.cities_id);
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            if (this.data.site.contractssites_id > 0){
                this.data.site.address = null;
                this.data.site.cities_id = null;
            } else {
                this.data.site.shifts = 0;
                this.data.site.employeesnumber = 0;
                this.data.site.employeeseffectivenumber = 0;
                this.data.site.employeesexternal = 0;
                this.data.site.employeesrepetitive = 0;
                this.data.site.contractssites_id = null;
                this.data.site.sampled = true;
            }
            this.saveHttp("audits/site/save", this.data.site).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/site/delete", this.data.site).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>