<template>
  <div>
    <marketingcampaign ref="campaign" @update="loadCampaigns()"/>
    <b-btn class="mr-2" variant="primary" @click="loadCampaigns()">Aggiorna</b-btn>
    <b-btn variant="success" @click="$refs.campaign.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 225) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="campaigns.items.data" :fields="campaigns.fields" :busy="campaigns.isBusy" :no-local-sorting="true" :sort-by.sync="campaigns.search.sortby" :sort-desc.sync="campaigns.search.sortdesc" @sort-changed="sortingChangeCampaigns" @row-clicked="$refs.campaign.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="campaigns.isBusy" :currentpage="campaigns.search.page" :total="campaigns.items.total" @updateCurrentPage="campaigns.search.page = $event" @loadData="loadCampaigns()"/>
  </div>
</template>

<script>
import marketingcampaign from './marketingcampaign';

export default {
  name: "marketing",
  components: {
    marketingcampaign,
  },
  data() {
    return {
      campaigns: {
        isBusy: true,
        search: {
          page: 1,
          filters: {},
          sortby: "createdate",
          sortdesc: true,
        },
        fields: [{
          key: "campaign",
          label: "Campagna",
          sortable: true,
        },{
          key: "enabled",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? 'Attiva' : 'Stoppata';
          },
        },{
          key: "createdate",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'note',
          label: 'Note',
        },{
          key: 'status1',
          label: 'Creato',
        },{
          key: 'status2',
          label: 'Email',
        },{
          key: 'status3',
          label: 'Contatto',
        },{
          key: 'status4',
          label: 'Successo',
        },{
          key: 'status5',
          label: 'Fallito',
        }],
        items: {
          total: 0,
          data: [],
        }
      },
      doneCombo: [{
        id: false,
        val: "Da fare"
      },{
        id: true,
        val: "Fatte"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "marketing").accesslevel;
    },
  },
  created(){
    this.loadCampaigns();
  },
  methods: {
    loadCampaigns(){
      this.campaigns.isBusy = true;
      this.openHttp("marketing/campaigns", this.campaigns.search).then((result) => {
        this.campaigns.items = result;
        this.campaigns.isBusy = false;
      })
    },
    sortingChangeCampaigns(ctx) {
      this.campaigns.search.sortby = ctx.sortBy;
      this.campaigns.search.sortdesc = ctx.sortDesc;
      this.loadCampaigns();
    }
  }
}
</script>