<template>
  <div>
    <order ref="order" @update="loadData(true)"/>
    <b-modal v-if="showFilter" v-model="showFilter" title="Filtra" size="lg">
      <b-row>
        <b-col lg="2">
          <b-form-group label="Commessa">
            <b-form-input type="number" v-model="tempFilter.id"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Stato">
            <multiselect v-model="tempFilter.ordersstates_idSupport" :options="filtersMenu.ordersstates" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.ordersstates_id = updateCombo(tempFilter.ordersstates_idSupport)"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Da">
            <b-form-input type="date" v-model="tempFilter.from"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="A">
            <b-form-input type="date" v-model="tempFilter.to"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Azienda">
        <multiselect v-model="tempFilter.companies_idSupport" :options="filtersMenu.companies" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempFilter.companies_id = updateComboSimple(tempFilter.companies_idSupport)"/>
      </b-form-group>
      <b-form-group label="Servizio">
        <b-form-select v-model="tempFilter.services_id" :options="addNullCombo(filtersMenu.services)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-row>
        <b-col lg="8">
          <b-form-group label="Collaboratore">
            <b-form-select v-model="tempFilter.users_id" :options="addNullCombo(filtersMenu.users)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Mese">
            <b-form-select v-model="tempFilter.months_id" :options="addNullCombo(filtersMenu.months)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Ente">
            <b-form-select v-model="tempFilter.certificationbodies_id" :options="addNullCombo(filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Ditta">
            <b-form-select v-model="tempFilter.internalcompanies_id" :options="addNullCombo(filtersMenu.internalcompanies)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Segnalatore">
            <b-form-select v-model="tempFilter.suppliers_id" :options="addNullCombo(filtersMenu.suppliers)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="IAF">
            <b-form-select v-model="tempFilter.nacesiafcodes_id" :options="addNullCombo(filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen">Filtra</b-btn>
    <b-btn v-if="level >= 2" class="mr-2" variant="success" @click="$refs.order.loadData(0)">Nuovo</b-btn>
    <b-btn v-if="level >= 3" variant="info" @click="$refs.excelFormSubmit.submit()">Excel</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" hover responsive show-empty mode="remote" class="mt-3" :items="computedRows" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.order.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template v-slot:cell(date)="row">
        <nobr>{{row.value}}</nobr>
      </template>
      <template v-slot:cell(services)="row">
        <div v-for="item in row.item.services" :key="item">{{ item }}</div>
      </template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData" :extraText="level >= 3 ? (' - Totale: ' + (items.total_quote > 10 ** 6 ? Math.round(items.total_quote / 10 ** 4 )/ 10 ** 2 + 'M' : ( items.total_quote > 10 ** 3 ? Math.round(items.total_quote / 10 ** 1 )/ 10 ** 2 + 'K' : Math.round( items.total_quote ) ) ) + '€ - Costi: ' + ( items.total_costs > 10 ** 6 ? Math.round(items.total_costs / 10 ** 4 )/ 10 ** 2 + 'M' : ( items.total_costs > 10 ** 3 ? Math.round(items.total_costs / 10 ** 1 )/ 10 ** 2 + 'K' : Math.round( items.total_costs ) ) ) + '€ - Delta: ' + (Math.abs(items.total_delta) > 10 ** 6 ? Math.round(items.total_delta / 10 ** 4 )/ 10 ** 2 + 'M' : ( items.total_delta > 10 ** 3 ? Math.round( items.total_delta / 10 ** 1 )/ 10 ** 2 + 'K' : Math.round( items.total_delta ) )) + '€ - Media: ' + items.avg_delta + '%') : ''"/>
    <form method="post" action="/api/orders/excel" ref="excelFormSubmit" target="_blank">
      <input type="hidden" name="token" :value="token">
      <input type="hidden" ref="downloadFormId" name="data" :value="JSON.stringify(search)">
    </form>
  </div>
</template>

<script>
export default {
  name: "orders",
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          id: null,
          ordersstates_id: [2],
          companies_id: null,
          users_id: null,
          services_id: null,
          months_id: null,
          certificationbodies_id: null,
          internalcompanies_id: null,
          suppliers_id: null,
          from: null,
          to: null,
          nacesiafcodes_id: null
        },
        sortby: "date",
        sortdesc: false,
      },
      tempFilter: {
        id: null,
        ordersstates_id: [2],
        companies_id: null,
        users_id: null,
        services_id: null,
        months_id: null,
        certificationbodies_id: null,
        internalcompanies_id: null,
        suppliers_id: null,
        from: null,
        to: null,
          nacesiafcodes_id: null
      },
      filtersMenu: {
        companies:[],
        internalcompanies:[],
      },
      fields: [{
        key: 'id',
        label: 'N°',
        sortable: true,
      },{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{
        key: 'company',
        label: 'Azienda',
        sortable: true,
      },{
        key: 'services',
        label: 'Servizi',
      },{
        key: 'user',
        label: 'Collaboratore',
      },{
        key: 'invoices',
        label: 'Fatture',
      },{
        key: 'internalcompany',
        label: 'Ditta',
      },{
        key: 'month',
        label: 'Mese',
        sortable: true,
      },{
        key: 'supplier',
        label: 'Segnalatore',
        sortable: true,
      },{
        key: 'certificationbody',
        label: 'Ente',
      },{
        key: 'hoursestimated',
        label: 'h.Prev',
      },{
        key: 'hoursdone',
        label: 'h.Fatte',
      },{
        key: 'quote',
        label: 'Preventivo',
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'costs',
        label: 'Costi',
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'deltaquote',
        label: 'Delta',
        thClass: 'd-none',
        tdClass: 'd-none',
      },{
        key: 'deltaperc',
        label: 'Delta%',
        thClass: 'd-none',
        tdClass: 'd-none',
      }],
      items: {
        total: 0,
        data: [],
        total_quotes: 0,
        total_costs: 0,
        total_deltaquote: 0,
        avg_deltaperc: 0,
      }
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "orders").accesslevel;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    computedRows(){
      let tempItems = this.items.data;
      for ( let item of tempItems ){
        item._cellVariants = {
          id: item.ordersstates_id === 1 ? 'success' : ( item.ordersstates_id === 4 ? 'danger' : '' ),
          invoices: item.paid === true && item.invoiced === true ? 'success' : ( !item.invoices ? 'danger' : 'warning' ),
          hoursestimated: item.hoursdone > item.hoursestimated ? 'danger' : '',
          hoursdone: item.hoursdone > item.hoursestimated ? 'danger' : '',
          quote: item.deltaperc >= 20 ? 'success' : ( item.deltaperc > 0 ? 'warning' : ( item.costs > 0 ? 'danger': '' ) ),
          costs: item.deltaperc >= 20 ? 'success' : ( item.deltaperc > 0 ? 'warning' : ( item.costs > 0 ? 'danger': '' ) ),
          deltaquote: item.deltaperc >= 20 ? 'success' : ( item.deltaperc > 0 ? 'warning' : ( item.costs > 0 ? 'danger': '' ) ),
          deltaperc: item.deltaperc >= 20 ? 'success' : ( item.deltaperc > 0 ? 'warning': ( item.costs > 0 ? 'danger': '' ) )
        }
      }
      return tempItems;
    }
  },
  created(){
    let d = new Date();
    let dMinusMonth3 = new Date(d.setMonth(d.getMonth() - 3));
    const dm3val = [ dMinusMonth3.getFullYear(), dMinusMonth3.getMonth() + 1, dMinusMonth3.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
    this.search.filters.from = dm3val;
    this.tempFilter.from = dm3val;
    this.loadData();
  },
  methods: {
    loadData( notScroll = false ){
      if (notScroll == false) this.isBusy = true;
      this.openHttp("orders/", this.search).then((result) => {
        this.items = result;
        if (this.level >= 3){
          delete this.fields.find(x => x.key === "quote").thClass;
          delete this.fields.find(x => x.key === "quote").tdClass;
          delete this.fields.find(x => x.key === "costs").thClass;
          delete this.fields.find(x => x.key === "costs").tdClass;
          delete this.fields.find(x => x.key === "deltaquote").thClass;
          delete this.fields.find(x => x.key === "deltaquote").tdClass;
          delete this.fields.find(x => x.key === "deltaperc").thClass;
          delete this.fields.find(x => x.key === "deltaperc").tdClass;
        }
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.openHttp("orders/filters", null).then((result) => {
        this.filtersMenu = result;
        this.tempFilter.ordersstates_idSupport = this.convertCombo(this.filtersMenu.ordersstates, this.tempFilter.ordersstates_id);
        this.tempFilter.companies_idSupport = this.convertComboSimple(this.filtersMenu.companies, this.tempFilter.companies_id);
        this.showFilter = true;
        this.$forceUpdate();
      })
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      let d = new Date();
      let dMinusMonth3 = new Date(d.setMonth(d.getMonth() - 3));
      const dm3val = [ dMinusMonth3.getFullYear(), dMinusMonth3.getMonth() + 1, dMinusMonth3.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
      this.tempFilter = {
        id: null,
        ordersstates_id: [2],
        companies_id: null,
        users_id: null,
        services_id: null,
        months_id: null,
        certificationbodies_id: null,
        internalcompanies_id: null,
        suppliers_id: null,
        from: dm3val,
        to: null,
        nacesiafcodes_id: null
      };
      this.tempFilter.ordersstates_idSupport = this.convertCombo(this.filtersMenu.ordersstates, this.tempFilter.ordersstates_id);
      this.tempFilter.companies_idSupport = this.convertComboSimple(this.filtersMenu.companies, this.tempFilter.companies_id);
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>