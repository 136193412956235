<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" size="lg" title="Report">
            <customeditor v-model="data.report" :height="winHeight - 360" :showToolbar="true"/>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import customeditor from '../../customeditor.vue';

export default {
    name: "auditreport",
    components: {
        customeditor
    },
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
    },
    methods: {
        loadData(auditsId, standardspointsId, languagesId){
            this.openHttp("audits/report/report", { audits_id: auditsId, standardspoints_id: standardspointsId, languages_id: languagesId }).then((result) => {
                this.show = true;
                this.data = result;
            })
        },
        saveData(){
            this.saveHttp("audits/report/report/save", this.data).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.data = null;
            this.show = false;
            this.$emit("update");
        },
    },
}
</script>