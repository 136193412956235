<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Parametro - ' + data.parameter.contractsparameterstypes_id" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>  
                <b-tabs card>
                    <b-tab title="Parametro" active>
                        <b-form-group :label="data.parameterstypes.find(x => x.id === data.parameter.contractsparameterstypes_id).val">
                            <b-form-input v-if="data.parameterstypes[0].format === 'string'" v-model="data.parameter.val"/>
                            <b-form-textarea v-if="data.parameterstypes[0].format === 'text'" v-model="data.parameter.val" rows="3"/>
                            <b-form-input type="number" v-if="data.parameterstypes[0].format === 'number'" v-model="data.parameter.val"/>
                            <b-form-input type="date" v-if="data.parameterstypes[0].format === 'date'" v-model="data.parameter.val"/>
                            <b-form-select v-if="data.parameterstypes[0].format === 'bool'" v-model="data.parameter.val" :options="yesnoCombo" value-field="id" text-field="val"/>
                            <b-form-select v-if="data.parameterstypes[0].format === 'select'" v-model="data.parameter.val" :options="data.parameterstypes[0].optionselect" value-field="id" text-field="val"/>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Precedenti">
                        <b-table :items="data.previous" :fields="fields"/>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" @click="saveData()">Salva</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractparameter",
    data() {
        return {
            show: false,
            data: null,
            yesnoCombo: [{
                id: false,
                val: 'No',
            },{
                id: true,
                val: 'Si',
            }],
            fields: [{
                key: "issuedate",
                label: "Data",
                sortable: true
            },{
                key: "contract",
                label: "Contratto"
            },{
                key: "certificationbody",
                label: "Ente"
            },{
                key: "standards",
                label: "Norme"
            },{
                key: "val",
                label: "Parametro"
            },]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/parameter/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/parameter/save", this.data.parameter).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>