<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Application review - ' + data.review.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <contractsignature ref="signature" @update="loadData(data.review.contracts_id, data.review.id)"/>
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Revisionato da">
                        <b-form-select v-model="data.review.users_id" :options="data.users" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Data">
                        <b-form-input v-model="data.review.date" type="date"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Dati cliente">
                        <b-form-select v-model="data.review.clientdata" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.clientdatanote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Campo di applicazione">
                        <b-form-select v-model="data.review.scopecover" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.scopecovernote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Copertura sito">
                        <b-form-select v-model="data.review.sitecover" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.sitecovernote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Audit team">
                        <b-form-select v-model="data.review.auditteamcover" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.auditteamcovernote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Veto team">
                        <b-form-select v-model="data.review.technicalcover" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.technicalcovernote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3">
                    <b-form-group label="Copertura tempo">
                        <b-form-select v-model="data.review.timecover" :options="yesNoCombo" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="9">
                    <b-form-group label="Note">
                        <b-form-textarea v-model="data.review.timecovernote" rows="2"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Firma per approvazione" v-if="data.review.id > 0" >
                <b-btn :variant="data.review.documentssignatures_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(data.review.documentssignatures_id, data.review.contracts_id)">{{ data.review.documentssignatures_check === true ? 'Firmata' : 'Da firmare' }}</b-btn>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.review.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import contractsignature from './contractsignature.vue';

export default {
    name: "contractreview",
    components: { contractsignature },
    data() {
        return {
            show: false,
            data: null,
            yesNoCombo: [{
                id: true,
                val: "Si"
            },{
                id: false,
                val: "No"
            }]
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/review/", { contracts_id: contractsId, id: id}).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/review/save", this.data.review).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/review/delete", { id: this.data.review.id, contracts_id: this.data.review.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>