<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Mail - ' + data.id" scrollable @hidden="closeForm()" @close="closeForm()" no-close-on-backdrop>
      <b-button class="mb-2" v-if="data.companies_id > 0 || data.orders_id > 0 || data.quotes_id > 0 || data.invoices_id > 0 || data.contracts_id > 0 || data.audits_id > 0" variant="outline-primary">La mail sarà archiviata sotto {{ (data.companies_id > 0 ? ('l\'anagrafica - ID:' + data.companies_id) : (data.orders_id > 0 ? ('la commessa - ID:' + data.orders_id) : (data.quotes_id > 0 ? ('il preventivo - ID:' + data.quotes_id) : (data.invoices_id > 0 ? ('la fattura - ID:' + data.invoices_id) : (data.contracts_id > 0 ? ('il contratto - ID:' + data.contracts_id) : (data.audits_id > 0 ? ('l\'audit - ID:' + data.audits_id) : '')))))) }}</b-button>
      <b-row>
        <b-col lg="5">
          <b-form-group label="Da">
            <b-form-select v-model="data.mailboxes_id" :options="mailboxesOptions" value-field="id" text-field="val" :disabled="lock" @change="saveData('mailboxes_id', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Template">
            <b-form-select v-if="templates.templates.length > 0" v-model="data.templates_id" :options="templates.templates.filter(x => x.mailboxes.includes(data.mailboxes_id))" value-field="id" text-field="val" :disabled="data.mailboxes_id == null || lock" @change="saveData('templates_id', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="1" v-if="data.companies_id > 0 && templates.deadlines.findIndex(x => x.mailstemplates_id === data.templates_id) > -1">
          <b-form-group label="Scadenza">
            <b-btn :variant="data.templates_deadline === true ? 'success' : 'danger'" :disabled="lock" @click="deadlineChange()">{{ data.templates_deadline === true ? "Si" : "No" }}</b-btn>
          </b-form-group>
        </b-col>
        <b-col lg="1">
          <b-form-group label="ULTIMO">
            <b-btn variant="primary" @click="lastTemplate()">Carica ultimo</b-btn>
          </b-form-group>
        </b-col>
      </b-row>
      <div no-body class="text-center" v-if="data.companies_id > 0 && templates.deadlines.findIndex(x => x.mailstemplates_id === data.templates_id) > -1">
        <b-alert v-if="data.templates_deadline === true" variant="success" show>
          <b>Nota:</b> {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).note }}<br>
          <b>Scadenza (per {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).deaduser }} tra {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).deadlinedays }} giorno/i):</b> {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).deadline }}<br>
          <b>Tags aggiunti:</b> {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).tagsadd }}, <b>Tags rimossi:</b> {{ templates.deadlines.find(x => x.mailstemplates_id === data.templates_id).tagsremove }}
        </b-alert>
        <b-alert v-else variant="danger" show>
          Non verranno messi la nota e la scadenza
        </b-alert>
      </div>
      <b-row>
        <b-col lg="6">
          <b-form-group label="A">
            <b-form-input v-model="data.to" class="mr-2" style="float: left" :disabled="lock" @change="saveData('to', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Cc">
            <b-form-input v-model="data.cc" :disabled="lock" @change="saveData('cc', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Ccn">
            <b-form-input v-model="data.bcc" :disabled="lock" @change="saveData('bcc', $event)"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group label="Oggetto">
            <b-form-input v-model="data.subject" :disabled="lock" @change="saveData('subject', $event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <customeditor v-model="data.body" :height="winHeight - 620" :showToolbar="true" :disabled="lock" @input="saveData('body', $event)"/>
        </b-col>
        <b-col lg="12">
          <b-form-group label="Allegati">
            <b-dropdown class="mr-2 mb-2" size="sm" v-for="(item, index) of data.attachments" :key="index" :text="item">
              <b-dropdown-item @click="deleteAttachments(item)">Elimina</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-model="showUpload" title="Carica File" hide-footer>
            <b-form-file @change="fileChange"/>
          </b-modal>
          <b-btn variant="success" class="mr-2" :disabled="lock" @click="sendData()">Invia</b-btn>
          <b-btn variant="primary"  :disabled="lock" @click="showUpload = true">Carica Allegati</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
export default {
  name: "mailnew",
  components: {
    customeditor
  },
  data() {
    return {
      show: false,
      lock: true,
      showUpload: false,
      data: null,
      mailboxesOptions: [],
      templates: {
        templates: [],
        deadlines: [],
      },
      templatesId: null
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadMailboxes(){
      if (this.mailboxesOptions.length == 0){
        this.openHttp("mails/mailnew/mailboxes", {}).then((result) => {
          this.mailboxesOptions = result;
        })
      }
    },
    loadTemplates(){
      if (this.templates.templates.length == 0){
        this.openHttp("mails/mailnew/templates", {}).then((result) => {
          this.templates = result;
        })
      }
    },
    loadData(dataFrom){
      this.lock = true;
      if (this.data === null) {
        this.show = false;
        this.loadMailboxes();
        this.loadTemplates();
      }
      this.openHttp("mails/mailnew/", dataFrom).then((result) => {
        this.data = result;
        this.show = true;
        this.lock = false;
      })
    },
    deadlineChange(){
      this.data.templates_deadline = ! this.data.templates_deadline;
      this.saveData("templates_deadline", this.data.templates_deadline);
    },
    lastTemplate(){
      const template = this.$store.getters.getSavedMail();
      this.saveHttp("mails/mailnew/settemplate", { id: this.data.id, mailboxes_id: template.mailboxes_id, templates_id: template.templates_id, deadlineyesno: template.deadlineyesno }).then((result) => {
        if (result !== null) this.data = result;
      })
    },
    saveData(field, val){
      this.lock = true;
      this.$store.commit("setSavedMail", { mailboxesId: this.data.mailboxes_id, templatesId: this.data.templates_id, templates_deadline: this.data.templates_deadline });
      this.saveHttp("mails/mailnew/save", { id: this.data.id, field: field, val: val }).then((result) => {
        if (result !== null) this.loadData({ id: this.data.id });
      })
    },
    sendData(){
      this.saveHttp("mails/mailnew/send", { id: this.data.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    fileChange(event){
      var reader = new FileReader();
      if (event.target.files != null){
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = ()=> {
          this.saveHttp("mails/mailnew/attachmentssave", { id: this.data.id, filename: event.target.files[0].name, content: reader.result }).then((result) => {
            if (result !== null){
              this.showUpload = false;
              this.loadData({ id: this.data.id });
            }
          })
        }
      } else {
        reader.readAsDataURL(event.dataTransfer.files[0]);
        reader.onload = ()=> {
          this.saveHttp("mails/mailnew/attachmentssave", { id: this.data.id, filename: event.dataTransfer.files[0].name, content: reader.result }).then((result) => {
            if ( result !== null ){
              this.showUpload = false;
              this.loadData({ id: this.data.id });
            }
          })
        }
      }
    },
    deleteAttachments(filename){
      this.deleteHttp("mails/mailnew/attachmentsdelete", { id: this.data.id, filename: filename }).then((result) => {
        if ( result !== null ) this.loadData({ id: this.data.id });
      })
    },
    closeForm(){
      this.templatesId = null;
      this.show = false;
      this.showUpload = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>