<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Automezzo - ' + data.vehicle.id" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="8">
          <b-form-group label="Automezzo">
            <b-form-input v-model="data.vehicle.vehicle"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Stato">
            <b-form-select v-model="data.vehicle.active" :options="activeCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="ID fleetsafe">
            <b-form-input type="number" v-model="data.vehicle.fleetsafe_id"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Numero telaio">
        <b-form-input v-model="data.vehicle.vin"/>
      </b-form-group>
      <b-form-group label="Collaboratori">
        <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.vehicle.users = updateCombo(usersSupport)"/>
      </b-form-group>
      <b-form-group label="Manutenzioni">
        <multiselect v-model="maintenancestypesSupport" :options="data.maintenancestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.vehicle.maintenancestypes = updateCombo(maintenancestypesSupport)"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.vehicle.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "vehicle",
  data() {
    return {
      show: true,
      data: null,
      usersSupport: [],
      maintenancestypesSupport: [],
      activeCombo: [{
        id: true,
        val: "Attivo",
      },{
        id: false,
        val: "Non attivo",
      }],
      yesnoCombo: [{
        id: true,
        val: "Si",
      },{
        id: false,
        val: "No",
      }]
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("vehicles/vehicle/", { id: id }).then((result) => {
        this.usersSupport = this.convertCombo(result.users, result.vehicle.users);
        this.maintenancestypesSupport = this.convertCombo(result.maintenancestypes, result.vehicle.maintenancestypes);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("vehicles/vehicle/save", this.data.vehicle).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("vehicles/vehicle/delete", { id: this.data.vehicle.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>