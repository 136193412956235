<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Audit - ' + data.audit.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <contract ref="contract" @update="loadData(data.audit.id)"/>
      <order ref="order" @update="loadData(data.audit.id)"/>
      <auditstandardphase ref="standardphase" @update="loadData(data.audit.id)"/>
      <auditsite ref="site" @update="loadData(data.audit.id)"/>
      <auditimpartiality ref="impartiality" @update="loadData(data.audit.id)"/>
      <auditspreviousaudit ref="previousaudit" @update="loadData(data.audit.id)"/>
      <auditcalendardate ref="calendardate" @update="loadData(data.audit.id)"/>
      <auditreservation ref="reservation" @update="loadData(data.audit.id)"/>
      <auditmonitoring ref="monitoring" @update="loadMonitorings()"/>
      <auditparameter ref="parameter" @update="loadData(data.audit.id)"/>
      <auditstrength ref="strength" @update="loadData(data.audit.id)"/>
      <auditsopportunitytobeclosedimport ref="opportunitytobeclosedimport" @update="loadData(data.audit.id)"/>
      <auditfinding ref="finding" @update="loadFindings()"/>
      <auditfindingtobeclosed ref="findingtobeclosed" @update="loadData(data.audit.id)"/>
      <auditnonconformity ref="nonconformity" @update="loadData(data.audit.id)"/>
      <auditnonconformitytobeclosed ref="nonconformitytobeclosed" @update="loadData(data.audit.id)"/>
      <auditnonconformitytobeclosedimport ref="nonconformitytobeclosedimport" @update="loadNonconformitiestobeclosed()"/>
      <auditdecision ref="decision" @update="loadDecisions()"/>
      <auditreport ref="report" @update="loadReport()"/>
      <checklist ref="checklist" @update="loadData(data.audit.id)"/>
      <auditdocument ref="document" @update="loadData(data.audit.id)"/>
      <auditsignature ref="signature" @update="loadData(data.audit.id)"/>
      <auditveto ref="veto" @update="loadData(data.audit.id)"/>
      <mail ref="mail" @update="loadData(data.audit.id)"/>
      <mailnew ref="mailnew" @update="loadData(data.audit.id)"/>
      <orderhour ref="hour"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Audit" active>
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label><b-link @click="$refs.contract.loadData(data.audit.contracts_id)">Contratto</b-link></label>
                  <b-form-input :value="data.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.audit.auditsstatus_id" :options="data.auditsstatus" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Data piano">
                  <b-form-input type="date" v-model="data.audit.plandate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Scadenza">
                  <b-form-input type="date" v-model="data.audit.expiracy"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="data.audit.auditsstatus_id != 1">
              <b-col lg="3">
                <b-form-group label="Audit padre">
                  <multiselect v-model="f_audits_idSupport" :options="data.auditsfathers" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.f_audits_id = updateComboSimple(f_audits_idSupport)" @search-change="asyncFathers" :disabled="data.audit.auditsstatus_id >= 3"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" v-if="data.audit.f_audits_id == data.audit.id">
                <b-form-group label="Autorizzati">
                  <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.users = updateCombo(usersSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group>
                  <label><b-link @click="$refs.order.loadData(data.audit.orders_id)">Commessa</b-link></label>
                  <multiselect v-model="orders_idSupport" :options="data.orders" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.orders_id = updateComboSimple(orders_idSupport)" @search-change="asyncOrders"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Lingue">
                  <multiselect v-model="languagesSupport" :options="data.languages" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.audit.languages = updateCombo(languagesSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Referente">
                  <b-form-select v-model="data.audit.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Norme, fasi e punti">
              <b-btn variant="primary" class="mb-2" size="sm" @click="$refs.standardphase.loadData(data.audit.id, 0)">Nuova</b-btn>
              <b-table responsive :items="data.standardsphases" :fields="fields.standardsphases" @row-clicked="$refs.standardphase.loadData(data.audit.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.audit.note" rows="2"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Siti & Personale">
            <b-btn size="sm" variant="primary" @click="$refs.site.loadData( 0, data.audit.id)">Nuovo</b-btn>
            <b-table responsive :fields="fields.sites" :items="data.sites" class="mt-2" :sticky-header="(winHeight - 305) + 'px'" @row-clicked="$refs.site.loadData($event.id, data.audit.id)"/>
          </b-tab>
          <b-tab title="Imparzialità" v-if="data.impartialities.length > 0">
            <b-table responsive :items="data.impartialities" :fields="fields.impartialities" :sticky-header="(winHeight - 305) + 'px'" @row-clicked="$refs.impartiality.loadData($event.id, data.audit.id)"/>
          </b-tab>
          <b-tab title="Parametri" v-if="data.parameters.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData(data.audit.id, $event.id)"/>
          </b-tab>
          <b-tab title="Calendario" v-if="data.audit.auditsstatus_id > 1">
            <b-row>
              <b-col lg="3">
                <b-table responsive :fields="fields.controlteam" :items="data.controlteam">
                  <template v-slot:cell(team)="row">
                    <div v-for="(item, index) in row.value" :key="index">
                      {{ item.date }}: {{ item.team }}
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col lg="3">
                <b-table responsive :fields="fields.controltime" :items="data.controltime"/>
              </b-col>
              <b-col lg="3">
                <b-table responsive :fields="fields.controlnaces" :items="data.controlnaces"/>
              </b-col>
              <b-col lg="3">
                <b-table responsive :fields="fields.ordersdays" :items="data.ordersdays"/>
              </b-col>
            </b-row>
            <b-btn class="mr-2 mb-2" style="float: left" size="sm">{{ calendar.month + '-' + calendar.year}}</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarPreviousMonth">Prima</b-btn>
            <b-btn variant="primary" class="mr-2 mb-2" style="float: left" size="sm" @click="calendarNextMonth">Dopo</b-btn>
            <b-form-input size="sm" v-model="calendar.filterUser" style="width: 300px" v-if="calendar.calendar.length > 0" />
            <b-table-simple hover responsive bordered :sticky-header="(winHeight - 630) + 'px'" v-if="calendar.calendar.length > 0">
              <b-thead head-variant="light">
                <b-th>Persona</b-th>
                <b-th>Qualifiche</b-th>
                <b-th>Idoneo</b-th>
                <b-th v-for="(item, index) in calendar.days" :key="index">{{ item }}</b-th>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in calendar.calendar.filter( x => x.user.toLowerCase().indexOf( calendar.filterUser.toLowerCase() ) > -1 ) " :key="index">
                  <b-td>
                    <font :color="item.active === false ? 'red' : ''">{{ item.user }}</font>
                  </b-td>
                  <b-td>
                    <div v-for="(item2, index2) of item.qualification" :key="index2">
                      {{ item2.standard }} <strong>{{ item2.roles }}</strong> <font color="green">{{ item2.ok }}</font> <font color="red">{{ item2.ko }}</font>
                    </div>
                  </b-td>
                  <b-td>{{ item.completelevel }}%</b-td>
                  <b-td v-for="(item2, index2) in item.dates" :key="index2" :variant="(item2.blocked === true || item2.sameaccred === true) ? 'danger' : ( item2.reserved === true ? 'success' : ( item2.companies !== null ? 'warning' : '' ) )" @click="loadCalendarDate( item.users_id, item2.day )">
                    <strong v-show="item2.blocked">NO</strong>
                    <b-btn class="sm" variant="light" v-if="item2.companies !== null" :id ="'tooltip-target-' + item.users_id + '-' + item2.day">?</b-btn>
                    <b-tooltip v-if="item2.companies !== null" :target="'tooltip-target-' + item.users_id + '-' + item2.day" trigger="hover">{{ item2.companies }}</b-tooltip>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Pianificazione" v-if="data.audit.auditsstatus_id > 1">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th>Da</b-th>
                  <b-th>A</b-th>
                  <b-th>Tipo</b-th>
                  <b-th>Team</b-th>
                  <b-th>Punti</b-th>
                  <b-th>Sito</b-th>
                  <b-th>Remoto</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(item, index) of data.reservations">
                  <b-tr :key="index + '_tab'">
                    <b-th colspan="7">
                      <b-btn class="mr-2" size="sm">{{ item.dateclean }}</b-btn>
                      <b-btn class="mr-2" size="sm" :variant="item.todo === item.done ? 'success' : 'warning'">{{ (item.todo - item.done) !== 0 ? ( item.todo > item.done ? ('Mancano ' + (Math.round((item.todo - item.done) * 10000) / 10000) + 'h' ) : 'Eccesso di ' + (Math.round((item.done - item.todo) * 10000) / 10000) + 'h') : 'OK' }}</b-btn>
                      <b-btn class="mr-2" variant="primary" size="sm" @click="$refs.reservation.loadData(data.audit.id, item.date, 0)">Nuovo</b-btn>
                      <b-btn class="mr-2" variant="info" size="sm" @click="$refs.reservation.halfData(data.audit.id, item.date)">Mezza</b-btn>
                      <b-btn class="mr-2" variant="info" size="sm" @click="$refs.reservation.fullData(data.audit.id, item.date)">Piena</b-btn>
                    </b-th>
                  </b-tr>
                  <b-tr v-for="(item2, index2) of item.reservations" :key="index + '_tab_' + index2" @click="$refs.reservation.loadData(data.audit.id, item.date, item2.id)">
                    <b-td>{{ item2.fromtime }}</b-td>
                    <b-td>{{ item2.totime }}</b-td>
                    <b-td>{{ item2.auditstype }}</b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.usersroles" :key="index3">
                        <nobr><strong>{{ item3.user + ' ' + item3.role }}</strong></nobr> {{ item3.standardsphases }}
                      </div>
                    </b-td>
                    <b-td>
                      <div v-for="(item3, index3) of item2.standardspoints" :key="index3">
                        <nobr><strong>{{ item3.standard }}</strong> {{ item3.points }}</nobr>
                      </div>
                    </b-td>
                    <b-td>{{ item2.site }}</b-td>
                    <b-td>{{ item2.remote }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-tab>
          <b-tab title="Monitorings" v-if="data.audit.auditsstatus_id > 1">
            <b-btn variant="primary" size="sm" @click="$refs.monitoring.loadData(data.audit.id, 0)">Nuovo</b-btn>
            <b-table responsive :items="monitorings" :fields="fields.monitorings" :sticky-header="(winHeight - 420) + 'px'" class="mt-3" @row-clicked="$refs.monitoring.loadData(data.audit.id, $event.id)"/>
          </b-tab>
          <b-tab :title="'Presenze'" v-if="data.audit.auditsstatus_id > 2">
            <b-table responsive :items="data.presences" :fields="fields.presences" :sticky-header="(winHeight - 420) + 'px'">
              <template v-slot:cell(signaturein)="row">
                <b-btn size="sm" :variant="row.item.signaturein_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(row.item.signaturein_id)">Firma</b-btn>
              </template>
              <template v-slot:cell(signatureout)="row">
                <b-btn size="sm" :variant="row.item.signatureout_check === true ? 'success' : 'danger'" @click="$refs.signature.loadData(row.item.signatureout_id)">Firma</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Audit precedenti" v-if="data.audit.auditsstatus_id > 2 && data.ispretransfer">
            <b-btn variant="primary" size="sm" @click="$refs.previousaudit.loadData(data.audit.id, 0)">Nuovo</b-btn>
            <b-table responsive :items="data.previousaudits" :fields="fields.previousaudits" :sticky-header="(winHeight - 420) + 'px'" class="mt-3" @row-clicked="$refs.previousaudit.loadData(data.audit.id, $event.id)">
              <template v-slot:cell(standardspoints)="row">
                <div v-for="(item, index) of row.value" :key="index">{{ item }}</div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Report" v-if="data.audit.auditsstatus_id > 2">
            <b-card no-body>
              <b-tabs card>
                <b-tab :title="'Correzioni ' + vetosevidences.length" active>
                  <b-btn size="sm" variant="warning" class="mb-2" @click="reprepareEvidences()">Reprepare evidences</b-btn>
                  <b-table responsive :fields="fields.vetosevidences" :items="vetosevidences" :sticky-header="(winHeight - 475) + 'px'"/>
                </b-tab>
                <b-tab v-for="(item, index) of evidences" :key="index" :title="item.paragraph + ' ' + Math.round(item.questions.filter(x => x.notapplicable === true || x.subquestions.findIndex(y => y.evidence === null)).length * 100 / item.questions.length) + '% ' +  Math.round(item.questions.filter(x => x.decision === true).length * 100 / item.questions.length) + '%'">
                  <b-table responsive :fields="fields.evidences" :items="item.questions" :sticky-header="(winHeight - 500) + 'px'">
                    <template v-slot:cell(question)="row">
                      <strong v-if="row.item.site !== null">Sede {{ row.item.site }}<br></strong>
                      <strong v-if="row.item.site !== null">IAF / NACE {{ row.item.iafnace }}<br></strong>
                      <strong>{{ row.item.question }}</strong>
                      <b-alert variant="danger" show v-if="row.item.notapplicable === true">
                        Non Applicable
                      </b-alert>
                      <b-row v-else>
                        <b-col v-for="(item2, index2) in row.item.subquestions" :key="index2" :lg="item2.width">
                          <b-form-group :label="item2.question">
                            <div v-for="(item3, index3) in item2.evidence" :key="index3">
                              <b-input-group size="sm" :prepend="item3.lang" v-if="item2.formattypes_id === 1">
                                <b-form-input size="sm" :value="item3.evidence" @change="saveEvidences(row.item.id, item2.id, item3.languages_id, $event)"/>
                              </b-input-group>
                              <b-input-group size="sm" :prepend="item3.lang" v-if="item2.formattypes_id === 2">
                                <b-form-textarea size="sm" :value="item3.evidence" rows="2" max-rows="5" @change="saveEvidences(row.item.id, item2.id, item3.languages_id, $event)"/>
                              </b-input-group>
                              <b-form-input v-if="item2.formattypes_id === 3" size="sm" :value="item3.evidence" type="date" @blur="saveEvidences(row.item.id, item2.id, null, $event.target.value)"/>
                              <b-form-input v-if="item2.formattypes_id === 4" size="sm" :value="item3.evidence" type="number" @change="saveEvidences(row.item.id, item2.id, null, $event)"/>
                              <b-form-select v-if="item2.formattypes_id === 5" size="sm" :value="item3.evidence" :options="item2.possibleanswers" value-field="id" text-field="val" @change="saveEvidences(row.item.id, item2.id, null, $event)" />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-slot:cell(decision)="row">
                      <nobr>
                        <b-btn :variant="row.item.decision === true ? 'success' : ''" size="sm" class="mr-2" @click="saveVetoQuestion(row.item.id, true, null)">Ok</b-btn>
                        <b-btn :variant="row.item.decision === false ? 'danger' : ''" size="sm" class="mr-2" @click="saveVetoQuestion(row.item.id, false, row.item.note)">Ko</b-btn>
                        <b-btn v-show="row.item.decision != null" variant="warning" size="sm" class="mr-2" @click="unlockVetoQuestion(row.item.id)">Sblocca</b-btn>
                      </nobr>
                      <b-form-group label="Note" v-show="row.item.decision === false">
                        <b-form-textarea size="sm" :value="row.item.note" rows="2" max-rows="5" @change="saveVetoQuestion(row.item.id, false, $event)"/>
                      </b-form-group>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Punti di forza ' + strengths.length">
                  <b-btn size="sm" variant="primary" @click="$refs.strength.loadData(data.audit.id, 0)">Nuovo</b-btn>
                  <b-table responsive :fields="fields.strengths" :items="strengths" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.strength.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(strength)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.strength }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Rilievi ' + (findings.length > 0 ? (' ' + findings.length + ' ' + Math.round(findings.filter(x => x.decision === true).length * 100 / findings.length) + '%') : '')">
                  <b-btn size="sm" variant="primary" @click="$refs.finding.loadData(data.audit.id, 0)">Nuovo</b-btn>
                  <b-table responsive :fields="fields.findings" :items="findings" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" :tbody-tr-class="rowClassVeto"  @row-clicked="$refs.finding.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(finding)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.finding }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                    <template v-slot:cell(decision)="row">
                      <nobr>
                        <b-btn :variant="row.item.decision === true ? 'success' : ''" size="sm" class="mr-2" @click="$refs.finding.saveVeto(data.audit.id, row.item.id, true, null)">Ok</b-btn>
                        <b-btn :variant="row.item.decision === false ? 'danger' : ''" size="sm" class="mr-2" @click="$refs.finding.saveVeto(data.audit.id, row.item.id, false, row.item.note)">Ko</b-btn>
                        <b-btn v-show="row.item.decision != null" variant="warning" size="sm" class="mr-2" @click="$refs.finding.unlockVeto(data.audit.id, row.item.id)">Sblocca</b-btn>
                      </nobr>
                      <b-form-group label="Note" v-show="row.item.decision === false">
                        <b-form-textarea size="sm" :value="row.item.note" rows="2" max-rows="5" @change="$refs.finding.saveVeto(data.audit.id, row.item.id, false, $event)"/>
                      </b-form-group>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Rilievi da verificare' + (findingstobeclosed.length > 0 ? (' ' + findingstobeclosed.length + ' ' + Math.round(findingstobeclosed.filter(x => x.decision === true).length * 100 / findingstobeclosed.length) + '%') : '')">
                  <b-btn size="sm" variant="primary" class="mr-2" @click="$refs.findingtobeclosed.loadData(data.audit.id, 0)">Nuovo</b-btn>
                  <b-btn size="sm" variant="info" @click="$refs.opportunitytobeclosedimport.loadData(data.audit.id)">Importa</b-btn>
                  <b-table responsive :fields="fields.findingstobeclosed" :items="findingstobeclosed" :tbody-tr-class="rowClassVeto" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.findingtobeclosed.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(finding)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.finding }}
                      </div>
                    </template>
                    <template v-slot:cell(closure)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.closure }}
                      </div>
                    </template>                        
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                    <template v-slot:cell(decision)="row">
                      <nobr>
                        <b-btn :variant="row.item.decision === true ? 'success' : ''" size="sm" class="mr-2" @click="$refs.findingtobeclosed.saveVeto(data.audit.id, row.item.id, true, null)">Ok</b-btn>
                        <b-btn :variant="row.item.decision === false ? 'danger' : ''" size="sm" class="mr-2" @click="$refs.findingtobeclosed.saveVeto(data.audit.id, row.item.id, false, row.item.note)">Ko</b-btn>
                        <b-btn v-show="row.item.decision != null" variant="warning" size="sm" class="mr-2" @click="$refs.findingtobeclosed.unlockVeto(data.audit.id, row.item.id)">Sblocca</b-btn>
                      </nobr>
                      <b-form-group label="Note" v-show="row.item.decision === false">
                        <b-form-textarea size="sm" :value="row.item.note" rows="2" max-rows="5" @change="$refs.findingtobeclosed.saveVeto(data.audit.id, row.item.id, false, $event)"/>
                      </b-form-group>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Non conformità ' + (nonconformities.length > 0 ? (' ' + nonconformities.length + ' ' + Math.round(nonconformities.filter(x => x.decision === true).length * 100 / nonconformities.length) + '%') : '')">
                  <b-btn size="sm" class="mb-2" variant="primary" @click="$refs.nonconformity.loadData(data.audit.id, 0)">Nuovo</b-btn>
                  <b-table responsive :fields="fields.nonconformities" :items="nonconformities" :tbody-tr-class="rowClassVeto" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.nonconformity.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(nonconformity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.nonconformity }}
                      </div>
                    </template>
                    <template v-slot:cell(acceptnote)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.acceptnote }}
                      </div>
                    </template>
                    <template v-slot:cell(cause)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.cause }}
                      </div>
                    </template>
                    <template v-slot:cell(treatment)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.treatment }}
                      </div>
                    </template>
                    <template v-slot:cell(correctiveaction)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.correctiveaction }}
                      </div>
                    </template>
                    <template v-slot:cell(effectivenessnote)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.effectivenessnote }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                    <template v-slot:cell(decision)="row">
                      <nobr>
                        <b-btn :variant="row.item.decision === true ? 'success' : ''" size="sm" class="mr-2" @click="$refs.nonconformity.saveVeto(data.audit.id, row.item.id, true, null)">Ok</b-btn>
                        <b-btn :variant="row.item.decision === false ? 'danger' : ''" size="sm" class="mr-2" @click="$refs.nonconformity.saveVeto(data.audit.id, row.item.id, false, row.item.note)">Ko</b-btn>
                        <b-btn v-show="row.item.decision != null" variant="warning" size="sm" class="mr-2" @click="$refs.nonconformity.unlockVeto(data.audit.id, row.item.id)">Sblocca</b-btn>
                      </nobr>
                      <b-form-group label="Note" v-show="row.item.decision === false">
                        <b-form-textarea size="sm" :value="row.item.note" rows="2" max-rows="5" @change="$refs.nonconformity.saveVeto(data.audit.id, row.item.id, false, $event)"/>
                      </b-form-group>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab :title="'Non conformità da chiudere' + (nonconformitiestobeclosed.length > 0 ? (' ' + nonconformitiestobeclosed.length + ' ' + Math.round(nonconformitiestobeclosed.filter(x => x.decision === true).length * 100 / nonconformitiestobeclosed.length) + '%') : '')">
                  <b-btn size="sm" variant="primary" class="mr-2" @click="$refs.nonconformitytobeclosed.loadData(data.audit.id, 0)">Nuovo</b-btn>
                  <b-btn size="sm" variant="info" @click="$refs.nonconformitytobeclosedimport.loadData(data.audit.id)">Importa</b-btn>
                  <b-table responsive :fields="fields.nonconformitiestobeclosed" :items="nonconformitiestobeclosed" :tbody-tr-class="rowClassVeto" :sticky-header="(winHeight - 475) + 'px'" class="mt-2" @row-clicked="$refs.nonconformitytobeclosed.loadData(data.audit.id, $event.id)">
                    <template v-slot:cell(nonconformity)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.nonconformity }}
                      </div>
                    </template>
                    <template v-slot:cell(cause)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.cause }}
                      </div>
                    </template>
                    <template v-slot:cell(treatment)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.treatment }}
                      </div>
                    </template>
                    <template v-slot:cell(correctiveaction)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.correctiveaction }}
                      </div>
                    </template>
                    <template v-slot:cell(effectivenessnote)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.lang }}</strong> {{ item.effectivenessnote }}
                      </div>
                    </template>
                    <template v-slot:cell(points)="row">
                      <div v-for="(item, index) in row.value" :key="index">
                        <strong>{{ item.standard }}</strong> {{ item.points }}
                      </div>
                    </template>
                    <template v-slot:cell(decision)="row">
                      <nobr>
                        <b-btn :variant="row.item.decision === true ? 'success' : ''" size="sm" class="mr-2" @click="$refs.nonconformitytobeclosed.saveVeto(data.audit.id, row.item.id, true, null)">Ok</b-btn>
                        <b-btn :variant="row.item.decision === false ? 'danger' : ''" size="sm" class="mr-2" @click="$refs.nonconformitytobeclosed.saveVeto(data.audit.id, row.item.id, false, row.item.note)">Ko</b-btn>
                        <b-btn v-show="row.item.decision != null" variant="warning" size="sm" class="mr-2" @click="$refs.nonconformitytobeclosed.unlockVeto(data.audit.id, row.item.id)">Sblocca</b-btn>
                      </nobr>
                      <b-form-group label="Note" v-show="row.item.decision === false">
                        <b-form-textarea size="sm" :value="row.item.note" rows="2" max-rows="5" @change="$refs.nonconformitytobeclosed.saveVeto(data.audit.id, row.item.id, false, $event)"/>
                      </b-form-group>
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="Report">
                  <b-btn size="sm" class="mb-2" variant="primary" @click="generateReport()">Genera report</b-btn>
                  <b-table responsive :fields="fields.reports" :items="report" :sticky-header="(winHeight - 475) + 'px'">
                    <template v-slot:cell(languages)="row">
                      <b-row>
                        <b-col v-for="(item, index) of row.value" :key="index">
                          <strong>{{ item.lang }}: </strong>
                          <span v-html="item.report" @click="$refs.report.loadData(data.audit.id, row.item.id, item.id)"></span>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab title="Veto" v-if="data.audit.auditsstatus_id !== 1">
            <b-form-group label="Veto team">
              <b-btn variant="primary" size="sm" @click="$refs.veto.loadData(data.audit.id, 0)">Nuovo</b-btn>
              <b-table responsive :items="vetos" :fields="fields.vetoauditors" class="mt-2" @row-clicked="$refs.veto.loadData(data.audit.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Decisioni">
              <b-table responsive :items="decisions" :fields="fields.decisions" @row-clicked="$refs.decision.loadData(data.audit.id, $event.id)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Email" v-if="data.audit.auditsstatus_id !== 1">
            <b-row class="mb-2" align-h="end">
              <b-col lg="3">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
          <b-tab :title="'Documenti ' + data.documents.length">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.audit.id)">Genera</b-btn>
            <b-btn variant="primary" size="sm" @click="$refs.document.downloadDataAll(data.audit.id)">Scarica tutto</b-btn>
            <b-table class="mt-3" :sticky-header="(winHeight - 405) + 'px'" :tbody-tr-class="rowClassDocuments" responsive :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.audit.id, $event.id)">
              <template v-slot:cell(jsonkey)="row">{{ row.value }}</template>
              <template v-slot:cell(id)="row">
                <b-btn variant="warning" class="mr-2" v-show="row.item.datetime !== null && row.item.locked === false" size="sm" @click="$refs.document.lockData(row.item.id, true)">Blocca</b-btn>
                <b-btn variant="primary" v-show="row.item.datetime !== null" size="sm"  @click="$refs.document.downloadData(row.value)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Errori - ' + errors.length" v-if="errors.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 405)+ 'px'" :items="errors" :fields="fields.errors"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-if="showMail" v-model="showMail" title="Manda" size="lg">
            <b-table :items="data.contacts" :fields="fields.contacts" sortable="true">
              <template v-slot:cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"/>
              </template>
            </b-table>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" class="mr-2" @click="prepareMail">Prepara Mail</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn variant="primary" class="mr-2" @click="loadData(data.audit.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.audit.id > 0" @click="deleteData()">Elimina</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.audit.id > 0" @click="loadOrderHour()" :disabled="data.audit.orders_id === null">Segna ore</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.audit.id > 0" @click="showMail = true">Prepara Mail</b-btn>
          <b-btn variant="primary" class="mr-2" v-show="data.audit.id > 0" @click="$refs.checklist.loadData(data.audit.f_audits_id)">Checklist</b-btn>
          <b-btn variant="info" :href="'#/externalaudit/' + data.token" target="_blank">Cliente</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import auditstandardphase from './auditscomponents/auditstandardphase.vue';
import auditsite from './auditscomponents/auditsite.vue';
import auditimpartiality from './auditscomponents/auditimpartiality.vue';
import auditcalendardate from './auditscomponents/auditcalendardate.vue';
import auditreservation from './auditscomponents/auditreservation.vue';
import auditveto from './auditscomponents/auditveto.vue';
import auditdecision from './auditscomponents/auditdecision.vue';
import auditmonitoring from './auditscomponents/auditmonitoring.vue';
import auditspreviousaudit from './auditscomponents/auditspreviousaudit.vue';
import auditparameter from './auditscomponents/auditparameter.vue';
import auditstrength from "./auditscomponents/auditstrength.vue";
import auditfinding from "./auditscomponents/auditfinding.vue";
import auditfindingtobeclosed from "./auditscomponents/auditfindingtobeclosed.vue";
import auditsopportunitytobeclosedimport from './auditscomponents/auditsopportunitytobeclosedimport.vue';
import auditnonconformity from './auditscomponents/auditnonconformity.vue';
import auditnonconformitytobeclosed from './auditscomponents/auditnonconformitytobeclosed.vue';
import auditnonconformitytobeclosedimport from './auditscomponents/auditnonconformitytobeclosedimport.vue';
import auditsignature from './auditscomponents/auditsignature.vue';
import auditreport from './auditscomponents/auditreport.vue';
import auditdocument from './auditscomponents/auditdocument.vue';

export default {
  name: "audit",
  components: { auditstandardphase, auditsite, auditimpartiality, auditcalendardate, auditreservation, auditdecision, auditveto, auditmonitoring, auditspreviousaudit, auditparameter, auditstrength, auditsopportunitytobeclosedimport, auditfinding, auditfindingtobeclosed, auditnonconformity, auditnonconformitytobeclosed, auditnonconformitytobeclosedimport, auditsignature, auditreport, auditdocument },
  data() {
    return {
      show: true,
      data: null,
      decisions: [],
      monitorings: [],
      vetosevidences: [],
      evidences: [],
      strengths: [],
      findings: [],
      findingstobeclosed: [],
      nonconformities: [],
      nonconformitiestobeclosed: [],
      report: [],
      vetos: [],
      errors: [],
      showMail: false,
      f_audits_idSupport: [],
      languagesSupport: [],
      ordersSupport: [],
      usersSupport: [],
      calendar: {
        filterUser: "",
        month: null,
        year: null,
        days: [],
        calendar: [],
        dates: [],
        fieldsControl: [{
          key: "standard",
          label: "Norma",
        },{
          key: "phase",
          label: "Fase"
        },{
          key: "mandaytodo",
          label: "GG/uomo da fare",
        },{
          key: "mandaydone",
          label: "GG/uomo fatti",
        },{
          key: "missing",
          label: "Manca",
          formatter: (value, key, item) => {
            return item.mandaytodo - item.mandaydone;
          },
        },{
          key: "team",
          label: "Team",
        }]
      },
      fields: {
        sites: [{
          key: 'type',
          label: "Tipologia",
          formatter: (value, key, item) => {
            let arr = [];
            if (item.ismain === true) arr.push("Principale");
            if (item.isregistered === true) arr.push("Legale");
            if (item.isyard === true) arr.push("Cantiere");
            return (item.active === false ? "NON Attiva - " : "") + arr.join(";");
          }
        },{
          key: 'site',
          label: "Sito",
        },{
          key: "sampled",
          label: "Campionato",
          formatter: value => {
            return value === true ? "Si" : "No";
          }
        },{
          key: 'shifts',
          label: "Turni",
        },{
          key: 'employeesnumber',
          label: "Personale totale",
        },{
          key: 'employeeseffectivenumber',
          label: "Personale effettivo",
        },{
          key: 'employeesexternal',
          label: "Personale esterno",
        },{
          key: 'employeesrepetitive',
          label: "Personale con mansioni ripetitive",
        },{
          key: 'naces',
          label: "NACE",
        },{
          key: 'locked',
          label: "Confermato",
          formatter: value => {
            return value === true ? "Si" : "No";
          }
        }],
        standardsphases: [{
          key: 'standard',
          label: "Norma",
        },{
          key: 'phase',
          label: 'Fase',
        },{
          key: 'standardspoints',
          label: 'Punti',
        }],
        impartialities: [{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'date',
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'signed',
          label: 'Firmato',
          formatter: value => {
            return value === null ? "Non necessaria" : (value === true ? "Si" : "No");
          }
        },{
          key: 'decision',
          label: 'Decisione',
          formatter: value => {
            return value === true ? "Si" : (value === false ? "No" : 'Da decidere');
          }
        },{
          key: 'decision_date',
          label: 'Decisione data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'decision_fullname',
          label: 'Decisione da',
        }],
        previousaudits: [{
          key: 'phase',
          label: 'Fase',
        },{
          key: 'plandate',
          label: 'Data Pianificazione',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'standardspoints',
          label: 'Punti',
        },{
          key: "reviewuser",
          label: "Revisore",
        },{
          key: "reviewdate",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "reviewsuccess",
          label: "Revisione",
          formatter: value => {
            return value === null ? "Da valutare" : ( value === true ? "Approvato" : "Rifiutato" );
          }
        }],
        controlteam: [{
          key: "phase",
          label: "Fase"
        },{
          key: "standard",
          label: "Norma",
        },{
          key: "team",
          label: "Team",
        }],
        controltime: [{
          key: "phase",
          label: "Fase"
        },{
          key: "standard",
          label: "Norma",
        },{
          key: "todo",
          label: "gg da fare",
        },{
          key: "done",
          label: "gg fatti",
        }],
        controlnaces: [{
          key: "phase",
          label: "Fase"
        },{
          key: "standard",
          label: "Norma",
        },{
          key: "nacesok",
          label: "Ok",
        },{
          key: "nacesko",
          label: "KO",
        }],
        ordersdays: [{
          key: "fromdate",
          label: "Da",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "todate",
          label: "A",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "ordersdaystype",
          label: "Tipo",
        },{
          key: "fullname",
          label: "Nome",
        },{
          key: "note",
          label: "Note",
        }],
        reservations: [{
          key: "fromtime",
          label: "Da",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "totime",
          label: "A",
          formatter: value => {
            let d = value.split(":");
            return [ d[0], d[1] ].join(':');
          },
        },{
          key: "auditstype",
          label: "Tipologia"
        },{
          key: "usersroles",
          label: "Team",
        },{
          key: "standardspoints",
          label: "Punti",
        },{
          key: "site",
          label: "Sede",
        }],
        vetoauditors: [{
          key: "user",
          label: "Veto"
        },{
          key: "role",
          label: "Role",
        },{
          key: "standards",
          label: "Standards",
        }],
        decisions: [{
          key: "phaseen",
          label: "Fase"
        },{
          key: "user1",
          label: "Pianificatore",
        },{
          key: "date1",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "decision1",
          label: "Decisione",
          formatter: value => {
            return value === null ? "Da valutare" : value;
          }
        },{
          key: "user2",
          label: "Lead Auditor",
        },{
          key: "date2",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "decision2",
          label: "Decisione",
          formatter: value => {
            return value === null ? "Da valutare" : value;
          }
        },{
          key: "user3",
          label: "Veto Auditor",
        },{
          key: "date3",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "decision3",
          label: "Decisione",
          formatter: value => {
            return value === null ? "Da valutare" : value;
          }
        }],
        parameters: [{
          key: 'type',
          label: 'Tipo',
        },{
          key: 'val',
          label: 'Valore',
        }],
        monitorings: [{
          key: "monitored",
          label: "Monitorato"
        },{
          key: "monitoring",
          label: "Monitorante",
        },{
          key: "standards",
          label: "Norma",
        },{
          key: "role",
          label: "Ruolo",
        }],
        presences: [{
          key: "phase",
          label: "Fase"
        },{
          key: "participant",
          label: "Partecipante"
        },{
          key: "roles",
          label: "Ruoli"
        },{
          key: "signaturein",
          label: "Apertura"
        },{
          key: "signatureout",
          label: "Chiusura"
        }],
        evidences: [{
          key: "question",
          label: "Domanda",
          thStyle: { width: "80%" }
        },{
          key: "decision",
          label: "Veto",
          thStyle: { width: "20%" }
        }],
        vetohistory: [{
          key: "question",
          label: "Domanda",
          thStyle: { width: "20%" }
        },{
          key: "vetohistory",
          label: "Veto",
          thStyle: { width: "40%" }
        },{
          key: "evidences",
          label: "Evidenze",
          thStyle: { width: "40%" }
        }],
        report: [{
          key: "id",
          label: "Domanda",
        }],
        vetosevidences:[{
          key: "date",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "paragraph",
          label: "Paragrafo",
        },{
          key: "question",
          label: "Domanda",
        },{
          key: "userveto",
          label: "Veto",
        },{
          key: "corrected",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Corretto": "Non corretto";
          }
        },{
          key: "note",
          label: "Note",
        }],
        strengths: [{
          key: "strength",
          label: "Punto di forza",
        },{
          key: "points",
          label: "Punti",
        }],
        findings: [{
          key: "finding",
          label: "Osservazione",
          thStyle: { width: "60%" }
        },{
          key: "points",
          label: "Punti",
          thStyle: { width: "20%" }
        },{
          key: "decision",
          label: "Veto",
          thStyle: { width: "20%" }
        }],
        findingstobeclosed: [{
          key: "finding",
          label: "Osservazione",
          thStyle: { width: "30%" }
        },{
          key: "closure",
          label: "Chiusura",
          thStyle: { width: "30%" }
        },{
          key: "points",
          label: "Punti",
          thStyle: { width: "20%" }
        },{
          key: "decision",
          label: "Veto",
          thStyle: { width: "20%" }
        }],
        nonconformities: [{
          key: "nonconformity",
          label: "Non conformità",
        },{
          key: "nctype",
          label: "Tipologia",
        },{
          key: "acceptdecision",
          label: "Decisione cliente",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Accettata" : "Non accettata";
          },
        },{
          key: "acceptnote",
          label: "Decisione note",
        },{
          key: "cause",
          label: "Causa",
        },{
          key: "treatment",
          label: "Trattamento",
        },{
          key: "correctiveaction",
          label: "Azione correttiva",
        },{
          key: "effectivenessdecision",
          label: "Decisione efficacia",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Verificata" : "Non verificata";
          },
        },{
          key: "effectivenessnote",
          label: "Decisione note",
        },{
          key: "points",
          label: "Punti",
        },{
          key: "decision",
          label: "Veto",
        }],
        nonconformitiestobeclosed: [{
          key: "nonconformity",
          label: "Non conformità",
        },{
          key: "nctype",
          label: "Tipologia",
        },{
          key: "acceptdecision",
          label: "Decisione cliente",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Accettata" : "Non accettata";
          },
        },{
          key: "acceptnote",
          label: "Decisione note",
        },{
          key: "cause",
          label: "Causa",
        },{
          key: "treatment",
          label: "Trattamento",
        },{
          key: "correctiveaction",
          label: "Azione correttiva",
        },{
          key: "effectivenessdecision",
          label: "Decisione efficacia",
          formatter: value => {
            if (value == null) return null;
            return value === true ? "Verificata" : "Non verificata";
          },
        },{
          key: "effectivenessnote",
          label: "Decisione note",
        },{
          key: "points",
          label: "Punti",
        },{
          key: "decision",
          label: "Veto",
          thStyle: { width: "20%" }
        }],
        workerssignatures: [{
          key: "fullname",
          label: "Nome completo"
        },{
          key: "roles",
          label: "Ruoli"
        },{
          key: "details",
          label: "Dettagli",
          formatter: (value, key, item) => {
            return item.phase + (item.standard !== null ? " " + item.standard : "") + (item.site !== null ? " " + item.site : "") + (item.inout !== null ? (item.inout === false ? ' Ingresso' : ' Uscita') : "");
          }
        }],
        auditorssignatures: [{
          key: "fullname",
          label: "Nome completo"
        },{
          key: "details",
          label: "Dettagli",
          formatter: (value, key, item) => {
            return item.phase + (item.standard !== null ? " " + item.standard : "") + (item.site !== null ? " " + item.site : "") + (item.inout !== null ? (item.inout === false ? ' Ingresso' : ' Uscita') : "");
          }
        }],
        reports: [{
          key: "point",
          label: "Punto",
        },{
          key: "languages",
          label: "Report",
        }],
        mailsCriteria: null,
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true
        },{
          key: 'from',
          label: 'Da',
        },{
          key: 'to',
          label: 'A',
        },{
          key: 'subject',
          label: "Oggetto",
        },{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            if (value == null) return null;
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Si" : "No";
          },
        },{
          key: "id",
          label: "",
        }],
        contacts: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "contactname",
          label: "Nome",
          sortable: true
        },{
          key: "contact",
          label: "Mail",
          sortable: true
        },{
          key: "active",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "job",
          label: "Mansione",
          sortable: true
        },{
          key: "note",
          label: "Note",
          sortable: true
        },{
          key: "areas",
          label: "Aree",
          sortable: true
        },{
          key: "selected",
          label: "",
          sortable: true
        }],
        errors: [{
          key: "errorname",
          label: "Errore"
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "note",
          label: "Note"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassSitesemployees ( item ){
      return 'table-' + (item.locked === true ? 'success' : 'warning');
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassChecked ( item ){
      return 'table-' + (item.usercheck === true ? 'success' : 'warning');
    },
    rowClassVeto(item){
      return 'table-' + (item.decision === true ? "success" : (item.decision === false ? "danger" : "warning"));
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    cleanDate(date){
      if (date == null) return null;
      var d = new Date(date);
      return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map( n => n < 10 ? `0${n}` : `${n}` ).join('-');
    },
    loadData(id){
      if (this.data === null) this.calendar.filterUser = "";
      this.fields.mailsCriteria = null;
      let month = null;
      let year = null;
      if ( this.data === null ){
        this.show = false;
      } else {
        month = this.data.calendarmonth;
        year = this.data.calendaryear;
      }
      if (this.data === null) this.show = false;
      this.openHttp("audits/audit/", { id: id, month: month, year: year }).then((result) => {
        this.f_audits_idSupport = this.convertComboSimple(result.auditsfathers, result.audit.f_audits_id);
        this.orders_idSupport = this.convertComboSimple(result.orders, result.audit.orders_id);
        this.languagesSupport = this.convertCombo(result.languages, result.audit.languages);
        this.usersSupport = this.convertCombo(result.users, result.audit.users);
        this.data = result;
        this.show = true;
        this.loadCalendar();
        this.loadDecisions();
        this.loadMonitorings();
        this.loadEvidencesVeto();
        this.loadEvidences();
        this.loadStrengths();
        this.loadFindings();
        this.loadFindingstobeclosed();
        this.loadNonconformitiestobeclosed();
        this.loadNonconformities();
        this.loadReport();
        this.loadVetos();
        this.loadErrors();
      })
    },
    asyncOrders(query) {
      if (query.length > 2){
        this.openHttp("audits/audit/orders", { search: query }).then((result) => {
          for ( let item of result ) if (this.data.orders.findIndex(x => x.id === item.id) === -1) this.data.orders.push(item);
          this.data.orders = this.data.orders.sort((a,b) => ( a.val > b.val ) ? 1 : 0 );
          this.orders_idSupport = this.convertComboSimple(this.data.orders, this.data.audit.orders_id);
        })
      }
    },
    asyncFathers(query) {
      if (query.length > 2){
        this.openHttp("audits/audit/fathers", { search: query }).then((result) => {
          for (let item of result) {
            if (this.data.auditsfathers.findIndex(x => x.id === item.id) === -1) this.data.auditsfathers.push(item);
          }
          this.data.auditsfathers = this.data.auditsfathers.sort((a,b) => ( a.val > b.val ) ? 1 : 0 );
          this.f_audits_idSupport = this.convertComboSimple(this.data.auditsfathers, this.data.audit.f_audits_id);
        })
      }
    },
    calendarPreviousMonth(){
      let date = new Date(this.calendar.year, this.calendar.month - 1, 1);
      this.calendar.year = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      this.calendar.month = date.getMonth() === 0 ? 12 : date.getMonth();
      this.loadCalendar();
    },
    calendarNextMonth(){
      let date = new Date(this.calendar.year, this.calendar.month, 1);
      this.calendar.year = date.getFullYear();
      this.calendar.month = date.getMonth() + 1;
      this.loadCalendar();
    },
    loadCalendar(){
      this.openHttp("audits/audit/calendar", { audits_id: this.data.audit.id, month: this.calendar.month !== null ? this.calendar.month : null, year: this.calendar.year !== null ? this.calendar.year : null }).then((result) => {
        this.calendar.month = result.month;
        this.calendar.year = result.year;
        this.calendar.days = result.days;
        this.calendar.calendar = result.calendar;
      })
    },
    loadCalendarDate(usersId, date){
      this.$refs.calendardate.loadData(this.data.audit.id, usersId, date);
    },
    loadDecisions(){
      this.openHttp("audits/audit/decisions", { audits_id: this.data.audit.id }).then((result) => {
        this.decisions = result;
      })
    },
    loadMonitorings(){
      this.openHttp("audits/audit/monitorings", { audits_id: this.data.audit.id }).then((result) => {
        this.monitorings = result;
      })
    },
    loadEvidencesVeto(){
      this.openHttp("audits/evidences/vetos", { audits_id: this.data.audit.id }).then((result) => {
        this.vetosevidences = result;
      })
    },
    loadEvidences(evidence = false){
      this.openHttp("audits/evidences/", { audits_id: this.data.audit.id }).then((result) => {
        this.evidences = result;
        if (evidence === true) this.loadEvidencesVeto();
      })
    },
    reprepareEvidences(){
      this.saveHttp("audits/evidences/reprepare", { audits_id: this.data.audit.f_audits_id }).then(() => {
        this.loadEvidences();
      })
    },
    saveEvidences(evidencesId, subquestionsId, languagesId, evidence){
      this.saveHttp("audits/evidences/evidence/save", { audits_id: this.data.audit.id, auditsevidences_id: evidencesId, auditssubquestions_id: subquestionsId, languages_id: languagesId, evidence: evidence }).then(() => {
        this.loadEvidences();
      })
    },
    saveVetoQuestion(evidencesId, decision, note){
      this.saveHttp("audits/evidences/vetosave", { audits_id: this.data.audit.id, auditsevidences_id: evidencesId, decision: decision, note: note}).then(() => {
        this.loadEvidences(true);
      })
    },
    unlockVetoQuestion(evidencesId, decision, note){
      this.saveHttp("audits/evidences/vetounlock", { audits_id: this.data.audit.id, auditsevidences_id: evidencesId, decision: decision, note: note}).then(() => {
        this.loadEvidences(true);
      })
    },
    loadStrengths(){
      this.openHttp("audits/audit/strengths", { audits_id: this.data.audit.id }).then((result) => {
        this.strengths = result;
      })
    },
    loadFindings(evidence = false){
      this.openHttp("audits/audit/findings", { audits_id: this.data.audit.id }).then((result) => {
        this.findings = result;
        if (evidence === true) this.loadEvidencesVeto();
      })
    },
    loadFindingstobeclosed(evidence = false){
      this.openHttp("audits/audit/findingstobeclosed", { audits_id: this.data.audit.id }).then((result) => {
        this.findingstobeclosed = result;
        if (evidence === true) this.loadEvidencesVeto();
      })
    },
    loadNonconformitiestobeclosed(evidence = false){
      this.openHttp("audits/audit/nonconformitiestobeclosed", { audits_id: this.data.audit.id }).then((result) => {
        this.nonconformitiestobeclosed = result;
        if (evidence === true) this.loadEvidencesVeto();
      })
    },
    loadNonconformities(evidence = false){
      this.openHttp("audits/audit/nonconformities", { audits_id: this.data.audit.id }).then((result) => {
        this.nonconformities = result;
        if (evidence === true) this.loadEvidencesVeto();
      })
    },
    loadReport(){
      this.openHttp("audits/report/", { audits_id: this.data.audit.id }).then((result) => {
        this.report = result;
      })
    },
    generateReport(){
      this.saveHttp("audits/report/report/generate", { audits_id: this.data.audit.id }).then(() => {
        this.loadReport(this.data.audit.id);
      })
    },
    loadVetos(){
      this.openHttp("audits/audit/vetos", { audits_id: this.data.audit.id }).then((result) => {
        this.vetos = result;
      })
    },
    loadOrderHour(){
      this.$refs.hour.loadData([this.data.audit.orders_id], 0);
    },
    loadErrors(){
      this.openHttp("audits/audit/errors", { audits_id: this.data.audit.id }).then((result) => {
        this.errors = result;
      })
    },
    prepareMail(){
      let contactsTos = [];
      let contactsNames = [];
      for (let item of this.data.contacts) {
        if ( item.selected === true ){
          contactsTos.push(item.contact);
          if ( contactsNames.includes(item.contactname) === false ) contactsNames.push(item.contactname);
        }
      }
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contactsTos.join(","),
        cc: "",
        bcc: "",
        subject: "Audit documenti",
        body: "",
        audits_id: this.data.audit.id,
        templates_id: null,
        attachments: this.data.documents.map(x => { return { filename: x.filename, id: x.id } }),
        params: {
          fullname: contactsNames.join(", ")
        }
      });
    },
    saveData(){
      this.saveHttp("audits/audit/save", this.data.audit).then((result) => {
        if (result !== null) this.loadData(this.data.audit.id);
      })
    },
    deleteData(){
      this.deleteHttp("audits/audit/delete", { id: this.data.audit.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.calendar.month = null;
      this.calendar.year = null;
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>
