<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Manutenzione - ' + data.maintenance.id" size="lg" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="6">
          <b-form-group label="Veicolo">
            <b-form-select v-model="data.maintenance.vehicles_id" :options="data.vehicles" value-field="id" text-field="val" @change="updateMaintenancesTypes($event)"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Data">
            <b-form-input type="date" v-model="data.maintenance.date"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Kms">
            <b-form-input type="number" v-model="data.maintenance.kms"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Interventi">
        <multiselect v-model="maintenancestypesSupport" :options="data.maintenancestypes.filter(x => x.vehicles.includes(data.maintenance.vehicles_id))" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.maintenance.maintenances = updateCombo(maintenancestypesSupport)"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.maintenance.note" rows="2"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.maintenance.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "vehiclemaintenance",
  data() {
    return {
      show: true,
      data: null,
      maintenancestypesSupport: [],
      fields: [{
        key: 'maintenance',
        label: 'Controllo'
      },{
        key: 'note',
        label: 'Note',
      }]
    }
  },
  methods: {
    loadData( id ){
      this.openHttp("vehicles/maintenance/", { id: id }).then((result) => {
        this.maintenancestypesSupport = this.convertCombo(result.maintenancestypes, result.maintenance.maintenancestypes);
        this.data = result;
        this.show = true;
      })
    },
    updateMaintenancesTypes(vehicleId){
      this.data.maintenance.maintenancestypes = [];
      this.maintenancestypesSupport = [];
      if (this.data.maintenance.id === 0) this.data.maintenance.kms = this.data.vehicles.find(x => x.id === vehicleId).kms;
    },
    saveData(){
      this.saveHttp("vehicles/maintenance/save", this.data.maintenance).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("vehicles/maintenance/delete", { id: this.data.maintenance.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>