<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Servizio - ' + data.service.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Servizio" active>
                        <b-form-group label="Servizio">
                            <b-form-input v-model="data.service.servicetitle"/>
                        </b-form-group>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Stato">
                                    <b-form-select v-model="data.service.active" :options="comboActive" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="5">
                                <b-form-group label="Norma">
                                    <b-form-select v-model="data.service.standards_id" :options="addNullCombo(data.standards, 'Nessuna')" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="5">
                                <b-form-group label="Step">
                                    <b-form-select v-model="data.service.contractsphases_id" :options="addNullCombo(data.contractsphases, 'Nessuna')" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Audit?">
                                    <b-form-select v-model="data.service.isaudit" :options="comboYesNo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Formazione?">
                                    <b-form-select v-model="data.service.istraining" :options="comboYesNo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Consulenza?">
                                    <b-form-select v-model="data.service.isconsultancy" :options="comboYesNo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Lingue">
                        <b-row v-for="item in data.service.languages" :key="item.id">
                            <b-col lg="2">
                                <b-form-group :label="'Titolo - ' + item.lang">
                                    <b-form-input v-model="item.title"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="5">
                                <b-form-group :label="'Preventivo - ' + item.lang">
                                    <b-form-textarea v-model="item.descriptionquote" rows="5" max-rows="10"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="5">
                                <b-form-group :label="'Commessa / Fattura - ' + item.lang">
                                    <b-form-textarea v-model="item.descriptioninvoice" rows="5" max-rows="10"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.service.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "service",
    data() {
        return {
            show: false,
            data: null,
            comboActive: [{
                id: true,
                val: "Attivo",
            },{
                id: false,
                val: "Non Attivo",
            }],
            comboYesNo: [{
                id: true,
                val: "Si",
            },{
                id: false,
                val: "No",
            }],
        }
    },
    methods: {
        loadData(id){
            this.openHttp("settings/orders/service/", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("settings/orders/service/save", this.data.service).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("settings/orders/service/delete", { id: this.data.service.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    },
}
</script>