<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Marketing Template - ' + data.template.id" @hidden="closeForm()" @close="closeForm()">
      <marketingtemplateattachment ref="attachment" @update="loadData(data.template.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Mail" active>
            <b-form-group label="Template">
              <b-form-input v-model="data.template.marketingtemplate"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.template.note"/>
            </b-form-group>
            <b-form-group label="Oggetto">
              <b-form-input v-model="data.template.subject"/>
            </b-form-group>
            <b-form-group label="Html">
              <customeditor v-model="data.template.html" :height="winHeight - 740" :showToolbar="true"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Allegati" v-if="data.template.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.attachment.loadData(data.template.id, 0)">Carica</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 400) + 'px'" responsive class="mt-2" :items="data.attachments" :fields="fields.attachments" @row-clicked="$refs.attachment.loadData(data.template.id, $event.id)">
              <template v-slot:cell(download)="row">
                <b-btn variant="primary" size="sm" @click="$refs.attachment.downloadData(row.item.id, 0)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.template.id > 0" @click="data.template.id = 0">Duplica</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.template.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import customeditor from '../customeditor.vue';
import marketingtemplateattachment from './marketingtemplateattachment.vue';

export default {
  name: "marketingtemplate",
  components: {
    customeditor, marketingtemplateattachment
  },
  data() {
    return {
      show: false,
      data: null,
      fields: {
        attachments: [{
          key: "filename",
          label: "File"
        },{
          key: "download",
          label: ""
        }],
        mailsboxesusers: [{
          key: "val",
          label: "Utente"
        },{
          key: "mailsboxes",
          label: "Abilitazioni"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("settings/mails/marketingtemplate/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("settings/mails/marketingtemplate/save", this.data.template).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("settings/mails/marketingtemplate/delete", { id: this.data.template.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>