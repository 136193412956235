<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Campagna - ' + data.campaign.id" scrollable @hidden="closeForm" @close="closeForm">
      <company ref="company" @update="loadActions()"/>
      <marketingaction ref="marketingaction" @update="loadData(data.campaign.id)"/>
      <mail ref="mail"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Campagna" active>
            <b-row>
              <b-col lg="10">
                <b-form-group label="Campagna">
                  <b-form-input v-model="data.campaign.campaign"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.campaign.enabled" :options="enabledCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Personale">
              <multiselect v-model="data.usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.campaign.users = updateCombo(data.usersSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.campaign.note" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Possibili clienti" v-if="data.campaign.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" :items="actions.items.data" :fields="actions.fields" :busy="actions.isBusy" :no-local-sorting="true" :sort-by.sync="actions.search.sortby" :sort-desc.sync="actions.search.sortdesc" @sort-changed="sortingChangeActions" @row-clicked="$refs.marketingaction.loadData($event.id)">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:cell(company)="row">
                <b-link @click="$refs.company.loadData(row.item.companies_id)">{{ row.item.company }}</b-link>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
            <tablebottom :busy="actions.isBusy" :currentpage="actions.search.page" :total="actions.items.total" @updateCurrentPage="actions.search.page = $event" @loadData="loadActions()"/>
          </b-tab>
          <b-tab title="Mails" v-if="data.campaign.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" :tbody-tr-class="rowClass" :items="mails.items.data" :fields="mails.fields" :busy="mails.isBusy" :no-local-sorting="true" :sort-by.sync="mails.search.sortby" :sort-desc.sync="mails.search.sortdesc" @sort-changed="sortingChangeMails">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:cell(mailboxesmails_id)="row">
                <b-link v-if="row.value > 0" @click="$refs.mail.loadData(row.value)">Mail</b-link>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
            <tablebottom :busy="mails.isBusy" :currentpage="mails.search.page" :total="mails.items.total" @updateCurrentPage="mails.search.page = $event" @loadData="loadMails()"/>
          </b-tab>
          <b-tab title="Telefonate" v-if="data.campaign.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" :tbody-tr-class="rowClass" :items="notes.items.data" :fields="notes.fields" :busy="notes.isBusy" :no-local-sorting="true" :sort-by.sync="notes.search.sortby" :sort-desc.sync="notes.search.sortdesc" @sort-changed="sortingChangeNotes">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
          </b-tab>
          <b-tab title="Successo" v-if="data.campaign.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" :tbody-tr-class="rowClass" :items="success.items.data" :fields="success.fields" :busy="success.isBusy" :no-local-sorting="true" :sort-by.sync="success.search.sortby" :sort-desc.sync="success.search.sortdesc" @sort-changed="sortingChangeSuccess">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
          </b-tab>
          <b-tab title="Falliti" v-if="data.campaign.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 420) + 'px'" responsive show-empty mode="remote" :tbody-tr-class="rowClass" :items="failed.items.data" :fields="failed.fields" :busy="failed.isBusy" :no-local-sorting="true" :sort-by.sync="failed.search.sortby" :sort-desc.sync="failed.search.sortdesc" @sort-changed="sortingChangeFailed">
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-model="tempActions.show" title="Pianifica" size="lg">
            <b-form-group label="Tipologia">
              <multiselect v-model="tempActions.companiestypesSupport" :options="tempActions.filtersMenu.companiestypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.companiestypes = updateCombo(tempActions.companiestypesSupport)"/>
            </b-form-group>
            <b-form-group label="Segnalatore">
              <multiselect v-model="tempActions.suppliersSupport" :options="tempActions.filtersMenu.suppliers" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.suppliers = updateCombo(tempActions.suppliersSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Tipo contatto">
                  <b-form-select v-model="tempActions.filters.worker" :options="workerCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Provincia">
                  <multiselect v-model="tempActions.provincesSupport" :options="tempActions.filtersMenu.provinces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.provinces = updateCombo(tempActions.provincesSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Regioni">
                  <multiselect v-model="tempActions.regionsSupport" :options="tempActions.filtersMenu.regions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.regions = updateCombo(tempActions.regionsSupport)"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Stati">
                  <multiselect v-model="tempActions.statesSupport" :options="tempActions.filtersMenu.states" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.states = updateCombo(tempActions.statesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Tags">
              <multiselect v-model="tempActions.tagsSupport" :options="tempActions.filtersMenu.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.tags = updateCombo(tempActions.tagsSupport)"/>
            </b-form-group>
            <b-form-group label="NACE">
              <multiselect v-model="tempActions.nacesSupport" :options="tempActions.filtersMenu.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="tempActions.filters.naces = updateCombo(tempActions.nacesSupport)"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="success" @click="saveActions()">Salva</b-btn>
              </div>
            </template>
          </b-modal>
          <b-modal v-model="tempMails.show" title="Pianifica">
            <b-form-group label="Casella">
              <b-form-select v-model="tempMails.filters.mailboxes_id" :options="tempMails.filtersMenu.mailboxes" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Template">
              <b-form-select v-model="tempMails.filters.marketingtemplates_id" :options="tempMails.filtersMenu.marketingtemplates" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Scadenza incaricato" v-if="tempMails.filtersMenu.users">
              <b-form-select v-model="tempMails.filters.note_users_id" :options="tempMails.filtersMenu.users.filter(x => data.campaign.users.includes(x.id))" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Scadenza gg dopo">
              <b-form-input v-model="tempMails.filters.note_daysadd" type="number"/>
            </b-form-group>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="success" @click="saveMails()">Salva</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.campaign.id > 0" @click="deleteData()">Elimina</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.campaign.id > 0" @click="prepareActions()">Aggiungi clienti</b-btn>
          <b-btn variant="info" class="mr-2" v-show="data.campaign.id > 0" @click="prepareMails()">Prepara invio circolare</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import marketingaction from './marketingaction';

export default {
  name: "marketingcampaign",
  components: {
    marketingaction
  },
  data() {
    return {
      show: false,
      data: null,
      actions: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "fullname",
          label: "Nome",
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        },{
          key: 'job',
          label: "Mansione"
        },{
          key: "datetime",
          label: "Data e Ora",
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
          sortable: true,
        },{
          key: "status",
          label: "Stato",
          sortable: true,
        }],
        items: {
          total: 0,
          data: []
        }
      },
      tempActions: {
        show: false,
        companiestypesSupport: [],
        suppliersSupport: [],
        provincesSupport: [],
        regionsSupport: [],
        statesSupport: [],
        tagsSupport: [],
        nacesSupport: [],
        filtersMenu: {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: [],
        },
        filters: {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: [],
          worker: null,
          date: null
        }
      },
      mails: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        },{
          key: 'marketingtemplate',
          label: "Template",
        },{
          key: 'mailbox',
          label: "Casella",
        },{
          key: 'datetime',
          label: "Caricamento",
          sortable: true
        },{
          key: 'success',
          label: "Esito",
          formatter: value => {
            return value === true ? "Inviata" : (value === false ? "Fallita" : "Caricata"); 
          }
        },{
          key: 'mailboxesmails_id',
          label: "Mail",
        }],
        items: {
          total: 0,
          data: []
        }
      },
      notes: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        },{
          key: 'datetime1',
          label: "Scadenza",
          sortable: true
        },{
          key: 'datetime2',
          label: "Nota",
          sortable: true
        },{
          key: 'note',
          label: "Nota",
        }],
        items: {
          total: 0,
          data: []
        }
      },
      success: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        }],
        items: {
          total: 0,
          data: []
        }
      },
      failed: {
        isBusy: true,
        search: {
          page: 1,
          filters: {
            marketingcampaigns_id: null
          },
          sortby: "company",
          sortdesc: false,
        },
        fields: [{
          key: 'company',
          label: 'Azienda',
          sortable: true,
        },{
          key: 'fullname',
          label: 'Nome',
        },{
          key: 'contact',
          label: "Mail",
          sortable: true
        }],
        items: {
          total: 0,
          data: []
        }
      },
      tempMails: {
        show: false,
        filtersMenu: {
          mailboxes: [],
          marketingtemplates: []
        },
        filters: {
          mailboxes_id: null,
          marketingtemplates_id: null
        }
      },
      workerCombo: [{
        id: null,
        val: "Tutti"
      },{
        id: true,
        val: "Solo con lavoratore"
      },{
        id: false,
        val: "Solo mail aziendali"
      }],
      enabledCombo: [{
        id: true,
        val: "Attiva"
      },{
        id: false,
        val: "Non attiva"
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    rowsMails() {
      return this.data.mails.length;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "marketing").accesslevel;
    }
  },
  methods: {
    rowClass( item ){
      if ( item !== null ) return item.ping === true ? "table-success" : "";
    },
    loadData(id){
      this.actions.search.filters.marketingcampaigns_id = id;
      this.actions.search.page = 1;
      this.loadActions();
      this.mails.search.filters.marketingcampaigns_id = id;
      this.mails.search.page = 1;
      this.loadMails();
      this.notes.search.filters.marketingcampaigns_id = id;
      this.notes.search.page = 1;
      this.loadNotes();
      this.success.search.filters.marketingcampaigns_id = id;
      this.success.search.page = 1;
      this.loadSuccess();
      this.failed.search.filters.marketingcampaigns_id = id;
      this.failed.search.page = 1;
      this.loadFailed();
      this.openHttp("marketing/campaign/", { id: id }).then((result) => {
        result.usersSupport = this.convertCombo(result.users, result.campaign.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("marketing/campaign/save", this.data.campaign).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("marketing/campaign/delete", { id: this.data.campaign.id }).then((result) => {
        if ( result !== null) this.closeForm();
      })
    },
    loadActions(){
      this.openHttp("marketing/campaign/actions", this.actions.search).then((result) => {
        this.actions.items = result;
        this.actions.isBusy = false;
      })
    },
    sortingChangeActions(ctx) {
      this.actions.search.sortby = ctx.sortBy;
      this.actions.search.sortdesc = ctx.sortDesc;
      this.loadActions();
    },
    loadMails(){
      this.openHttp("marketing/campaign/mails", this.mails.search).then((result) => {
        this.mails.items = result;
        this.mails.isBusy = false;
      })
    },
    sortingChangeMails(ctx) {
      this.mails.search.sortby = ctx.sortBy;
      this.mails.search.sortdesc = ctx.sortDesc;
      this.loadMails();
    },
    loadNotes(){
      this.openHttp("marketing/campaign/notes", this.notes.search).then((result) => {
        this.notes.items = result;
        this.notes.isBusy = false;
      })
    },
    sortingChangeNotes(ctx) {
      this.notes.search.sortby = ctx.sortBy;
      this.notes.search.sortdesc = ctx.sortDesc;
      this.loadNotes();
    },
    loadSuccess(){
      this.openHttp("marketing/campaign/success", this.success.search).then((result) => {
        this.success.items = result;
        this.success.isBusy = false;
      })
    },
    sortingChangeSuccess(ctx) {
      this.success.search.sortby = ctx.sortBy;
      this.success.search.sortdesc = ctx.sortDesc;
      this.loadSuccess();
    },
    loadFailed(){
      this.openHttp("marketing/campaign/failed", this.failed.search).then((result) => {
        this.failed.items = result;
        this.failed.isBusy = false;
      })
    },
    sortingChangeFailed(ctx) {
      this.failed.search.sortby = ctx.sortBy;
      this.failed.search.sortdesc = ctx.sortDesc;
      this.loadFailed();
    },
    prepareActions(){
      this.openHttp("marketing/campaign/actions/prepare", null).then((result) => {
        this.tempActions.filtersMenu = result;
        this.tempActions.companiestypesSupport = this.convertCombo(result.companiestypes, []);
        this.tempActions.suppliersSupport = this.convertCombo(result.suppliers, []);
        this.tempActions.provincesSupport = this.convertCombo(result.provinces, []);
        this.tempActions.regionsSupport = this.convertCombo(result.regions, []);
        this.tempActions.statesSupport = this.convertCombo(result.states, []);
        this.tempActions.tagsSupport = this.convertCombo(result.tags, []);
        this.tempActions.nacesSupport = this.convertCombo(result.naces, []);
        this.tempActions.filters= {
          companiestypes: [],
          suppliers: [],
          provinces: [],
          regions: [],
          states: [],
          tags: [],
          naces: []
        };
        this.tempActions.show = true;
      })
    },
    saveActions(){
      this.tempActions.show = false;
      this.tempActions.filters.id = this.data.campaign.id;
      this.saveHttp("marketing/campaign/actions/save", this.tempActions.filters).then((result) => {
        if (result !== null) this.loadData(this.data.campaign.id);
      })
    },
    prepareMails(){
      this.openHttp("marketing/campaign/mails/prepare", null).then((result) => {
        this.tempMails.filtersMenu = result;
        this.tempMails.filters = {
          mailboxes_id: null,
          marketingtemplates_id: null,
          note_users_id: null,
          note_daysadd: 0
        };
        this.tempMails.show = true;
      })
    },
    saveMails(){
      this.tempMails.show = false;
      this.tempMails.filters.marketingcampaigns_id = this.data.campaign.id;
      this.saveHttp("marketing/campaign/mails/save", this.tempMails.filters).then((result) => {
        if (result !== null) this.loadData(this.data.campaign.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>