<template>
    <div>
        <b-modal v-if="show" v-model="show" :title="'Norma - ' + data.standard.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Norma" active>
                        <b-row>
                            <b-col lg="6">
                                <b-form-group label="Norma">
                                    <b-form-select v-model="data.standard.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standard.points = []"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Codice" v-if="data.standard.id > 0">
                                    <b-form-input v-model="data.standard.certificatecode" :disabled="data.standard.contractsstandardsstatus_id == 1"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3">
                                <b-form-group label="1a emissione">
                                    <b-form-input v-model="data.standard.certificatefirstissue" type="date"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Emissione iniziale">
                                    <b-form-select v-model="data.standard.firstcertificatewithanothercb" :options="firstcertificatewithanothercbCombo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" v-show="data.notfirst">
                                <b-form-group label="Emissione del tran. / ric.">
                                    <b-form-input v-model="data.standard.datetransferissue" type="date"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" v-show="data.notfirst">
                                <b-form-group label="Scadenza del tran./ ric.">
                                    <b-form-input v-model="data.standard.datetransferexpirancy" type="date"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Prodotti" v-if="data.standard.standards_id > 0 && data.standards.find(x => x.id === data.standard.standards_id).product === true">
                        <b-btn variant="primary" size="sm" @click="data.standard.products.push({ id: 0, productfamily: null, productname: null })">Nuovo</b-btn>
                        <b-table no-border-collapse class="mt-2" :items="data.standard.products" :fields="fieldsProducts">
                            <template v-slot:cell(productfamily)="row">
                                <b-form-input v-model="row.item.productfamily" size="sm"/>
                            </template>
                            <template v-slot:cell(productname)="row">
                                <b-form-input v-model="row.item.productname" size="sm"/>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-btn variant="danger" size="sm" @click="data.standard.products.splice(row.index,1)">Elimina</b-btn>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractstandard",
    data() {
        return {
            show: false,
            data: null,
            fieldsProducts: [{
                key: "productfamily",
                label: "Famiglia",
            },{
                key: "productname",
                label: "Prodotto",
            },{
                key: "id",
                label: "",
            }],
            firstcertificatewithanothercbCombo: [{
                id: false,
                val: "Da stesso ente"
            },{
                id: true,
                val: "Da altro ente"
            }]
        }
    },
    methods: {
        loadData(contractsId, id){
            this.openHttp("contracts/standard/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/standard/save", this.data.standard).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/standard/delete", { id: this.data.standard.id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>