<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Sito - ' + data.site.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="8">
                    <b-form-group label="Sito">
                        <b-form-select v-model="data.site.sites_id" :options="data.sites" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Anagrafica">
                        <b-btn :variant="data.sites.findIndex(x => x.id === data.site.sites_id) > -1 ? (data.sites.find(x => x.id === data.site.sites_id).active == true ? 'success' : 'danger') : 'warning'">{{ data.sites.findIndex(x => x.id === data.site.sites_id) > -1 ? (data.sites.find(x => x.id === data.site.sites_id).active == true ? 'Attivo' : 'Non attivo') : '?' }}</b-btn>
                        <b-btn v-if="data.sites.findIndex(x => x.id === data.site.sites_id) > -1" :variant="(data.sites.find(x => x.id === data.site.sites_id).ismain == true ? 'success' : '')" class="ml-2">Principale</b-btn>
                        <b-btn v-if="data.sites.findIndex(x => x.id === data.site.sites_id) > -1" :variant="(data.sites.find(x => x.id === data.site.sites_id).isregistered == true ? 'danger' : '')" class="ml-2">Legale</b-btn>
                        <b-btn v-if="data.sites.findIndex(x => x.id === data.site.sites_id) > -1" :variant="(data.sites.find(x => x.id === data.site.sites_id).isoperative == true ? 'primary' : '')" class="ml-2">Operativa</b-btn>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="2">
                    <b-form-group label="Lav. totali">
                        <b-form-input v-model="data.site.employeesnumber" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Lav. effettivi">
                        <b-form-input v-model="data.site.employeeseffectivenumber" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Lav. esterni">
                        <b-form-input v-model="data.site.employeesexternal" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Lav, mansioni ripetitive">
                        <b-form-input v-model="data.site.employeesrepetitive" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Turni">
                        <b-form-input v-model="data.site.shifts" type="number" min="0" step="1"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="8">
                    <b-form-group label="Attività">
                        <b-form-checkbox-group v-model="data.site.naces" :options="data.naces" value-field="id" text-field="val" stacked/>
                        <b-form-checkbox @change="data.site.naces = ($event == true ? data.naces.map(x => x.id) : [])">Seleziona / deseleziona tutto</b-form-checkbox>                        
                    </b-form-group>
                </b-col>
                <b-col lg="4">
                    <b-form-group label="Personale effettivo in caso di riduzioni">
                        <b-input-group size="sm" v-for="(item, index) of data.site.employees" :prepend="data.standards.find(x => x.id === item.contractsstandards_id).val" :key="index" class="mb-1">
                            <b-form-input v-model="item.employeeseffectivenumber"/>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.site.note" rows="3" max="6"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.site.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractsite",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/site/", { contracts_id: contractsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("contracts/site/save", this.data.site).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/site/delete", {id: this.data.site.id}).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>
