<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Costo registrato - ' + data.cost.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Commessa">
        <b-form-select v-model="data.cost.orders_id" :options="data.orders" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Data">
        <b-form-input type="date" v-model="data.cost.date"/>
      </b-form-group>
      <b-form-group label="Collaboratore" v-if="data.users.length > 0">
        <b-form-select v-model="data.cost.users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.cost.orderscoststypes_id" :options="data.orderscoststypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Spesa">
        <b-form-input type='number' step='0.01' v-model="data.cost.cost"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.cost.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.cost.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ordercost",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( ordersId, id ){
      this.openHttp("orderscosts/ordercost/", { orders_id: ordersId, id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("orderscosts/ordercost/save", this.data.cost).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("orderscosts/ordercost/delete", { id: this.data.cost.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');
    },
  },
}
</script>