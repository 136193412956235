<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" title="Naces" size="lg" hide-footer>
      <b-form-group label="Filtro">
        <b-form-input v-model="criteria"/>
      </b-form-group>
      <b-form-group label="Tabella">
        <b-table :sticky-header="(winHeight - 335) + 'px'" hover responsive :items="data" :fields="fields" :filter="criteria"/>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companynaces",
  data() {
    return {
      show: false,
      data: null,
      criteria: null,
      fields: [{
        key: "standard",
        label: "Norma",
        sortable: true,
      },{
        key: "certificationbodies",
        label: "Enti",
      },{
        key: "nacesok",
        label: 'OK',
      },{
        key: "nacesko",
        label: "KO",
      }]
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( naces ){
      this.criteria = null;
      this.openHttp("companies/company/naces", { naces: naces }).then((result) => {
        this.data = result;
        this.show = true;
      })
    }
  },
}
</script>