<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Checklist - ' + data.id" scrollable @hidden="closeForm" @close="closeForm">
      <checklistsite ref="site" @update="loadData(data.id)"/>
      <checklistworker ref="worker" @update="loadData(data.id)"/>
      <checklistopportunitytobeclosed ref="opportunitytobeclosed" @update="loadData(data.id)"/>
      <checkliststrength ref="strength" @update="loadData(data.id)"/>
      <checklistopportunity ref="opportunity" @update="loadData(data.id)"/>
      <checklistnonconformity ref="nonconformity" @update="loadData(data.id)"/>
      <checklistnonconformitytobeclosed ref="nonconformitytobeclosed" @update="loadData(data.id)"/>
      <checklistmonitoring ref="monitoring" @update="loadData(data.id)"/>
      <checklistsignature ref="signature" @update="loadData(data.id)"/>
      <checklistsdocument ref="document" @update="loadData(data.id)"/>
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab :title="translate('opening meeting') + ' ' + (data.audit.reduce((acc, cur) => acc && cur.signature_initial && cur.workers_id > 0, true) ? 'Ok' : 'No')" active>
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index">
                  <template #title>
                    <font :color="item.signature_initial === true && item.workers_id > 0 ? 'green' : 'red'">{{ item.company + ' ' + item.standards }}</font>
                  </template>
                  <b-table-simple responsive>
                    <b-thead head-variant="light">
                      <b-tr>
                        <b-th style="width: 25%">{{ translate('parameter') }}</b-th>
                        <b-th>{{ translate('value') }}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>{{ translate('company') }}</b-td>
                        <b-td>{{ item.company }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('vat number') }}</b-td>
                        <b-td>{{ item.vat }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('certification body') }}</b-td>
                        <b-td>{{ item.certificationbody }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('scopes') }}</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.scopes" :key="index"><strong>{{ item.lang }}: </strong> {{ item.scope }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>NACE (IAF)</b-td>
                        <b-td>{{ item.naces }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('sites on the certificate') }}</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.contractssites" :key="index">{{ item }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('standards and phases') }}</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.standardsphases" :key="index">{{ item.standard + ": " + item.phases }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('points not to be verified') }}</b-td>
                        <b-td>
                          <div v-for="(item, index) of item.exclusions" :key="index">{{ item.standard + ': ' + item.exclusion }}</div>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('company representative') }}</b-td>
                        <b-td>
                          <b-form-select size="sm" v-model="item.workers_id" :options="data.workerscombo" value-field="id" text-field="val" @change="saveWorkerRepresentative(item.id, $event)"/>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td>{{ translate('approval of the company') }}</b-td>
                        <b-td>
                          <b-btn :variant="item.signature_initial === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item.signature_initial_id, item.id)">{{ item.signature === true ? translate('approved') : translate('to be approved') }}</b-btn>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab :title="translate('sites and employees') + ' ' + (data.audit.reduce((acc, cur) => acc && cur.sites.reduce((acc2, cur2) => acc2 && cur2.locked, true), true) === true ? 'Ok' : 'Ko')">
            <b-tabs card>
              <b-tab v-for="(item, index) of data.audit" :key="index">
                <template #title>
                  <font :color="item.sites.reduce((acc2, cur2) => acc2 && cur2.locked, true) === true ? 'green' : 'red'">{{ item.company + ' ' + item.standards }}</font>
                </template>
                <b-form-group :label="translate('sites and employees')">
                  <b-btn variant="primary" size="sm" @click="$refs.site.loadData(0, item.id)">{{ translate('new yard') }}</b-btn>
                  <b-table responsive :items="item.sites" :fields="fieldsCleanedSites" :sticky-header="(winHeight - 275) + 'px'" class="mt-3" @row-clicked="$refs.site.loadData($event.id, item.id)" :tbody-tr-class="rowClassSites">
                    <template v-slot:cell(sampled)="row">
                      {{ row.value === true ? translate("sampled") : translate("not sampled") }}
                    </template>
                    <template v-slot:cell(locked)="row">
                      {{ row.value === true ? translate("confirmed") : translate("not confirmed") }}
                    </template>
                  </b-table>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab :title="translate('participants')">
            <b-card no-body>
              <b-tabs card>
                <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presencesworkers" :key="index">
                  <b-btn variant="primary" size="sm" @click="$refs.worker.loadData(0, item.companies_id, data.id)">{{ translate('new') }}</b-btn>
                  <b-table responsive :sticky-header="(winHeight - 435) + 'px'" class="mt-3" :items="item.workers" :fields="fieldsCleanedPresencesWorkers" @row-clicked="$refs.worker.loadData($event.workers_id, item.companies_id, data.id)">
                    <template v-slot:cell(fullname)="row">
                      <div class="mb-2">{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(phases)="row">
                      <div v-for="(item2, index2) of row.item.phases" :key="index2" class="mb-1">
                        <nobr>
                          <b-btn :variant="item2.presence === true ? 'success' : 'warning'" class="mr-2" size="sm" @click="markWorkerPresence(item.id, item2.auditsphases_id, row.item.workers_id)">{{ item2.phase }}: Presente</b-btn>
                          <b-btn v-show="item2.presence === true" :variant="item2.signaturein_id !== null ? 'success' : 'danger'" class="mr-2" size="sm" @click="markWorkerOpeningMeeting(item2.presences_id)">Riunione iniziale</b-btn>
                          <b-btn v-show="item2.presence === true" :variant="item2.signatureout_id !== null ? 'success' : 'danger'" class="mr-2" size="sm" @click="markWorkerClosingMeeting(item2.presences_id)">Riunione finale</b-btn>
                        </nobr>
                      </div>
                    </template>
                    <template v-slot:cell(signatures)="row">
                      <div v-for="(item2, index2) of row.item.phases" :key="index2" class="mb-1">
                        <nobr>
                          <b-btn v-show="item2.signaturein_id > 0" :variant="item2.signaturein_check === true ? 'success' : 'danger'" class="mr-2" size="sm" @click="loadSignature(item2.signaturein_id, item.id)">Firma</b-btn>
                          <b-btn v-show="item2.signatureout_id > 0" :variant="item2.signatureout_check === true ? 'success' : 'danger'" class="mr-2" size="sm" @click="loadSignature(item2.signatureout_id, item.id)">Firma</b-btn>
                        </nobr>
                      </div>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab :title="translate('auditors') + ' ' + (data.presencesauditsusers.reduce((acc, cur) => acc && cur.users.reduce((acc2, cur2) => acc2 && cur2.signaturein_check && cur2.signatureout_check, true), true) ? 'Ok' : 'No')">
            <b-card no-body>
              <b-tabs card>
                <b-tab :title="item.company + ' ' + item.standards" v-for="(item, index) of data.presencesauditsusers" :key="index">
                  <b-table responsive :sticky-header="(winHeight - 435) + 'px'" class="mt-3" :items="item.users" :fields="fieldsCleanedPresencesUsers">
                    <template v-slot:cell(signaturein)="row">
                      <b-btn size="sm" :variant="row.item.signaturein_check === true ? 'success' : 'danger'" @click="loadSignature(row.item.signaturein_id, item.id)">{{ translate('signature') }}</b-btn>
                    </template>
                    <template v-slot:cell(signatureout)="row">
                      <b-btn size="sm" :variant="row.item.signatureout_check === true ? 'success' : 'danger'" @click="loadSignature(row.item.signatureout_id, item.id)">{{ translate('signature') }}</b-btn>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab v-for="(paragraph, paragraphIndex) of evidences" :key="paragraphIndex" :title="paragraph.paragraph + ' '  + paragraph.percentage + '%'">
            <b-table responsive :sticky-header="(winHeight - 285) + 'px'" :items="paragraph.questions" :fields="data.auditsstatus_id >= 4 ? fieldsCleanedEvidencesVeto : fieldsCleanedEvidences" :tbody-tr-class="rowClassEvidences">
              <template v-slot:cell(question)="row">
                <h5 v-if="row.item.sites_id !== null">Sede {{ data.sites.findIndex(x => x.id === row.item.sites_id) > -1 ? data.sites.find(x => x.id === row.item.sites_id).val : '???' }}</h5>
                <h6>{{ row.item.question }}</h6>
                <b-form-checkbox switch v-show="row.item.notapplicable === true" v-model="row.item.isnotapplicable" @change="saveNotApplicable(row.item.evidences_id, $event)">{{ translate('not applicable') }}</b-form-checkbox>
                <b-row v-if="row.item.notapplicable === false || row.item.isnotapplicable === false">
                  <b-col v-for="(subquestion, subquestionIndex) in row.item.subquestions" :key="subquestionIndex" :lg="subquestion.width">
                    <b-form-group :label="subquestion.question">
                      <b-form-input size="sm" v-if="subquestion.types_id === 1" :value="subquestion.evidence" @change="saveEvidence(row.item.evidences_id, subquestion.id, $event)"/>
                      <b-form-textarea size="sm" v-if="subquestion.types_id === 2" :value="subquestion.evidence" rows="2" max-rows="10" @change="saveEvidence(row.item.evidences_id, subquestion.id, $event)"/>
                      <b-form-input size="sm" v-if="subquestion.types_id === 3" :value="subquestion.evidence" type="date" @blur="saveEvidence(row.item.evidences_id, subquestion.id, $event.target.value)"/>
                      <b-form-input size="sm" v-if="subquestion.types_id === 4" :value="subquestion.evidence" type="number" step="0.01" @change="saveEvidence(row.item.evidences_id, subquestion.id, $event)"/>
                      <b-form-select size="sm" v-if="subquestion.types_id === 5" :value="subquestion.evidence" :options="data.possibleanswers.find(x => x.id === subquestion.id).answers" value-field="id" text-field="val" @change="saveEvidence(row.item.evidences_id, subquestion.id, $event)"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(vetos)="row">
                <b-alert v-for="(vetoitem, vetoindex) in row.item.vetos" :key="vetoindex" :variant="vetoitem.decision === true ? 'success' : 'danger'" show>{{ vetoitem.note }}</b-alert>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="translate('findings to be verified') + ' ' + findingstobeclosed.length">
            <b-table responsive :items="findingstobeclosed" :fields="fieldsCleanedOpportunitiestobeclosed" :sticky-header="(winHeight - 275) + 'px'" @row-clicked="$refs.opportunitytobeclosed.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab :title="translate('non conformities to be closed') + ' ' + nonconformitiestobeclosed.length">
            <b-table responsive :items="nonconformitiestobeclosed" :fields="fieldsCleanedNonconformitiestobeclosed" :sticky-header="(winHeight - 275) + 'px'" @row-clicked="$refs.nonconformitytobeclosed.loadData($event.id, data.id)">
              <template v-slot:cell(vetos)="row">
                <b-alert v-if="row.item.decision === true" variant="success" show>{{ translate("verified") }}</b-alert>
                <b-alert v-if="row.item.decision === false" variant="danger" show>{{ translate("not verified") }}<br>{{ row.item.note }}</b-alert>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="translate('strengths') + ' ' + strengths.length">
            <b-btn variant="success" size="sm" @click="$refs.strength.loadData(0,data.id)">{{ translate('new') }}</b-btn>
            <b-table responsive :items="strengths" :fields="fieldsCleanedStrengths" :sticky-header="(winHeight - 275) + 'px'" class="mt-2" @row-clicked="$refs.strength.loadData($event.id, data.id)"/>
          </b-tab>
          <b-tab :title="translate('findings') + ' ' + findings.length">
            <b-btn variant="success" size="sm" @click="$refs.opportunity.loadData(0, data.id)">{{ translate('new') }}</b-btn>
            <b-table responsive :items="findings" :fields="fieldsCleanedFindings" class="mt-2" @row-clicked="$refs.opportunity.loadData($event.id, data.id)">
              <template v-slot:cell(vetos)="row">
                {{ row.item }}
              </template>
            </b-table> 
          </b-tab>
          <b-tab :title="translate('non conformities') + ' ' + nonconformities.length">
            <b-btn variant="success" size="sm" @click="$refs.nonconformity.loadData( 0, data.id)">{{ translate('new') }}</b-btn>
            <b-table responsive :items="nonconformities" :fields="fieldsCleanedNonconformities" :sticky-header="(winHeight - 275) + 'px'" class="mt-2" @row-clicked="$refs.nonconformity.loadData($event.id, data.id)">
              <template v-slot:cell(vetos)="row">
                {{ row.item }}
              </template>
            </b-table> 
          </b-tab>
          <b-tab :title="translate('closing meeting') + ' ' + (data.audit.map(x => x.signature_final).findIndex(x => x === false) >= 0 ? 'No' : 'Ok')">
            <b-card no-body>
              <b-tabs card>
                <b-tab v-for="(item, index) of data.audit" :key="index">
                  <template #title>
                    <font :color="item.signature_final === true ? 'green' : 'red'">{{ item.company + ' ' + item.standards }}</font>
                  </template>
                  <b-table-simple responsive>
                    <b-thead head-variant="light">
                      <b-tr>
                      </b-tr>
                      <b-tr>
                        <b-th style="width: 55%">{{ translate('standard') }}</b-th>
                        <b-th style="width: 15%">{{ translate('strengths') }}</b-th>
                        <b-th style="width: 15%">{{ translate('opportunities') }}</b-th>
                        <b-th style="width: 15%">{{ translate('non conformities') }} </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item2, index2) of item.standardsphases.filter(x => x.phases_id !== 1)" :key="index2">
                        <b-td>{{ item2.standard + ": " + item2.phases }}</b-td>
                        <b-td><nobr>{{ item2.strengths }}</nobr></b-td>
                        <b-td><nobr>{{ item2.opportunities }}</nobr></b-td>
                        <b-td><nobr>{{ item2.nonconformities }}</nobr></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <hr/>
                  <b-row v-for="(item2, index2) of item.decisions" :key="index2">
                    <b-col lg="3">
                      <b-form-group :label="translate('phase')">
                        <b-form-input :value="item2.phase"/>
                      </b-form-group>
                    </b-col>
                    <b-col lg="3">
                      <b-form-group :label="translate('lead auditor decision')">
                        <b-form-select v-model="item2.auditsdecisionstypes_id" :options="data.decisions" value-field="id" text-field="val" @change="saveDecision(item.id, item2.auditsphases_id, $event, item2.note)"/>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group :label="translate('note')">
                        <b-form-textarea v-model="item2.note" rows="3" @change="saveDecision(item.id, item2.auditsphases_id, item2.auditsdecisionstypes_id, $event)"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group :label="translate('approval of the company')">
                    <b-btn :variant="item.signature_final === true ? 'success' : 'danger'" size="sm" @click="loadSignature(item.signature_final_id, item.id)">{{ item.signature_final === true ? translate('approved') : translate('to be approved') }}</b-btn>                  
                  </b-form-group>                  
                </b-tab>
              </b-tabs>
            </b-card>
          </b-tab>
          <b-tab :title="translate('documents') + ' ' + data.documents.length">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.id)">{{ translate('generate') }}</b-btn>
            <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.document.downloadDataAll(data.id)">{{ translate('download all') }}</b-btn>
            <b-table class="mt-2" :sticky-header="(winHeight - 275) + 'px'" :tbody-tr-class="rowClassDocuments" hover responsive :items="data.documents" :fields="fieldsCleanedDocuments" @row-clicked="$refs.document.loadData(data.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="warning" class="mr-2" v-show="row.item.datetime !== null && row.item.locked === false" size="sm" @click="$refs.document.lockData(row.item.id, true)">{{ translate('lock') }}</b-btn>
                <b-btn variant="primary" v-show="row.item.datetime !== null" size="sm" @click="$refs.document.downloadData(row.item.id)">{{ translate('download') }}</b-btn>
              </template>
            </b-table>
          </b-tab>
          <!--
          <b-tab title="Monitoring" v-if="data.monitoring.length > 0">
            <b-table :items="data.monitoring" :fields="fields.monitoring" :sticky-header="(winHeight - 295) + 'px'" @row-clicked="$refs.monitoring.loadData($event.id)"/>
          </b-tab>
          -->
          <b-tab :title="translate('mails') + ' ' + data.mails.length" v-if="data.mails.length > 0">
            <mail ref="mail" @update="loadData(data.id)"/>
            <b-table :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fieldsCleanedMails" @row-clicked="$refs.mail.loadData($event.id)"/>
          </b-tab>
          <b-tab :title="'Errori - ' + errors.length" v-if="errors.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 405)+ 'px'" :items="errors" :fields="fields.errors"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.id)">{{ translate('refresh') }}</b-btn>
          <b-btn variant="success" class="mr-2" @click="$refs.excelFormSubmit.submit()">Excel</b-btn>
          <b-btn variant="info" :href="'#/externalaudit/' + data.token" target="_blank">Cliente</b-btn>
        </div>
      </template>
      <form method="post" action="/api/checklists/checklist/excel" ref="excelFormSubmit" target="_blank">
        <input type="hidden" name="token" :value="token">
        <input type="hidden" name="id" :value="data.id">
      </form>
    </b-modal>
  </div>
</template>

<script>
import checklistsite from './checklistcomponents/checklistsite.vue';
import checklistworker from './checklistcomponents/checklistworker.vue';
import checklistsignature from './checklistcomponents/checklistsignature.vue';
import checklistopportunitytobeclosed from './checklistcomponents/checklistopportunitytobeclosed.vue';
import checklistmonitoring from './checklistcomponents/checklistmonitoring.vue';
import checkliststrength from './checklistcomponents/checkliststrength.vue';
import checklistopportunity from './checklistcomponents/checklistopportunity.vue';
import checklistnonconformity from './checklistcomponents/checklistnonconformity.vue';
import checklistnonconformitytobeclosed from './checklistcomponents/checklistnonconformitytobeclosed.vue';
import checklistsdocument from './checklistcomponents/checklistsdocument.vue';

export default {
  name: "checklist",
  components : { checklistsite, checklistworker, checklistsignature, checklistopportunitytobeclosed, checklistmonitoring, checkliststrength, checklistopportunity, checklistnonconformity, checklistnonconformitytobeclosed, checklistsdocument },
  data() {
    return {
      show: false,
      data: null,      
      evidences: [],
      strengths: [],
      findings: [],
      findingstobeclosed: [],
      nonconformities: [],
      nonconformitiestobeclosed: [],
      errors: [],
      fields: {
        sites: [{
          key: "site",
          label: "site",
        },{
          key: "sampled",
          label: "sampled",
        },{
          key: "shifts",
          label: "shifts",
        },{
          key: "employeesnumber",
          label: "employeesnumber",
        },{
          key: "employeeseffectivenumber",
          label: "employeeseffectivenumber",
        },{
          key: "employeesexternal",
          label: "employeesexternal",
        },{
          key: "employeesrepetitive",
          label: "employeesrepetitive",
        },{
          key: "naces",
          label: "activities",
        },{
          key: "locked",
          label: "",
        }],
        workers: [{
          key: "fullname",
          label: "fullname",
        },{
          key: "active",
          label: "status",
        },{
          key: "job",
          label: "job",
        },{
          key: "roles",
          label: "areas",
        },{
          key: "signatures",
          label: "signatures",
        }],
        presencesworkers: [{
          key: "fullname",
          label: "fullname"
        },{
          key: "job",
          label: "job"
        },{
          key: "roles",
          label: "areas"
        },{
          key: "phases",
          label: "phases"
        },{
          key: "signatures",
          label: "signatures"
        }],
        presencesusers: [{
          key: "fullname",
          label: "fullname"
        },{
          key: "phase",
          label: "phase"
        },{
          key: "signaturein",
          label: "opening meeting"
        },{
          key: "signatureout",
          label: "closing meeting"
        }],
        evidences: [{
          key: "question",
          label: "question",
        }],
        evidencesveto: [{
          key: "question",
          label: "question",
          thStyle: { width: "80%" },
        },{
          key: "vetos",
          label: "review",
          thStyle: { width: "20%" },
        }],
        opportunitiestobeclosed: [{
          key: "opportunity",
          label: "finding",
        },{
          key: "closure",
          label: "verify",
        },{
          key: "standardspoints",
          label: "points",
        }],
        strengths: [{
          key: "strength",
          label: "strength",
        },{
          key: "standardspoints",
          label: "points",
        }],
        findings: [{
          key: "opportunity",
          label: "finding"
        },{
          key: "standardspoints",
          label: "points"
        }],
        findingsveto: [{
          key: "opportunity",

          label: "finding"
        },{
          key: "standardspoints",
          label: "points"
          /*
        },{
          key: "vetos",
          label: "review"
          */
        }],

        nonconformities: [{
          key: "nonconformity",
          label: "non conformity",
        },{
          key: "nctype",
          label: "type",
        },{
          key: "cause",
          label: "cause",
        },{
          key: "treatment",
          label: "correction",
        },{
          key: "correctiveaction",
          label: "corrective action",
        },{
          key: "cause",
          label: "cause",
        },{
          key: "standardspoints",
          label: "points",
        }],

        nonconformitiestobeclosed: [{
          key: "nonconformity",
          label: "non conformity",
        },{
          key: "nctype",
          label: "type",
        },{
          key: "cause",
          label: "cause",
        },{
          key: "treatment",
          label: "correction",
        },{
          key: "correctiveaction",
          label: "corrective action",
        },{
          key: "cause",
          label: "cause",
        },{
          key: "standardspoints",
          label: "points",
        },{
          key: "vetos",
          label: "review"
        }],
        monitoring: [{
          key: "monitored",
          label: "Monitored",
        },{
          key: "monitoring",
          label: "Monitoring",
        },{
          key: "standards",
          label: "Standards",
        },{
          key: "role",
          label: "Role",
        },{
          key: "done",
          label: "Done",
        }],
        mails: [{
          key: 'folder',
          label: "folder",
          sortable: true
        },{
          key: 'from',
          label: 'from',
        },{
          key: "to",
          label: "to",
        },{
          key: 'subject',
          label: "subject",
        },{
          key: 'datetime',
          label: "date & time",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        documents: [{
          key: "filename",
          label: "file",
          sortable: true,
        },{
          key: "datetime",
          label: "date & time",
          formatter: value => {
            if (value === null) return null;
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "locked",
          formatter: value => {
            return value === true ? "Ok" : "No";
          },
        },{
          key: "id",
          label: "",
        }],
        errors: [{
          key: "errorname",
          label: "Errore"
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "note",
          label: "Note"
        }]
      }
    }
  },
  computed: {
    token (){
      return this.$store.getters.getToken;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    fieldsCleanedSites(){
      return this.fields.sites.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedPresencesWorkers(){
      return this.fields.presencesworkers.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedPresencesUsers(){
      return this.fields.presencesusers.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedEvidences(){
      return this.fields.evidences.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedEvidencesVeto(){
      return this.fields.evidencesveto.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedOpportunitiestobeclosed(){
      return this.fields.opportunitiestobeclosed.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedStrengths(){
      return this.fields.strengths.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedFindings(){
      const asd = JSON.parse(JSON.stringify(this.data.auditsstatus_id <= 3 ? this.fields.findings : this.fields.findingsveto));
      return asd.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedNonconformities(){
      return this.fields.nonconformities.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedNonconformitiestobeclosed(){
      return this.fields.nonconformitiestobeclosed.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedMails(){
      return this.fields.mails.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
    fieldsCleanedDocuments(){
      return this.fields.documents.map((element) => {
        element.label = this.translate(element.label);
        return element
      });
    },
  },
  methods: {
    rowClassSites(item, type) {
      if (!item || type !== 'row') return
      return item.locked === true ? 'table-success' : 'table-warning';
    },
    rowClassEvidences(item, type) {
      if (!item || type !== 'row') return
      return "table-" + (item.vetook === false ? "danger" : (item.vetook === true ? "success" : (item.isnotapplicable === true ? 'primary' : item.done !== item.todo ? 'warning' : "")));
    },    
    rowClassSignature(item, type) {
      if (!item || type !== 'row') return
      return item.signature === false ? 'table-danger' : '';
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : (item.locked === false ? "table-warning" : "");
    },
    loadData(id){
      if (this.data === null) this.show = false;
      this.openHttp("checklists/checklist/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
        this.loadEvidences();
        this.loadStrengths();
        this.loadFindings();
        this.loadFindingsToBeClosed();
        this.loadNonconformities();
        this.loadNonconformitiestobeclosed();
        this.loadErrors();
      })
    },
    loadEvidences(){
      this.openHttp("checklists/checklist/evidences", { id: this.data.id }).then((result) => {
        this.evidences = result;
      })
    },
    loadStrengths(){
      this.openHttp("checklists/checklist/strengths", { id: this.data.id }).then((result) => {
        this.strengths = result;
      })
    },
    loadFindings(){
      this.openHttp("checklists/checklist/findings", { id: this.data.id }).then((result) => {
        this.findings = result;
      })
    },
    loadFindingsToBeClosed(){
      this.openHttp("checklists/checklist/findingstobeclosed", { id: this.data.id }).then((result) => {
        this.findingstobeclosed = result;
      })
    },
    loadNonconformities(){
      this.openHttp("checklists/checklist/nonconformities", { id: this.data.id }).then((result) => {
        this.nonconformities = result;
      })
    },
    loadNonconformitiestobeclosed(){
      this.openHttp("checklists/checklist/nonconformitiestobeclosed", { id: this.data.id }).then((result) => {
        this.nonconformitiestobeclosed = result;
      })
    },
    loadErrors(){
      this.openHttp("checklists/checklist/errors", { id: this.data.id }).then((result) => {
        this.errors = result;
      })
    },
    saveWorkerRepresentative(auditsId, workerId){
      this.saveHttp("checklists/worker/representativesave", { audits_id: auditsId, workers_id: workerId }).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    markWorkerPresence(auditsId, auditsPhasesId, workersId){
      this.saveHttp("checklists/worker/presence", { audits_id: auditsId, auditsphases_id: auditsPhasesId, workers_id: workersId }).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    markWorkerOpeningMeeting(presencesId){
      this.saveHttp("checklists/worker/presenceopening", { presences_id: presencesId }).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    markWorkerClosingMeeting(presencesId){
      this.saveHttp("checklists/worker/presenceclosing", { presences_id: presencesId }).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    loadSignature(id, auditsId){
      this.$refs.signature.loadData( id, auditsId );
    },
    saveEvidence(evidencesId, subquestionId, evidence){
      this.saveHttp("checklists/evidence/save", { audits_id: this.data.id, evidences_id: evidencesId, auditssubquestions_id: subquestionId, evidence: evidence }).then((result) => {
        if (result !== null) this.loadEvidences();
      })
    },
    saveNotApplicable( evidencesId, notApplicable ){
      this.saveHttp("checklists/evidence/notapplicable", { audits_id: this.data.id, evidences_id: evidencesId, notapplicable: notApplicable }).then((result) => {
        if (result !== null) this.loadEvidences();
      })
    },
    saveDecision(auditsId, auditsphasesId, decision, note){
      this.saveHttp("checklists/checklist/decisionsave", { audits_id: auditsId, auditsphases_id: auditsphasesId, decision: decision, note: note } ).then((result) => {
        if (result !== null) this.loadData(this.data.id);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  }
}
</script>
