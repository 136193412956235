<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="'Ora registrata - ' + data.hour.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Commessa">
        <b-form-select v-model="data.hour.orders_id" :options="data.orders" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Data">
        <b-form-input type="date" v-model="data.hour.date"/>
      </b-form-group>
      <b-form-group label="Collaboratore" v-if="data.users.length > 0">
        <b-form-select v-model="data.hour.users_id" :options="data.users" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.hour.orderscoststypes_id" :options="data.ordershourstypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ore">
        <b-form-input type='number' step='0.01' v-model="data.hour.done"/>
      </b-form-group>
      <b-form-group label="Note">
        <b-form-textarea v-model="data.hour.note" rows="4"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.hour.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "orderhour",
  data() {
    return {
      show: true,
      data: null,
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( ordersId, id ){
      this.openHttp("ordershours/orderhour/", { orders_id: ordersId, id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("ordershours/orderhour/save", this.data.hour).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("ordershours/orderhour/delete", { id: this.data.hour.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>