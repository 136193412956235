<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="translate('strength') + ' - ' + data.strength.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-form-group label="Audit" v-if="data.audits.length > 1">
        <b-form-select size="sm" v-model="data.strength.audits_id" :options="data.audits" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Descrizione punto di forza">
        <b-form-textarea size="sm" v-model="data.strength.strength" rows="5"/>
      </b-form-group>
      <b-form-checkbox-group v-model="data.strength.standardspoints">
        <b-form-group v-for="item in data.points.find(x => x.id === data.strength.audits_id).standardpoints" :key="item.standard" :label="item.standard">
          <b-form-checkbox v-for="item2 in item.points" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>
        </b-form-group>
      </b-form-checkbox-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">{{ translate('save') }}</b-btn>
          <b-btn variant="danger" v-show="data.strength.id > 0" @click="deleteData">{{ translate('delete') }}</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checkliststrength",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists/strength/", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/strength/save", this.data.strength).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData( ){
      this.deleteHttp("checklists/strength/delete", this.data.strength).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>