<template>
  <div>
    <phone ref="phone"/>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Azienda ' + data.company.company + ' - ' + data.company.id" scrollable @hidden="closeForm" @close="closeForm">
      <companysite ref="site" @update="loadData(data.company.id)"/>
      <companycontact ref="contact" @update="loadData(data.company.id)"/>
      <companyworker ref="worker" @update="loadData(data.company.id)"/>
      <companyduplicate ref="duplicate" @update="loadData"/>
      <companynote ref="note" @update="loadData(data.company.id)"/>
      <companydeadline ref="deadline" @update="loadData(data.company.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Azienda" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Nome completo">
                  <b-form-input v-model="data.company.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="1" v-if="level >= 2">
                <b-form-group label="Nazionalità">
                  <b-form-select v-model="data.company.states_id" :options="data.states" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Partita IVA">
                  <b-form-input v-model="data.company.vat"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Codice fiscale">
                  <b-form-input v-model="data.company.taxcode"/>
                </b-form-group>
              </b-col>
              <b-col lg="1" v-if="level >= 2">
                <b-form-group label="SDI">
                  <b-form-input v-model="data.company.sdi" maxlength="7"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Dipendenti">
                  <b-form-input v-model="data.company.employees" type="number"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Turni">
                  <b-form-input v-model="data.company.shifts" type="number"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.company.companiestypes_id" :options="data.companiestypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Segnalatore">
                  <b-form-select v-model="data.company.suppliers_id" :options="data.suppliers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="level >= 2">
              <companynaces ref="naces"/>
              <b-col lg="6">
                <b-form-group>
                  <label>NACE</label> <span @click="$refs.naces.loadData( data.company.naces )">Controlla</span>
                    <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.company.naces = updateCombo(nacesSupport)"/>
                  </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Tags">
                  <multiselect v-model="tagsSupport" :options="data.tags" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.company.tags = updateCombo(tagsSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Siti" v-if="data.company.id > 0">
              <b-btn class="mb-2" variant="primary" size="sm" @click="$refs.site.loadData( 0, data.company.id)">Nuovo</b-btn>
              <b-table :sticky-header="(winHeight - 500) + 'px'" responsive :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData( $event.id, data.company.id)" :tbody-tr-class="rowClassSite">
                <template v-slot:cell(type)="row">
                  <b-badge variant="success" v-show="row.item.ismain" class="mr-2">Principale</b-badge>
                  <b-badge variant="danger" v-show="row.item.isregistered" class="mr-2">Legale</b-badge>
                  <b-badge variant="primary" v-show="row.item.isoperative" class="mr-2">Operativa</b-badge>
                  <b-badge variant="warning" v-show="row.item.active === false">Non attiva</b-badge>
                </template>
                <template v-slot:cell(latlng)="row">
                  <b-btn variant="primary" size="sm" v-show="row.item.lat !== null && row.item.lng !== null">Mappa</b-btn>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Contatti" v-if="data.company.id > 0">
            <b-btn class="mb-2" variant="primary" size="sm" @click="loadContact( 0, null )">Nuovo</b-btn>
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th>Email</b-th>
                  <b-th>Telefono</b-th>
                  <b-th>Web</b-th>
                  <b-th>Linkedin</b-th>
                  <b-th>PEC</b-th>
                  <b-th>PEC Fatturazione</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactsmails" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, 'Spett.le ' + data.company.company)">Scrivi</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactsphones" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="preparePhone(item.contact)">Telefona</b-dropdown-item>
                          <b-dropdown-item @click="prepareWhatsapp(item.contact)">Whatsapp</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactswebs" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareWeb(item.contact)">Naviga</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactslinkedins" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareWeb(item.contact)">Naviga</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactspecs" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, 'Spett.le ' + data.company.company)">Scrivi</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                  <b-td>
                    <b-input-group size="sm" v-for="item in data.contactspecsinvoices" :key="item.id" class="mb-2">
                      <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                      <template #append>
                        <b-dropdown size="sm" variant="info">
                          <b-dropdown-item @click="prepareMail(item.contact, 'Spett.le ' + data.company.company)">Scrivi</b-dropdown-item>
                          <b-dropdown-item @click="loadContact(item.id, null)">Modifica</b-dropdown-item>
                          <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-input-group>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-btn class="mb-2" variant="primary" size="sm" @click="loadWorker(0)">Nuovo</b-btn>
            <b-table responsive :items="data.workers" :fields="fields.workers" @row-clicked="loadWorker($event.id)" :tbody-tr-class="rowClassWorker">
              <template v-slot:cell(mails)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + (row.item.firstname || '') + ' ' + (row.item.lastname || '')).replace('  ', ' '))">Scrivi</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(phones)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="preparePhone(item.contact)">Telefona</b-dropdown-item>
                      <b-dropdown-item @click="prepareWhatsapp(item.contact)">Whatsapp</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(webs)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="prepareWeb(item.contact)">Naviga</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(linkedins)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="prepareWeb(item.contact)">Naviga</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(pecs)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + (row.item.firstname || '') + ' ' + (row.item.lastname || '')).replace('  ', ' '))">Scrivi</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(pecfats)="row">
                <b-input-group size="sm" v-for="item in row.value" :key="item.id" class="mb-2">
                  <b-form-input :ref="'contact' + item.id" :value="item.contact" readonly/>
                  <template #append>
                    <b-dropdown size="sm" variant="info">
                      <b-dropdown-item @click="prepareMail(item.contact, (row.item.titleletter + ' ' + (row.item.firstname || '') + ' ' + (row.item.lastname, '')).replace('  ', ' '))">Scrivi</b-dropdown-item>
                      <b-dropdown-item @click="loadContact(item.id, row.item.id)">Modifica</b-dropdown-item>
                      <b-dropdown-item @click="copyContact(item.id)">Copia</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </template>
              <template v-slot:cell(addcontact)="row">
                <b-btn class="mb-2" variant="primary" size="sm" @click="loadContact( 0, row.item.id )">Aggiungi recapito</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Note" v-if="data.company.id > 0 && level >= 2">
            <b-row class="mb-2">
              <b-col lg="9">
                <b-btn variant="primary" size="sm" @click="$refs.note.loadData( 0, data.company.id )">Nuovo</b-btn>
              </b-col>
              <b-col lg="3">
                <b-form-input v-model="fields.notesCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 375) + 'px'" responsive :items="data.notes" :fields="fields.notes" @row-clicked="$refs.note.loadData( $event.id, data.company.id )" :filter="fields.notesCriteria"/>
          </b-tab>
          <b-tab title="Scadenze" v-if="data.company.id > 0 && level >= 2">
            <b-row class="mb-2">
              <b-col lg="9">
                <b-btn variant="primary" size="sm" @click="$refs.deadline.loadData( 0, data.company.id )">Nuovo</b-btn>
              </b-col>
              <b-col lg="3">
                <b-form-input v-model="fields.deadlinesCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 375) + 'px'" responsive :items="data.deadlines" :fields="fields.notes"  @row-clicked="$refs.deadline.loadData($event.id, data.company.id )" :filter="fields.deadlinesCriteria"/>
          </b-tab>
          <b-tab title="Telefonate" v-if="data.phonecalls && data.phonecalls.length > 0">
            <b-row>
              <b-col lg="2">
                <b-form-input v-model="filterPhonecalls" placeholder="Filtra..."/>
              </b-col>
              <b-col lg="10">
                <b-pagination v-model="currentPagePhonecalls" per-page="50" :total-rows="rowsPhonecalls" align="right" aria-controls="table-phonecalls"/>
              </b-col>
            </b-row>
            <b-table id="table-phonecalls" :fields="fields.phonecalls" :sticky-header="(winHeight - 375) + 'px'" :filter="filterPhonecalls" :items="data.phonecalls" per-page="50" :current-page="currentPagePhonecalls"></b-table>
          </b-tab>
          <b-tab title="Marketing" v-if="data.company.id > 0 && level >= 2">
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.marketing" :fields="fields.marketing" @row-clicked="$refs.mail.loadData($event.mailboxesmails_id)"/>
          </b-tab>
          <b-tab title="Email archiviate" v-if="data.company.id > 0 && level >= 2">
            <mailnew ref="mailnew" @update="loadData(data.company.id)"/>
            <mail ref="mail" @update="loadData(data.company.id)"/>
            <b-row class="mb-2" align-h="end">
              <b-col lg="3">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
          <b-tab title="Email complessive" v-if="data.company.id > 0 && level >= 3">
            <b-modal v-model="showFilterMailsTotal" title="Filtra">
              <b-form-group label="Da">
                <b-form-input v-model="tempFilterMailsTotal.from"/>
              </b-form-group>
              <b-form-group label="A">
                <b-form-input v-model="tempFilterMailsTotal.to"/>
              </b-form-group>
              <b-form-group label="Oggetto">
                <b-form-input v-model="tempFilterMailsTotal.subject"/>
              </b-form-group>
              <template v-slot:modal-footer>
                <div class="w-100">
                  <b-btn variant="primary" class="mr-2" @click="filterSetMailsTotal">Filtra</b-btn>
                  <b-btn variant="warning" @click="filterResetMailsTotal">Reset</b-btn>
                </div>
              </template>
            </b-modal>
            <b-btn class="mr-2" variant="primary" @click="loadDataMailsTotal()">Aggiorna</b-btn>
            <b-btn variant="secondary" @click="showFilterMailsTotal = true">Filtra</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 435) + 'px'" class="mt-3" hover responsive show-empty mode="remote" :tbody-tr-class="rowClassMails" :items="itemsMailsTotal.data" :fields="fields.mails" :busy="isBusyMailsTotal" :no-local-sorting="true" :sort-by.sync="searchMailsTotal.sortby" :sort-desc.sync="searchMailsTotal.sortdesc" @sort-changed="sortingMailsTotalChange" @row-clicked="$refs.mail.loadData($event.id)">
              <template v-slot:table-busy>
                <div class="text-center text-primary">
                  <b-spinner class="align-middle"/>
                </div>
              </template>
              <template v-slot:empty>Non ci sono risultati</template>
            </b-table>
            <tablebottom :busy="isBusyMailsTotal" :currentpage="searchMailsTotal.page" :total="itemsMailsTotal.total" @updateCurrentPage="searchMailsTotal.page = $event" @loadData="loadDataMailsTotal()"/>
          </b-tab>
          <b-tab title="Preventivi" v-if="data.quotes && data.quotes.length > 0">
            <quote ref="quote" @update="loadData(data.company.id)"/>
            <b-row>
              <b-col lg="2">
                <b-form-input v-model="filterQuotes" placeholder="Filtra..."/>
              </b-col>
              <b-col lg="10">
                <b-pagination v-model="currentPageQuotes" per-page="50" :total-rows="rowsQuotes" align="right" aria-controls="table-quotes"/>
              </b-col>
            </b-row>
            <b-table id="table-quotes" :fields="fields.quotes" :sticky-header="(winHeight - 400) + 'px'" :items="data.quotes" :filter="filterQuotes" per-page="50" :current-page="currentPageQuotes" @row-clicked="$refs.quote.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Commesse" v-if="data.orders && data.orders.length > 0">
            <order ref="order" @update="loadData(data.company.id)"/>
            <b-row>
              <b-col lg="2">
                <b-form-input v-model="filterOrders" placeholder="Filtra..."/>
              </b-col>
              <b-col lg="10">
                <b-pagination v-model="currentPageOrders" per-page="50" :total-rows="rowsOrders" align="right" aria-controls="table-orders"/>
              </b-col>
            </b-row>
            <b-table id="table-orders" :fields="levelOrders >= 3 ? fields.orders3 : fields.orders" :sticky-header="(winHeight - 400) + 'px'" :items="data.orders" :filter="filterOrders" per-page="50" :current-page="currentPageOrders" @row-clicked="$refs.order.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Fatture" v-if="data.invoices && data.invoices.length > 0">
            <invoice ref="invoice" @update="loadData(data.company.id)"/>
            <b-row>
              <b-col lg="2">
                <b-form-input v-model="filterInvoices" placeholder="Filtra..."/>
              </b-col>
              <b-col lg="10">
                <b-pagination v-model="currentPageInvoices" per-page="50" :total-rows="rowsInvoices" align="right" aria-controls="table-invoices"/>
              </b-col>
            </b-row>
            <b-table id="table-invoices" :fields="fields.invoices" :sticky-header="(winHeight - 400) + 'px'" :items="data.invoices" :filter="filterInvoices" per-page="50" :current-page="currentPageInvoices" @row-clicked="$refs.invoice.loadData($event.id)"/>
          </b-tab>
          <b-tab title="Contratti" v-if="data.contracts && data.contracts.length > 0">
            <contract ref="contract" @update="loadData(data.company.id)"/>
            <b-row class="mb-2" align-h="end">
              <b-col lg="3">
                <b-form-input v-model="fields.contractsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassContracts" :items="data.contracts" :fields="fields.contracts" @row-clicked="$refs.contract.loadData($event.id)" :filter="fields.contractsCriteria"/>
          </b-tab>
          <b-tab title="Audits" v-if="data.audits && data.audits.length > 0">
            <audit ref="audit" @update="loadData(data.company.id)"/>
            <b-row class="mb-2" align-h="end">
              <b-col lg="3">
                <b-form-input v-model="fields.auditsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>            
            <b-table :sticky-header="(winHeight - 375) + 'px'" :tbody-tr-class="rowClassAudits" :items="data.audits" :fields="fields.audits" @row-clicked="$refs.audit.loadData($event.id)" :filter="fields.auditsCriteria"/>
          </b-tab>
          <b-tab title="Kompass" v-if="data.company.id > 0 && level >= 2">
            <b-row>
              <b-col lg="6">
                <b-table :sticky-header="(winHeight - 375) + 'px'" :items="data.economics" :fields="fields.economics"/>
              </b-col>
              <b-col lg="6">
                <b-table :sticky-header="(winHeight - 375) + 'px'" :items="data.shareholders" :fields="fields.shareholders"/>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" v-show="data.company.id > 0" @click="loadData(data.company.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-if="level >= 2" v-show="data.company.id > 0" @click="deleteData">Elimina AZIENDA</b-btn>
          <b-btn variant="info" class="mr-2" v-if="level >= 2" v-show="data.company.id > 0" @click="printData">Stampa</b-btn>
          <b-btn variant="warning" class="mr-2" v-if="level >= 3" v-show="data.company.id > 0" @click="$refs.duplicate.loadData(data.company.id)">Unisci anagrafiche</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import phone from './companycomponents/phone.vue';
import companynaces from './companycomponents/companynaces.vue';
import companysite from './companycomponents/companysite.vue';
import companycontact from './companycomponents/companycontact.vue';
import companyworker from './companycomponents/companyworker.vue';
import companynote from './companycomponents/companynote.vue';
import companydeadline from './companycomponents/companydeadline.vue';
import companyduplicate from './companycomponents/companyduplicate.vue';

export default {
  name: "company",
  components: { phone, companynaces, companysite, companycontact, companyworker, companynote, companydeadline, companyduplicate },
  data() {
    return {
      show: false,
      data: null,
      nacesSupport: [],
      tagsSupport: [],
      filterPhonecalls: null,
      currentPagePhonecalls: 1,
      filterQuotes: null,
      currentPageQuotes: 1,
      filterOrders: null,
      currentPageOrders: 1,
      filterInvoices: null,
      currentPageInvoices: 1,
      fields: {
        sites: [{
          key: 'address',
          label: "Indirizzo",
          sortable: true,
        },{
          key: 'city',
          label: 'Città',
          sortable: true,
        },{
          key: 'active',
          label: 'Stato',
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo';
          },
        },{
          key: 'type',
          label: 'Tipologia',
        },{
          key: "latlng",
          label: "",
        }],
        workers: [{
          key: "title",
          label: "Titolo",
        },{
          key: "firstname",
          label: "Nome",
          sortable: true
        },{
          key: "lastname",
          label: "Cognome",
          sortable: true
        },{
          key: 'active',
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? 'Attivo' : 'Non attivo';
          },
        },{
          key: 'internal',
          label: "Interno",
          sortable: true,
          formatter: value => {
            return value === true ? 'Interno' : 'Esterno';
          },
        },{
          key: "nomarketing",
          label: "Marketing",
          sortable: true,
          formatter: value => {
            return value === true ? "No" : "Si";
          },
        },{
          key: 'job',
          label: "Mansione",
        },{
          key: 'workersrolestypes',
          label: "Aree",
        },{
          key: 'note',
          label: "Note",
        },{
          key: 'mails',
          label: "Mail",
        },{
          key: 'phones',
          label: "Telefoni",
        },{
          key: 'webs',
          label: "Web",
        },{
          key: 'linkedins',
          label: "Linkedin",
        },{
          key: 'pecs',
          label: "PEC",
        },{
          key: 'pecfats',
          label: "PEC Fatt",
        },{
          key: 'addcontact',
          label: "",
        }],
        notes: [{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'user',
          label: 'Collaboratore',
          sortable: true,
        },{
          key: 'note',
          label: "Nota",
        }],
        notesCriteria: null,
        deadlinesCriteria: null,
        phonecalls: [{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'time',
          label: "Ora",
          sortable: true,
        },{
          key: 'user',
          label: "Collaboratore",
          sortable: true,
        },{
          key: 'billsec',
          label: "Chiamata",
          sortable: true,
        },{
          key: 'contact',
          label: 'Contatto',
        },{
          key: 'worker',
          label: 'Destinatario',
        }],
        marketing: [{
          key: 'campaign',
          label: 'Campagna',
          sortable: true,
        },{
          key: 'mailtemplate',
          label: 'Template',
          sortable: true,
        },{
          key: 'contact',
          label: "Mail",
        },{
          key: 'worker',
          label: 'Destinatario',
        },{
          key: 'datetime',
          label: "Invio",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          }
        }],
        marketingCriteria: null,
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true,
        },{
          key: 'from',
          label: 'Da',
        },{
          key: 'to',
          label: 'A',
        },{
          key: 'subject',
          label: "Oggetto",
        },{
          key: 'datetime',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
        orders3: [{
          key: "id",
          label: "#",
          sortable: true,
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'services',
          label: 'Servizi',
          formatter: value => {
            return value.join(",");
          }
        },{
          key: 'internalcompany',
          label: "Ditta",
        },{
          key: 'certificationbody',
          label: "Ente",
        },{
          key: 'invoices',
          label: "Fatture",
        },{
          key: 'quote',
          label: "Preventivo",
        },{
          key: 'totalcost',
          label: "Costi",
        },{
          key: "delta",
          label: "Delta",
        },{
          key: "deltap",
          label: "Delta %",
        }],
        orders: [{
          key: "id",
          label: "#",
          sortable: true,
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "ordersstate",
          label: "Stato"
        },{
          key: 'services',
          label: 'Servizi',
          formatter: value => {
            return value.join(",");
          }
        },{
          key: 'internalcompany',
          label: "Ditta interna",
        },{
          key: 'invoices',
          label: "Fatture",
        }],
        quotes: [{
          key: 'id',
          label: '#',
          sortable: true
        },{
          key: 'opendate',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'internalcompany',
          label: 'Ditta',
        },{
          key: 'certificationbody',
          label: "Ente",
        },{
          key: 'services',
          label: "Servizi",
        },{
          key: 'standards',
          label: 'Norme',
        }],
        invoices: [{
          key: 'progressive',
          label: "Progressivo",
          sortable: true
        },{
          key: 'date',
          label: 'Data',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'internalcompany',
          label: 'Ditta',
        },{
          key: 'services',
          label: "Servizi",
        },{
          key: 'total',
          label: 'Totale',
        }],
        contracts: [{
          key: 'issuedate',
          label: "Emissione",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'contract',
          label: 'Contratto',
        },{
          key: 'contracttype',
          label: 'Tipologia',
          sortable: true,
        },{
          key: 'certificationbody',
          label: "Ente",
        },{
          key: 'standards',
          label: "Norme",
        },{
          key: 'expiracydate',
          label: 'Scadenza',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        contractsCriteria: null,
        audits: [{
          key: 'plandate',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'certificationbody',
          label: "Ente",
        },{
          key: 'standardsphases',
          label: "Norme e fasi",
        },{
          key: 'users',
          label: "Incaricati",
        }],
        auditsCriteria: null,
        economics: [{
          key: 'date',
          label: "Year",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return d.getFullYear();
          },
        },{
          key: 'turnoverk',
          label: "Fatturato",
          formatter: value => {
            return value + " k";
          },
        },{
          key: 'managementprofitk',
          label: "Utile di gestione",
          formatter: value => {
            return value + " k";
          },
        },{
          key: 'netincomek',
          label: "Utile netto",
          formatter: value => {
            return value + " k";
          },
        }],
        shareholders: [{
          key: 'shareholder',
          label: "Azionista",
        },{
          key: 'percentage',
          label: "Percentual",
          sortable: true,
          formatter: value => {
            return value + " %";
          }
        }]
      },
      isBusyMailsTotal: true,
      showFilterMailsTotal: false,
      searchMailsTotal: {
        page: 1,
        filters: {
          id: null,
          from: null,
          to: null,
          subject: null,
        },
        sortby: null,
        sortdesc: false,
      },
      tempFilterMailsTotal: {
        id: null,
        from: null,
        to: null,
        subject: null,
      },
      itemsMailsTotal: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "companies").accesslevel;
    },
    levelOrders(){
      return this.$store.getters.getRoutes.find(x => x.route === "orders").accesslevel;
    },
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    sitesCombo(){
      return this.data.sites.length > 0 ? this.data.sites.map(x => ({ id: x.id, val: x.address + ' ' + x.city }) ) : [];
    },
    rowsPhonecalls() {
      return this.data.phonecalls.length
    },
    rowsQuotes() {
      return this.data.quotes.length
    },
    rowsOrders() {
      return this.data.orders.length
    },
    rowsInvoices() {
      return this.data.invoices.length
    }
  },
  methods: {
    rowClassSite( item ){
      return item.active === false ? "table-warning": "";
    },
    rowClassWorker ( item ){
      return item.active === false ? "table-warning": "";
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassContracts(item) {
      return item !== null && item.active === false ? "table-danger" : "";
    },
    rowClassAudits(item) {
      return item === null ? '' : ( item.auditsstatus_id === 1 ? 'table-primary' : ( item.auditsstatus_id === 2 ? 'table-success' : ( item.auditsstatus_id === 4 ? 'table-danger' : '' ) ) )
    },
    copyContact(contactId){
      this.$refs["contact" + contactId][0].select();
      document.execCommand('copy');
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.fields.notesCriteria = null;
      this.fields.deadlinesCriteria = null;
      this.fields.mailsCriteria = null;
      this.fields.contractsCriteria = null;
      this.fields.auditsCriteria = null;
      this.currentPagePhonecalls = 1;
      this.filterPhonecalls = null;
      this.currentPageQuotes = 1;
      this.filterQuotes = null;
      this.currentPageOrders = 1;
      this.filterOrders = null;
      this.currentPageInvoices = 1;
      this.filterInvoices = null;
      this.openHttp("companies/company/", { id: id }).then((result) => {
        if ( this.level >= 2){
          this.nacesSupport = this.convertCombo(result.naces, result.company.naces);
          this.tagsSupport = this.convertCombo(result.tags, result.company.tags);
        }
        this.data = result;
        this.show = true;
        if ( this.level >= 3 ){
          this.tempFilterMailsTotal = {
            id: this.data.company.id,
            from: null,
            to: null,
            subject: null
          }
          this.searchMailsTotal.filters.page = 1;
          this.searchMailsTotal.filters = this.tempFilterMailsTotal;
          this.loadDataMailsTotal();
        }
      })
    },
    loadDataMailsTotal(){
      this.searchMailsTotal.filters.id = this.data.company.id;     
      this.isBusyMailsTotal = true;
      this.openHttp( "companies/company/mails", this.searchMailsTotal ).then((result) => {
        this.isBusyMailsTotal = false;
        this.itemsMailsTotal = result;
      })
    },
    filterSetMailsTotal(){
      this.searchMailsTotal.filters.page = 1;
      this.searchMailsTotal.filters = this.tempFilterMailsTotal;
      this.showFilterMailsTotal = false;
      this.loadDataMailsTotal();
    },
    filterResetMailsTotal(){
      this.tempFilterMailsTotal = {
        id: this.data.company.id,
        from: null,
        to: null,
        subject: null
      }
      this.filterSetMailsTotal();
    },
    sortingMailsTotalChange(ctx) {
      this.searchMailsTotal.sortby = ctx.sortBy;
      this.searchMailsTotal.sortdesc = ctx.sortDesc;
      this.loadDataMailsTotal();
    },
    loadWorker(id){
      this.$refs.worker.loadData(id, this.data.company.id);
    },
    loadContact(id, workersId){
      this.$refs.contact.loadData(id, this.data.company.id, workersId);
    },
    preparePhone(contact){
      this.$refs.phone.loadData(contact);
    },
    prepareWhatsapp(contact){
      window.open("https://wa.me/+39" + contact, "_blank");
    },
    prepareMail(contact, workerName){
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contact,
        cc: "",
        bcc: "",
        subject: "",
        body: "",
        companies_id: this.data.company.id,
        templates_id: null,
        templates_deadline: null,
        params: { fullname: workerName }
      });
    },
    prepareWeb(contact){
      window.open("http://" + contact, "_blank");
    },
    saveData(){
      this.saveHttp("companies/company/save", this.data.company).then((result) => {
        if ( result !== null ) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("companies/company/delete", { id: this.data.company.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    printData(){
        const newWindow = window.open('');
        newWindow.document.write("<html><body>");
        newWindow.document.write("<style>table { border: 1px solid black; border-collapse: collapse; width: 100% }; tr, td { border: 1px solid black; border-collapse: collapse; padding: 5px; }</style>");
        newWindow.document.write("<h4>Anagrafica - Id:" + this.data.company.id + "</h4>");
        newWindow.document.write("<table><tr><td><b>" + this.data.company.company + "</b></td><td>" + this.data.states.find(x => x.id === this.data.company.states_id ).val + this.data.company.vat + "</td><td>" + this.data.suppliers.find(x => x.id === this.data.company.suppliers_id ).val + "</td></tr></table><br>");
        newWindow.document.write("<table><tr><td colspan=\"3\"><h5>Indirizzi</h5></td></tr><tr><td>Indirizzo</td><td>Città</td><td>Tipologia</td></tr>" + this.data.sites.map(x => "<tr><td>" + x.address + "</td><td>" + x.city + "</td><td>" + [(x.principal === true ? "Principale" : null), (x.operative === true ? "Operativa" : null), (x.legal === true ? "Legale" : null), (x.yard === true ? "Cantiere" : null) ].filter(x => x !== null).join(", ") + "</td></tr>").join("") + "</table><br>");
        newWindow.document.write("<table><tr><td><h5>Contatti</h5></td></tr><tr><td>" + [].concat(this.data.contactsmails.map(x => x.contact).concat(this.data.contactsphones.map(x => x.contact)).concat(this.data.contactswebs.map(x => x.contact)).concat(this.data.contactslinkedins.map(x => x.contact)).concat(this.data.contactspecs.map(x => x.contact)).concat(this.data.contactspecsinvoices.map(x => x.contact))).join(", ")  + "</td></tr></table><br>");
        newWindow.document.write("<table><tr><td colspan=\"3\"><h5>Contatti</h5></td></tr><tr><td>Nome</td><td>Ruolo</td><td>Contatti</td></tr>" + this.data.workers.map(x => "<tr><td>" + x.firstname + " " + x.lastname + "</td><td>" + x.job + "</td><td>" + [].concat(x.mails.map(x => x.contact)).concat(x.phones.map(x => x.contact)).concat(x.webs.map(x => x.contact)).concat(x.linkedins.map(x => x.contact)).concat(x.pecs.map(x => x.contact)).concat(x.pecfats.map(x => x.contact)).join(", ") + "</td></tr>").join("") + "</table><br>");
        
        if (this.data.notes.length > 0) {
          newWindow.document.write("<table><tr><td colspan=\"3\"><h5>Note</h5></td></tr><tr><td>Collaboratore</td><td>Data</td><td>Note</td></tr>" + this.data.notes.map(x => "<tr><td>" + x.user + "</td><td>" + x.date + "</td><td>" + x.note + "</td></tr>").join("") + "</table><br>");
        }
        if (this.data.deadlines.length > 0) {
          newWindow.document.write("<table><tr><td colspan=\"3\"><h5>Scadenze</h5></td></tr><tr><td>Collaboratore</td><td>Data</td><td>Note</td></tr>" + this.data.deadlines.map(x => "<tr><td>" + x.user + "</td><td>" + x.date + "</td><td>" + x.note + "</td></tr>").join("") + "</table><br>");
        }
        newWindow.document.write("</body></html>");
        newWindow.print();
        newWindow.close();
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>