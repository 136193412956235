<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" :title="translate('site') + ' - ' + data.site.id" size="lg" @hidden="closeForm()" @close="closeForm()">
      <div v-if="data.site.contractssites_id > 0">
        <b-form-group :label="translate('site')">
          <b-form-select v-model="data.site.contractssites_id" :options="data.contractssites" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
        </b-form-group>
        <b-row>
          <b-col lg="2">
            <b-form-group :label="translate('shifts')">
              <b-form-input type="number" v-model="data.site.shifts" :disabled="data.site.locked === true"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="translate('employeesnumber')">
              <b-form-input type="number" v-model="data.site.employeesnumber" :disabled="data.site.locked === true"/>
              ogni lavoratore assunto conta come 1 anche se part-time
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="translate('employeeseffectivenumber')">
              <b-form-input type="number" v-model="data.site.employeeseffectivenumber" :disabled="data.site.locked === true"/>
              ogni lavoratore full time conta come 1 mentre il part-time conta per le ore fatte
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="translate('employeesexternal')">
              <b-form-input type="number" v-model="data.site.employeesexternal" :disabled="data.site.locked === true"/>
              lavoratori interinali o collaboratori esterni
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="translate('employeesrepetitive')">
              <b-form-input type="number" v-model="data.site.employeesrepetitive" :disabled="data.site.locked === true"/>
              numero di addetti che svolgono mansioni semplici ripetitive
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <h4>{{ translate('yard') }}</h4>
        <b-row>
          <b-col lg="5">
            <b-form-group label="Indirizzo">
              <b-form-input v-model="data.site.address"/>
            </b-form-group>
          </b-col>
          <b-col lg="5">
            <b-form-group label="Città">
              <multiselect v-model="cities_idSupport" :options="data.cities" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.site.cities_id = updateComboSimple(cities_idSupport);"/>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Location">
              <b-form-select v-model="data.site.onremote" :options="onRemoteCombo" value-field="id" text-field="val" :disabled="data.site.locked === true"/>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-form-group :label="translate('activities')">
        <b-form-checkbox-group v-model="data.site.naces" :options="data.naces.filter(x => (data.site.contractssites_id === null) ? (x.yardnec === true) : (x.id > 0))" value-field="id" text-field="val" stacked :disabled="data.site.locked === true"/>
      </b-form-group>
      <b-row>
        <b-col lg="2">
          <b-form-group :label="translate('data confirmed')">
            <b-form-select v-model="data.site.locked" :options="lockedCombo" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">{{ translate('save') }}</b-btn>
          <b-btn variant="danger" v-show="data.site.id > 0 && data.site.contractssites_id === null" @click="deleteData()">{{ translate('delete') }}</b-btn>
        </div>
      </template>           
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "checklistsite",
  data() {
    return {
      show: false,
      data: null,
      onRemoteCombo: [{
        id: false,
        val: "In presenza"
      },{
        id: true,
        val: "Remoto"
      }],
      lockedCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Si"
      }]
    }
  },
  methods: {
    loadData(id, auditsId){
      this.openHttp("checklists/site/", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/site/save", this.data.site).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.saveHttp("checklists/site/delete", this.data.site).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>