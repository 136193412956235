<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Ditta interna - ' + data.internalcompany.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Ditta interna">
                <b-form-input v-model="data.internalcompany.internalcompany"/>
            </b-form-group>
            <b-form-group label="Stato">
                <b-form-select v-model="data.internalcompany.active" :options="comboActive" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Anagrafica">
                <b-form-select v-model="data.internalcompany.companies_id" :options="data.companies" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Tasse">
                <b-form-select v-model="data.internalcompany.internalcompaniestaxregimes_id" :options="data.internalcompaniestaxregimes" value-field="id" text-field="val"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.internalcompany.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "internalcompany",
    data() {
        return {
            show: false,
            data: null,
            comboActive: [{
                id: true,
                val: "Attivo",
            },{
                id: false,
                val: "Non Attivo",
            }],
        }
    },
    methods: {
        loadData(id){
            this.openHttp("settings/orders/internalcompany/", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("settings/orders/internalcompany/save", this.data.internalcompany).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("settings/orders/internalcompany/delete", { id: this.data.internalcompany.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    },
}
</script>