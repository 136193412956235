<template>
    <div>
        <b-modal v-if="show" v-model="show" :title="'Norma e fase - ' + data.standardphase.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Norma">
                <b-form-select v-model="data.standardphase.standards_id" :options="data.standards" value-field="id" text-field="val" @change="data.standardphase.points = []"/>
            </b-form-group>
            <b-form-group label="Fase">
                <b-form-select v-model="data.standardphase.auditsphases_id" :options="data.phases" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Punti">
                <b-form-checkbox-group v-model="data.standardphase.points">
                    <b-form-checkbox v-for="(item, index) in data.points.findIndex( x => x.standards_id === data.standardphase.standards_id ) > -1 ? data.points.find(x => x.standards_id === data.standardphase.standards_id).points: []" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="info" class="mr-2" @click="completeData">Completa</b-btn>                    
                    <b-btn variant="danger" v-show="data.standardphase.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditstandardphase",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( auditsId, standardphaseId ){
            this.openHttp("audits/standardphase/", { audits_id: auditsId, id: standardphaseId } ).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        completeData(){
            this.data.standardphase.points = this.data.pointscomplete.find(x => x.standards_id === this.data.standardphase.standards_id && x.auditsphases_id === this.data.standardphase.auditsphases_id).points;
        },
        saveData(){
            this.saveHttp("audits/standardphase/save", this.data.standardphase).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/standardphase/delete", { id: this.data.standardphase.id, audits_id: this.data.standardphase.audits_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>