<template>
  <div>
    <b-modal v-model="show" v-if="data !== null" :title="'Contatto - ' + data.contact.id" @hidden="closeForm()" @close="closeForm()">
      <b-alert variant="danger" :show="data.contact.duplicatenumber > 0">Attenzione Duplicato  {{ data.contact.duplicatenumber }}</b-alert>
      <b-form-group label="Contatto">
        <b-form-input v-model="data.contact.contact"/>
      </b-form-group>
      <b-form-group label="Tipologia">
        <b-form-select v-model="data.contact.contactstypes_id" :options="data.contactstypes" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Persona associata">
        <b-form-select v-model="data.contact.workers_id" :options="addNullCombo(data.workers, 'Nessuno')" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="danger" v-show="data.contact.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "companycontact",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData(id, companies_id, workers_id){
      this.openHttp("companies/contact/", { id: id, companies_id: companies_id, workers_id: workers_id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("companies/contact/save", this.data.contact).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){      
      this.deleteHttp("companies/contact/delete", { id: this.data.contact.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    }
  },
}
</script>